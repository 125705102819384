import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useStores} from "../../stores/context";
import {SimpleCard} from "../../matx";
import Button from "@material-ui/core/Button";
import {setTitle} from "../utilities/setTitle";
import {functionsRoot} from "shared/utils/firebaseFunctions";
import {mailchimpCallbackUrl} from "../../stores/authStore";
import CircularProgress from "@material-ui/core/CircularProgress";

const SettingsPage = observer(() => {

    const {userStore, settingsStore} = useStores();
    settingsStore.ensureInitialized();
    const [isDisconnecting, setIsDisconnecting] = useState(false);

    const handleResetPassword = async () => {
        await userStore.sendPasswordReset();
        window.alert("You will shortly receive an e-mail with a link to reset your password.")
    }

    const handleMailchimpConnect = () => {
        window.location.href =
            `https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=707383511747&redirect_uri=${encodeURI(mailchimpCallbackUrl())}`;
    }

    const handleMailchimpDisconnect = async () => {
        setIsDisconnecting(true);
        const disconnectMailchimpFn = functionsRoot().httpsCallable('disconnectMailchimp');
        await disconnectMailchimpFn();
        setIsDisconnecting(false);
    }

    useEffect(() => {
        setTitle(`Settings`);
    }, []);

    const userProviderId = userStore.user?.providerData[0]?.providerId;

    return <div className="ml-4 mr-4"><div className="mt-4 mx-auto layout-contained">
        <SimpleCard title="Reset Password">
            {userProviderId === "google.com" &&
            <>You are logged in with your Google account. See <a className="underline" target="_blank" rel="noopener noreferrer" href="https://myaccount.google.com/signinoptions/password">this
                link</a> for instructions to change your password.</>
            }
            {userProviderId === "apple.com" &&
            <>You are logged in with your Apple account. Please go to <a className="underline" target="_blank" rel="noopener noreferrer"
                                                                         href="https://support.apple.com/en-gb/HT201355">Apple</a> to change your
                password.</>
            }
            {userProviderId === "password" &&
            <Button variant="contained" onClick={handleResetPassword}>Press to request a password reset e&#8209;mail</Button>
            }
        </SimpleCard>
        {!settingsStore.isLoading &&
        <SimpleCard title="Integrations" className="mt-4">
            {settingsStore.isMailchimpEnabled ?
                <>
                    <div><Button variant="contained" onClick={handleMailchimpDisconnect} disabled={isDisconnecting}>
                        Disconnect from Mailchimp
                        {isDisconnecting &&
                        <CircularProgress className="ml-2"
                                          size={20}
                        />
                        }</Button></div>
                    <div className="mt-4">
                        You can configure any form to add submitted e-mail addresses to Mailchimp from the 'Edit Form' screen.
                    </div>
                </>
                :
                <>
                    <div>
                        <Button variant="contained" onClick={handleMailchimpConnect}>Connect to Mailchimp</Button></div>
                    <div className="mt-4">Connect your Mailchimp account to add e-mail addresses from form submissions to your mailing list.</div>
                </>
            }
        </SimpleCard>
        }
        <SimpleCard title="Version" className="mt-4">
            v2.3 {process.env.REACT_APP_VERSION?.substr(0, 6)}
        </SimpleCard>
        {process.env.REACT_APP_ENV === 'staging' &&
            <Button variant="contained" onClick={async () => {
                await (functionsRoot().httpsCallable('testSubmissionWrite')({submissionId: "cOqApw8hVs1unPpQXvW9"}));
            }}>Test submission write</Button>
        }
        </div></div>;

});


export default SettingsPage;