import React, {useEffect, useState} from "react";

import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Link from "@material-ui/core/Link";

import { Link as RouterLink } from 'react-router-dom';
import {useStores} from "../../stores/context";
import {observer} from "mobx-react";
import {formatTimestamp, TimestampFormat} from "shared/utils/dateUtils";
import { useHistory } from "react-router-dom";
import {MatxLoading} from "../../matx";
import StoreTablePagination from "../common/StoreTablePagination";
import {Form} from "shared/forms/model";
import {Document} from "firestorter";
import {formLink} from "shared/forms/webLink";
import {useIsLg, useIsXl} from "../../hooks/useWindowSize";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DotsHorizontalIcon from 'mdi-material-ui/DotsHorizontal';
import {AckDialog} from "../common/AckDialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import {FormEditStore} from "shared/forms/formEditStore";

interface ListingTableRowProps {
    form: Document<Form>;
    isMd: boolean;
    isXl: boolean;
    handleMenuOpen: (element: Element, form: Document<Form>) => void;
}

const ListingTableRow = observer((props: ListingTableRowProps) =>  {
    const {form} = props;

    return <TableRow
        className="select-none"
    >
        <TableCell className="px-0" align="left">
            <Link component={RouterLink} color="inherit" to={`/forms/details/${form.id}`}>{form.data.name || "(untitled)"}</Link>
        </TableCell>
        <TableCell className="px-0 capitalize" align="left">
            <Link color="inherit" href={formLink(form.id!)}>Link</Link>
        </TableCell>
        {props.isXl &&
            <TableCell className="px-0 capitalize" align="left">
                {formatTimestamp(form.data.createdAt, TimestampFormat.DateTimeFormat)}
            </TableCell>
        }
        {props.isMd && <TableCell className="px-0 capitalize">
            {formatTimestamp(form.data.updatedAt, TimestampFormat.DateTimeFormat)}
        </TableCell>}
        {props.isMd && <TableCell className="px-0 capitalize">
            {form.data.isPublished ? "Published" : "Draft"}
        </TableCell>}
        <TableCell className="px-0">
            {form.data.subCounter ? form.data.subCounter - FormEditStore.scOffset : 0}
        </TableCell>
        <TableCell className="px-0">
            <IconButton onClick={(event) => props.handleMenuOpen(event.currentTarget, form)}>
                <DotsHorizontalIcon aria-hidden={false} aria-label="Actions"/>
            </IconButton>
        </TableCell>
    </TableRow>;
})

export default observer(() => {
    const {formListStore, billingStore, authStore} = useStores();
    authStore.abortIfNotSignedIn();
    formListStore.ensureInitialized();
    billingStore.ensureInitialized();
    const [deletingId, setDeletingId] = useState<string | undefined>(undefined);
    const [deletingName, setDeletingName] = useState<string>("");
    const history = useHistory();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<Element | null>(null);
    const [activeForm, setActiveForm] = useState<Document<Form> | undefined>(undefined);
    const [showPublishFormWarning, setShowPublishFormWarning] = React.useState(false);

    const handleDeleteOk = () => {
        formListStore.delete(deletingId as string);
        setDeletingId(undefined);
    };

    const handleDeleteCancel = () => {
        setDeletingId(undefined);
    };

    const handleDeleteForm = () => {
        handleMenuClose();
        setDeletingId(activeForm!.id);
        setDeletingName(activeForm!.data.name || "");
    };

    const handleDuplicateForm = () => {
        handleMenuClose();
        formListStore.duplicate(activeForm!.id!, activeForm!.data.name || "");
    };

    const handleEditForm = () => {
        history.push(`/forms/details/${activeForm!.id}`);
    };

    const handleFillForm = () => {
        if (activeForm!.data.isPublished) {
            history.push(`/submissions/form/${activeForm!.id}`);
        } else {
            handleMenuClose();
            setShowPublishFormWarning(true);
        }
    };

    const handleSubmissionListing = () => {
        history.push(`/submissions?formId=${activeForm!.id}`);
    };

    const handleAnalytics = () => {
        history.push(`/analytics?formId=${activeForm!.id}`);
    };

    const handleMenuOpen = (element: Element, form: Document<Form>) => {
        setMenuAnchorEl(element);
        setActiveForm(form);
    }

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    }

    const [doDisplayStartOfTrialMessage, setDoDisplayStartOfTrialMessage] = useState(false);

    const handleStartOfTrialClose = async () => {
        await billingStore.updateDisplayedStartOfTrial();
        setDoDisplayStartOfTrialMessage(false);
    }

    useEffect(() => {
        if (!billingStore.isLoading) {
            setDoDisplayStartOfTrialMessage(billingStore.shouldDisplayStartOfTrialMessage);
        }

    }, [billingStore.isLoading, setDoDisplayStartOfTrialMessage, billingStore.shouldDisplayStartOfTrialMessage])

    const isXl = useIsXl();
    const isMd = useIsLg();

    return (
        <div className="w-full overflow-auto">
            {deletingId !== undefined &&
            <AckDialog
                title="Delete form?"
                cancelText="Cancel"
                okText="Yes"
                handleClose={handleDeleteCancel}
                handleOk={handleDeleteOk}
            >
                <DialogContentText>
                    Are you sure you want to delete '{deletingName}' form?
                </DialogContentText>
            </AckDialog>
            }
            {showPublishFormWarning &&
            <AckDialog
                title="Form not yet published"
                okText="Ok"
                handleClose={() => setShowPublishFormWarning(false)}
            >
                <DialogContentText>
                    You must publish this form in 'Edit Form' before you can fill it.
                </DialogContentText>
            </AckDialog>
            }
            {doDisplayStartOfTrialMessage &&
            <AckDialog
                title="Welcome to speedyWaiver"
                okText="Ok"
                handleClose={handleStartOfTrialClose}
            >
                <DialogContentText>
                    Welcome to your one month free trial of speedyWaiver. We hope you find it useful. If you have any questions then please don't hesitate to contact us at support@speedywaiver.io
                </DialogContentText>
            </AckDialog>
            }
            {formListStore.isLoading ?
                <MatxLoading /> :
                formListStore.isEmpty() ?
                    <div className="w-full text-center pt-16 pb-16 text-16">You haven't created any forms yet.<br/><br/>Use the Create New Form button above to create your
                        first form.
                    </div>
                    :
                    <>
                        <Table className="whitespace-pre">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="px-0">Name</TableCell>
                                    <TableCell className="px-0">Fill Link</TableCell>
                                    {isXl && <TableCell className="px-0">Created At</TableCell>}
                                    {isMd && <TableCell className="px-0">Last Updated</TableCell>}
                                    {isMd && <TableCell className="px-0">Is Published?</TableCell>}
                                    <TableCell className="px-0">Submissions</TableCell>
                                    <TableCell className="px-0">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formListStore.docs
                                    .map((form) => (
                                        <ListingTableRow key={form.id} form={form}
                                                         isXl={isXl} isMd={isMd} handleMenuOpen={handleMenuOpen}
                                        />
                                    ))}
                            </TableBody>
                        </Table>

                        <StoreTablePagination store={formListStore}/>

                        <Menu
                            anchorEl={menuAnchorEl}
                            keepMounted
                            open={Boolean(menuAnchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleEditForm}>Edit/Share Form</MenuItem>
                            <MenuItem onClick={handleFillForm}>Fill Form</MenuItem>
                            <MenuItem onClick={handleSubmissionListing}>Submission List</MenuItem>
                            <MenuItem onClick={handleDeleteForm}>Delete Form</MenuItem>
                            <MenuItem onClick={handleDuplicateForm}>Duplicate Form</MenuItem>
                            <MenuItem onClick={handleAnalytics}>View Analytics</MenuItem>
                        </Menu>

                    </>
                }
        </div>
    );
});