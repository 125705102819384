const firebaseConfig = process.env.REACT_APP_ENV === 'staging' ? {
  apiKey: "AIzaSyDxAqAMIrPUfDg2bhDfSUa7tYi4-gN43GE",
  authDomain: "release-staging-d2920.firebaseapp.com",
  databaseURL: "https://release-staging-d2920.firebaseio.com",
  projectId: "release-staging-d2920",
  storageBucket: "release-staging-d2920.appspot.com",
  messagingSenderId: "307769649728",
  appId: "1:307769649728:web:dcf186f9f2632af0378da5",
  measurementId: "G-8T62E1RQ5B"
} : {
  apiKey: "AIzaSyD2kPrZOM8Pb0dk4ozrpo_4w407hBWGtxI",
  authDomain: "auth.speedywaiver.io",
  databaseURL: "https://release-app-276609.firebaseio.com",
  projectId: "release-app-276609",
  storageBucket: "release-app-276609.appspot.com",
  messagingSenderId: "710355752846",
  appId: "1:710355752846:web:a1d47f24fa7e9ed298f6df",
  measurementId: "G-9B32LNCS7K"
};

console.log(`Environment: ${process.env.REACT_APP_ENV}`)

export default firebaseConfig;
