import React from "react";
import {authRoles} from "shared/auth/authRoles";
import FormDetailPage from "./FormDetailPage";
import ListingPage from "./ListingPage";

const formRoutes = [
  {
    path: "/standalone/formbuilder",
    component: React.lazy(() => import("./FormBuilderPage")),
    auth: authRoles.user
  },{
    path: "/forms/builder",
    component: React.lazy(() => import("./FormBuilderPage")),
    auth: authRoles.user
  },{
    path: "/forms/details/:id",
    component: FormDetailPage,
    auth: authRoles.user
  },
  {
    path: "/forms",
    component: ListingPage,
    auth: authRoles.user
  }

];

export default formRoutes;