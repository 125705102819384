import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

const styles = (theme: Theme) => ({
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  wrapper: {
    width: "100%",
    height: "100vh"
  },
  inner: {
    flexDirection: "column",
    maxWidth: "320px"
  }
});

class NotFound extends Component<any> {
  state = {};

  render() {
    const { classes } = this.props;
    return (
      <div className={`${classes.flexCenter} ${classes.wrapper}`}>
        <div className={`${classes.flexCenter} ${classes.inner}`}>
          <img
            className="mb-8"
            src="/assets/images/illustrations/404.svg"
            alt=""
          />
          <h1 className="mb-6">Page not Found</h1>
        </div>
      </div>
    );
  }
}

export default withStyles(styles as any, { withTheme: true })(NotFound);
