import {Logger} from "shared/common/context";
import {getFirebaseApp} from "firestorter";
import {PathReader} from "shared/submissions/model";
const storage = getFirebaseApp().storage();

export const webPathReader: PathReader = {
    readPathAsString: async (path: string, log: Logger) => {
        try {
            const downloadUrl = await storage.ref(path).getDownloadURL();
            const response = await fetch(downloadUrl)
            return await response.text();
        } catch (e) {
            log.error(e, `Error downloading path: ${path}`)
            return undefined;
        }
    },
    readPathAsDataUrl: async (path: string, log: Logger) => {
        try {
            const downloadUrl = path.startsWith("http") ? path : await storage.ref(path).getDownloadURL();
            const response = await fetch(downloadUrl);
            const blob = await response.blob();
            const reader = new FileReader();
            await new Promise((resolve, reject) => {
                reader.onload = resolve;
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
            if (reader.result && typeof(reader.result) === "string") {
                return reader.result;
            } else {
                return undefined;
            }
        } catch (e) {
            log.error(e, `Error downloading path: ${path}`)
            return undefined;
        }
    }
}