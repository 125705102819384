import { observable, runInAction } from "mobx";

export class DebugLogger {

    @observable log: any[] = [];

    constructor() {
        const old = console.log;
        console.log = (line: any) => {
            this.write(line);
            old(line);
        };
        console.warn = console.log;
        console.error = console.log;
    }

    write(rec: any) {
        runInAction(() => {
            if (!(typeof rec === "string" && rec.indexOf("beautiful-dnd") > -1)) {
                this.log.push(rec);
            }
        });
    }
}
