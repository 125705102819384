import {FieldData} from "../forms/model";
import * as firebaseApp from 'firebase/app';
import { Logger } from "../common/context";
type Timestamp = firebaseApp.firestore.Timestamp;

export interface Submission {
    formId: string;
    formConfigId: string;
    formName: string;
    data: {[fieldId: string]: FieldData};
    groupId: string | null;
    createdAt: firebase.firestore.Timestamp;
    clientCreatedAt: firebase.firestore.Timestamp;
    clientCreatedAtTz: string;
    updatedAt: firebase.firestore.Timestamp;
    orgId: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    dob?: Timestamp;
}

export interface SubmissionDoc {
    createdAt: firebase.firestore.Timestamp;
    clientCreatedAt: firebase.firestore.Timestamp;
    clientCreatedAtTz: string;
    groupId: string | null;
    formId: string;
    formConfigId: string;
    formName: string;
    data: {[fieldId: string]: FieldData};
    orgId: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    dob?: Date;
}

export function userSubmissionId(id: string) {
    return id.substring(0, 7).toLowerCase();
}

export interface PathReader {
    readPathAsString: (path: string, log: Logger) => Promise<string | undefined>;
    readPathAsDataUrl: (path: string, log: Logger) => Promise<string | undefined>;
    readPathAsBuffer?: (path: string, log: Logger) => Promise<Buffer | undefined>;
}

export const submissionsEntity = "submissions";