import * as React from "react";
import {FieldRenderMode, FieldType, radioText} from "shared/forms/model";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import {action, runInAction} from "mobx";
import {observer} from "mobx-react";
import {FieldProps} from "./field";
import {Controller, useFormContext, get} from "react-hook-form";
import {textInputProps} from "./defaultProps";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import {errorMessage} from "shared/utils/error";
import {radioDisplayOptions} from "shared/forms/formConfigEditStore";
import {useCallback, useEffect, useRef} from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useIsSmallScreen} from "../../../hooks/screen";
import {AppTextInput} from "../../common/AppTextInput";

const OptionsEditor = React.lazy(() => import("./OptionsEditor"));

export default observer(React.forwardRef((props: FieldProps<FieldType.Radio | FieldType.Dropdown>, ref: React.Ref<HTMLInputElement>) => {

    const {field, fieldId, data} = props;
    const { errors, register, watch, trigger, formState } = useFormContext();
    const inputRef = useRef<HTMLInputElement>(null);
    const labelRef = useRef<HTMLLabelElement>(null);
    const legendRef = useRef<HTMLLegendElement>(null);
    const defaultOptionId = useRef<string | undefined>(field.config.defaultOptionId);
    const optionCount = useRef<number>(field.config.options.length);

    const updateLabel = (event: any) => {
        runInAction(() => {
            field.isClean = false;
            field.label = event.target.value;
        });
    };

    const editFieldId = "e-" + field.id;
    const reqFieldId = "r-" + field.id;
    const optionCountFieldId = "o-" + field.id;

    const isSmallScreenBrowser = useIsSmallScreen();

    const displayOptions = radioDisplayOptions(field);

    const updateDefault = useCallback((optionId: string) => {
        defaultOptionId.current = optionId;

        if (formState.submitCount > 0) {
            trigger(reqFieldId);
        }
    }, [trigger, reqFieldId, formState.submitCount]);

    const updateOptionCount = useCallback((updatedCount: number) => {
        optionCount.current = updatedCount;
        if (formState.submitCount > 0) {
            trigger(optionCountFieldId);
        }
    }, [trigger, optionCountFieldId, formState.submitCount]);

    useEffect(() => {
        register(optionCountFieldId, {
            validate: () =>
                (optionCount.current === 0 ? "No options provided" : undefined)
        });
    }, [register, optionCountFieldId, reqFieldId, field.type]); // eslint-disable-line react-hooks/exhaustive-deps


    const watchOther =
        watch(fieldId) === "other" || //dropdown or single-select radio button
        watch(`${fieldId}[other]`) === true; //multi-select radio button

    let error = get(errors, fieldId);
    if (error && displayOptions.length > 0 && error[displayOptions[0].id]) {
        error = error[displayOptions[0].id];
    }

    return (
        <>
            {props.fieldRenderMode === FieldRenderMode.Edit &&
            <>
                <div className="w-full mb-2">
                <AppTextInput autoFocus
                    name={editFieldId}
                    label="Field title"
                    defaultValue={props.field.label}
                    onChange={updateLabel}
                    inputRef={register({required: true})}
                />
            </div>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={action((event, checked) => {
                                field.config.isRequired = checked;
                                if (formState.submitCount > 0) {
                                    trigger(reqFieldId);
                                }
                            })}
                            checked={field.config.isRequired}
                            color="primary"
                            name={reqFieldId}
                        />
                    }
                    label="Is required?"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={action((event, checked) => { field.config.canSelectOther = checked; })}
                            checked={field.config.canSelectOther}
                            color="primary"
                        />
                    }
                    label="Allow 'other'?"
                />

                {field.type === FieldType.Radio &&
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={action((event, checked) => {
                                field.config.canSelectMultiple = checked;
                                if (formState.submitCount > 0) {
                                    trigger(reqFieldId);
                                }
                            })}
                            checked={field.config.canSelectMultiple}
                            color="primary"
                        />
                    }
                    label="Allow multiple selections?"
                />
                }
                <div className="font-bold mb-2 mt-2">Options (use the switch to make an option default):</div>
                {/*minHeight is so scrollIntoView works properly given this is lazy*/}
                <div style={{minHeight: 50}}>
                    <OptionsEditor field={field} updateDefault={updateDefault} updateOptionCount={updateOptionCount}/>
                </div>
                {[get(errors, reqFieldId)].map(error => error && <FormHelperText key={1} error={true}>{errorMessage(error)}</FormHelperText>)}
                {[get(errors, optionCountFieldId)].map(error => error && <FormHelperText key={2} error={true}>{errorMessage(error)}</FormHelperText>)}

            </>
            }

            {props.fieldRenderMode === FieldRenderMode.Fill &&
                field.type === FieldType.Radio &&
                field.config.canSelectMultiple &&
                <FormControl component="fieldset">
                    <FormLabel ref={legendRef} className="mb-4" component="legend">{field.label}</FormLabel>
                    <FormGroup>
                    {displayOptions.map((option, i) =>

                    <FormControlLabel
                        key={i}
                        className="w-full"
                        control={
                            <Controller
                                render={({onChange, onBlur, value}) => (
                                    <Checkbox
                                        onChange={(event) => {
                                            onChange(event.target.checked);
                                            displayOptions.forEach((toggleOption) => {
                                                trigger(`${fieldId}[${toggleOption.id}]`);
                                            });
                                        }}
                                        onBlur={onBlur}
                                        checked={value}
                                        value={true}
                                        inputRef={i === 0 ? inputRef : undefined}
                                        color="primary"/>
                                )}
                                name={`${fieldId}[${option.id}]`}
                                defaultValue={option.id === defaultOptionId.current}
                                rules={props.fillValidationOpts}
                                onFocus={() => {
                                    legendRef.current?.scrollIntoView();
                                    inputRef.current?.focus();
                                }}
                            />
                        }
                        label={option.text}
                    />

            )}
                    </FormGroup>
                    {error && <FormHelperText error={true}>{errorMessage(error)}</FormHelperText>}
                </FormControl>
            }

            {props.fieldRenderMode === FieldRenderMode.Fill &&
            field.type === FieldType.Radio &&
            !field.config.canSelectMultiple &&
            <FormControl component="fieldset">
                <FormLabel ref={legendRef} className="mb-4" component="legend">{field.label}</FormLabel>
                <Controller
                    name={fieldId}
                    onFocus={() => {
                        legendRef.current?.scrollIntoView();
                        inputRef.current?.focus();
                    }}
                    rules={props.fillValidationOpts}
                    defaultValue={defaultOptionId.current ? defaultOptionId.current : ""}
                    render={({onChange, onBlur, value}) => (
                    <RadioGroup aria-label={field.label} onChange={onChange} onBlur={onBlur}>
                        {displayOptions.map((option, i) =>
                            <FormControlLabel
                                key={option.id}
                                value={option.id}
                                control={<Radio checked={value === option.id} color="primary" inputRef={i === 0 ? inputRef : undefined} />}
                                label={option.text} />
                        )}
                    </RadioGroup>
                    )}
                />
                {error && <FormHelperText error={true}>{errorMessage(error)}</FormHelperText>}
            </FormControl>
            }

            {props.fieldRenderMode === FieldRenderMode.Fill &&
            field.type === FieldType.Dropdown &&
            <>
                <Controller
                    name={fieldId}
                    onFocus={() => {
                        labelRef.current?.scrollIntoView();
                        inputRef.current?.focus();
                    }}
                    defaultValue={defaultOptionId.current ? defaultOptionId.current : ""}
                    rules={props.fillValidationOpts}
                    render={({onChange, onBlur, value}) => (
                        isSmallScreenBrowser ?
                            <><InputLabel ref={labelRef} className="mt-2" htmlFor="default">{field.label}</InputLabel>
                            <NativeSelect
                                className="py-2 mb-4 w-full"
                                value={value}
                                onBlur={onBlur}
                                onChange={(event) => onChange(event.target.value)}
                                inputRef={inputRef}
                            >
                                <option value="">Please select an item ...</option>
                                {displayOptions.map((option, i) =>
                                    <option key={option.id} value={option.id}>{option.text}</option>
                                )}
                            </NativeSelect></> :
                            <FormControl variant="outlined" className="py-2 mb-4 w-full">
                                <InputLabel ref={labelRef}>{field.label}</InputLabel>
                                <Select
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={(event) => onChange(event.target.value)}
                                    label={field.label}
                                >
                                    {displayOptions.map((option, i) =>
                                        <MenuItem key={option.id} value={option.id}>{option.text}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                    )}
                />
                {error && <FormHelperText error={true}>{errorMessage(error)}</FormHelperText>}
            </>
            }

            {props.fieldRenderMode === FieldRenderMode.Fill && watchOther &&
            <div className="my-2">
                <TextField
                    {...textInputProps(`${fieldId}-othertext`, errors)}
                    label="Other"
                    inputRef={register({required: true})}
                />
            </div>
            }

            {props.fieldRenderMode === FieldRenderMode.Display &&
            <>
                <div className="field-display-name">
                    {field.label}:&nbsp;
                </div>
                <div className="field-display-data">
                    {radioText(data)}
                </div>
            </>
            }
        </>
    );
}));