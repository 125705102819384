import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useStores} from "../../stores/context";
import {MatxLoading, SimpleCard} from "../../matx";
import useScript from 'react-script-hook';
import PriceModules from "./PriceModules";
import {AckDialog} from "../common/AckDialog";
import {PaddlePlan} from "shared/users/billing";
import {logger} from "../../sumoLogger";
import {setTitle} from "../utilities/setTitle";
import DialogContentText from "@material-ui/core/DialogContentText";

declare global {
    interface Window {
        Paddle: any;
    }
}

const log = logger("SettingsPage");

const SettingsPage = observer(() => {

    const {billingStore, userStore} = useStores();
    billingStore.ensureInitialized();
    const [scriptLoading, scriptError] = useScript({ src: 'https://cdn.paddle.com/paddle/paddle.js' });

    const [pendingPlan, setPendingPlan] = useState<undefined | PaddlePlan>(undefined);
    const [showSubscriptionUpdated, setShowSubscriptionUpdated] = useState(false);
    const [showTrialWarning, setShowTrialWarning] = useState(false);
    const [isUpdatingSubscription, setIsUpdatingSubscription] = useState(false);

    const handleSignUp = (plan: PaddlePlan) => {
        if (billingStore.hasActivePaddlePlan) {
            setPendingPlan(plan);
        } else if (billingStore.hasPaddleTrial) {
            setShowTrialWarning(true);
        } else {
            window.Paddle.Checkout.open({
                product: plan.paddleId,
                email: userStore.user?.email,
                passthrough: JSON.stringify({userId: userStore.user!.uid})
            });
        }
    }

    const handleChangePlan = async () => {
        if (pendingPlan) {
            try {
                setIsUpdatingSubscription(true);
                await billingStore.updateSubscription(pendingPlan!);
                setPendingPlan(undefined);
                setShowSubscriptionUpdated(true);
            } catch(e) {
                log.error(e, "Error updating subscription");
                window.alert("Unfortunately there was an error updating the subscription. Please contact support@speedywaiver.io")
            } finally {
                setIsUpdatingSubscription(false);
                setPendingPlan(undefined);
            }

        }
    }

    useEffect(() => {
        if (!scriptLoading && !scriptError) {
            const vendorIdString = process.env.REACT_APP_PADDLE_VENDOR_ID as string;
            window.Paddle.Setup({ vendor: parseInt(vendorIdString, 10) });
        }
    }, [scriptLoading, scriptError])

    useEffect(() => {
        setTitle(`Billing`);
    }, []);

    if (billingStore.isLoading) {
        return <MatxLoading />;
    } else {
        const currentPlan = billingStore.currentPlan;
        const formCount = billingStore.currentFormCount;
        const maxForms = billingStore.maxForms;
        const formPercentage = maxForms ? formCount * 100 / maxForms : 0;

        const submissionCount = billingStore.currentMonthSubmissionCount;
        const maxSubmissions = billingStore.maxSubmissionsPerMonth;
        const submissionPercentage = maxSubmissions ? submissionCount * 100 / maxSubmissions : 0;

        return <div className="ml-4 mr-4"><div className="mt-4 mb-4 mx-auto w-full max-w-xl">
            {pendingPlan && <AckDialog title="Change Plan" loading={isUpdatingSubscription} cancelText="Cancel" okText="Yes" handleOk={handleChangePlan} handleClose={() => setPendingPlan(undefined)}>
                <DialogContentText>Are you sure you want to change your subscription plan?</DialogContentText>
            </AckDialog>}
            {showSubscriptionUpdated && <AckDialog title="Change Plan" handleClose={() => setShowSubscriptionUpdated(false)}>
                <DialogContentText>Your subscription has been updated.</DialogContentText>
            </AckDialog>}
            {showTrialWarning && <AckDialog title="Can't change plan during trial period" okText="Ok" handleClose={() => setShowTrialWarning(false)}>
                <DialogContentText>You cannot change your plan here whilst you are still in trial period, please either wait till the end of the trial, or cancel the trial and take out a new plan. If you have any problems please contact us at support@speedywaiver.io</DialogContentText>
            </AckDialog>}
            {currentPlan &&
            <SimpleCard title="Current plan">
                <PriceModules activePlan={billingStore.currentPaddlePlan} showActive={true}/>
                <h2 className="text-16 font-bold mt-4">Usage</h2>
                <table style={{borderSpacing: 0}} className={"m-2"}>
                    <tbody>
                    <tr>
                        <td className="whitespace-no-wrap">Forms</td>
                        <td className="text-gray-600 whitespace-no-wrap text-right">{formCount} / {maxForms}</td>
                        <td className="pr-3">
                            <progress className="ml-3 progress-orange" value={formPercentage} max={100}/>
                        </td>
                    </tr>
                    <tr>
                        <td className="whitespace-no-wrap pr-6">Submissions</td>
                        <td className="text-gray-600 whitespace-no-wrap text-right">{submissionCount} / {maxSubmissions}</td>
                        <td style={{width: "99%"}} className="pr-3">
                            <progress className="ml-3 progress-blue" value={submissionPercentage} max={100}/>
                        </td>
                    </tr>
                    </tbody>
                </table>
                {billingStore.cancelSubscriptionLink &&
                <p><a className="underline" target="_blank" rel="noopener noreferrer" href={billingStore.cancelSubscriptionLink}>Cancel
                    subscription</a></p>
                }
                {billingStore.updateSubscriptionLink &&
                <p><a className="underline" target="_blank" rel="noopener noreferrer" href={billingStore.updateSubscriptionLink}>Change credit
                    card</a></p>
                }
            </SimpleCard>
            }

            {billingStore.planChannel === "iap" &&
            <SimpleCard className="mt-6" title="Mobile purchase">
                You currently have a plan that you have purchased using in-app purchase on a mobile device.
                To switch to web billing, please contact us at support@speedywaiver.io
            </SimpleCard>
            }

            <SimpleCard className="mt-6" title="Available Plans">
                <div className="flex flex-col pb-2">
                    <PriceModules
                        activePlan={billingStore.currentPaddlePlan}
                        showActive={false}
                        handleSignUp={billingStore.planChannel !== "iap" ? handleSignUp : undefined}
                    />
                </div>
            </SimpleCard>

        </div></div>;

    }

});


export default SettingsPage;