import "./styles/_app.scss";
import React from "react";
import './initEnv';
import './sumoLogger';
import './stores/initFirebase';
import {Router, Switch, Route} from "react-router-dom";
import MatxTheme from "./MatxLayout/MatxTheme/MatxTheme";
import AppContext from "./appContext";
import history from "browserHistory";
import {ErrorBoundary} from 'react-error-boundary'

import routes from "./RootRoutes";
import MatxLayout from "./MatxLayout/MatxLayoutSFC";
import AuthGuard from "./auth/AuthGuard";
import MatxSuspense from "matx/components/MatxSuspense/MatxSuspense";
import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import FillSubmissionPage from "./views/submissions/FillSubmissionPage";
import {debugLogger} from "./sumoLogger";
import {DebugLogger} from "./views/utilities/DebugLogger";
import ScrollToTop from "./views/utilities/ScrollToTop";
import {ErrorFallback} from "./views/ErrorFallback";
import GroupChooseFormPage from "./views/groups/GroupChooseFormPage";
import LogRocket from "logrocket";

declare global {
    interface Window { heap: any; }
}

const isLocalHost = window.location.hostname !== "localhost" && window.location.hostname !== "desktop" && !window.location.hostname.startsWith("192.168");

const disableTracking = window.location.pathname.startsWith("/fill/") || window.location.pathname.startsWith("/fillgroup/");

if (!isLocalHost && !disableTracking) {
    window.heap.load(process.env.REACT_APP_HEAP_ID);

    LogRocket.init('ioln13/speedywaiver', {
        rootHostname: 'speedywaiver.io',
    });
}

const FormBuilderPage = React.lazy(() => import("./views/forms/FormBuilderPage"));

(async () => {
    if (!("ResizeObserver" in window)) {
        const module = await import("@juggle/resize-observer");
        (window as any).ResizeObserver = module.ResizeObserver;
        // https://github.com/wellyshen/react-cool-dimensions/issues/45
        (window as any).ResizeObserverEntry = module.ResizeObserverEntry;
    }
})();

const App = () => {

    return (
        <AppContext.Provider value={{routes}}>
            <LocalizationProvider dateAdapter={DateFnsUtils}>
                <MatxTheme>
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                        <MatxSuspense>
                            <Router history={history}>
                                <ScrollToTop />
                                <Switch>
                                    <Route exact path="/standalone/formbuilder">
                                        <AuthGuard>
                                            <FormBuilderPage isStandalone={true}/>
                                        </AuthGuard>
                                    </Route>
                                    <Route path="/fill/:formId">
                                        <FillSubmissionPage/>
                                    </Route>
                                    <Route path="/fillgroup/:groupId">
                                        <GroupChooseFormPage/>
                                    </Route>
                                    <Route>
                                        <AuthGuard>
                                            <MatxLayout/>
                                        </AuthGuard>
                                    </Route>
                                </Switch>
                            </Router>
                        </MatxSuspense>
                    </ErrorBoundary>
                </MatxTheme>
                {debugLogger && <DebugLogger/>}
            </LocalizationProvider>
        </AppContext.Provider>
    );
};

export default App;
