import * as React from "react";
import {FieldRenderMode, FieldType, NameHelpers} from "shared/forms/model";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import {action} from "mobx";
import {observer} from "mobx-react";
import {FieldProps} from "./field";
import { useFormContext } from "react-hook-form";
import {textInputProps} from "./defaultProps";
import FormLabel from "@material-ui/core/FormLabel";
import {AppTextInput} from "../../common/AppTextInput";

export default observer((props: FieldProps<FieldType.Name>) => {

    const {field, fieldId, data} = props;
    const { errors, register } = useFormContext();

    const firstNameFieldId = NameHelpers.firstNameFieldId(fieldId);
    const lastNameFieldId = NameHelpers.lastNameFieldId(fieldId);

    const updateRequired = action((event: any, checked: boolean) => {
        field.config.isRequired = checked;
    });

    const editFieldId = "e-" + field.id;

    return (
        <>
            {props.fieldRenderMode === FieldRenderMode.Edit &&
            <div key="edit" className="w-full">
                <AppTextInput autoFocus
                           name={editFieldId}
                           label="Field title"
                           defaultValue={props.field.label}
                           onChange={action((event) => { field.isClean = false; field.label = event.target.value; })}
                           inputRef={register({required: true})}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={updateRequired}
                            checked={field.config.isRequired}
                            color="primary"
                        />
                    }
                    label="Is required?"
                />
                <div className="text-xs mt-2">
                    {props.isInsideGroup ? "NOTE: This field will not be used for the submission listing as it's inside a repeated group." :
                        "NOTE: If the form contains multiple 'Name' fields, the first one is used for the submission listing and for search."
                    }
                </div>

            </div>
            }
            {props.fieldRenderMode === FieldRenderMode.Fill &&
            <>
                <FormLabel className="mb-4" component="legend">{field.label}</FormLabel>
                <div key="fill" className="w-full flex mb-4">
                <span className="flex-1 mr-1 w-full">
                    <TextField {...textInputProps(firstNameFieldId, errors)}
                               label="First"
                               id={firstNameFieldId}
                               inputRef={register<HTMLInputElement>(props.fillValidationOpts as any)}
                    />
                </span>
                    <span className="flex-1 ml-1 w-full">
                    <TextField {...textInputProps(lastNameFieldId, errors)}
                               label="Last"
                               id={lastNameFieldId}
                               inputRef={register<HTMLInputElement>(props.fillValidationOpts as any)}
                    />
                </span>
                </div>
            </>
            }
            {props.fieldRenderMode === FieldRenderMode.Display &&
            <div key="display" className="w-full">
                <div className="field-display-name">{field.label}:</div>
                <div className="field-display-data">
                    {data?.firstName !== "" || data?.lastName !== "" ? `${data?.firstName} ${data?.lastName}` : "(none)"}
                </div>
            </div>
            }
        </>
    )
});