import React, {useCallback, useContext, useEffect} from "react";
import { withRouter } from "react-router-dom";
import { matchRoutes } from "react-router-config";
import AppContext from "appContext";
import Layout1 from "./Layout1/Layout1";
import {useStores} from "../stores/context";
import {runInAction} from "mobx";
import {Location} from "history";

interface Props {
  location: Location<any>
}

const MatxLayoutSFC = (props: Props) => {
  const appContext = useContext(AppContext);

  const {uiLayoutStore} = useStores();

  const updateSettingsFromRouter = useCallback(() => {
    const { routes } = appContext as any;
    const matched = matchRoutes(routes, props.location.pathname)[0];

    runInAction(() => {
      if (matched && matched.route.settings) {
        uiLayoutStore.showSideNav = matched.route.settings.showSideNav;

        uiLayoutStore.showHeader = matched.route.settings.showHeader;
        uiLayoutStore.showFooter = matched.route.settings.showFooter;
      } else {
        uiLayoutStore.reset();
      }
    });

  }, [appContext, props.location.pathname, uiLayoutStore]);

  useEffect(() => {
    updateSettingsFromRouter();
  }, [props.location, updateSettingsFromRouter]);

  return (
    <Layout1 {...props} />
  );
};

export default withRouter(
    MatxLayoutSFC
);
