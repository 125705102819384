export async function downloadUrl(url: string, filename: string): Promise<void> {
    const response = await fetch(url);
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = blobUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
}

export function downloadImage(width: number, height: number, src: string): void {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    const image = new Image(width, height);
    image.src = src;
    image.onload = () => {
        context.drawImage(image, 0, 0);
        let uri = canvas
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream');
        downloadUrl(uri, "qrcode.png").then();
    }
}