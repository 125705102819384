export const generateSvgFromText = (textToSVG: any, text: string) => {
    const options = {anchor: 'left top', attributes: {stroke: 'black', fill: 'black'}};
    const svg = textToSVG.getSVG(text, options) as string;
    const widthExtract = /width="([\d.]+)"/.exec(svg);
    const width = widthExtract && widthExtract.length > 1 ? widthExtract[1] : undefined;
    const heightExtract = /height="([\d.]+)"/.exec(svg);
    const height = heightExtract && heightExtract.length > 1 ? heightExtract[1] : undefined;
    if (width && height) {
        return svg.replace(" width=", ` viewBox="0 0 ${width} ${height}" width=`);
    } else {
        return new Error('Could not update SVG width or height');
    }
}