import React from "react";
import {textInputProps, useHideOnEnterFn} from "../forms/editFields/defaultProps";
import TextField from "@material-ui/core/TextField";
import { useFormContext } from "react-hook-form";

interface Props {
    name: string;
    label: string;
    defaultValue: string;
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    autoFocus?: boolean;
    inputRef?: React.Ref<any>;
    multiline?: boolean;
}

export const AppTextInput = (props: Props) => {

    const hideOnEnterFn = useHideOnEnterFn();
    const { errors } = useFormContext();

    return <TextField
        {...textInputProps(props.name, errors)}
        id={props.name}
        label={props.label}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        inputRef={props.inputRef}
        onKeyPress={hideOnEnterFn}
        autoFocus={props.autoFocus}
        multiline={props.multiline}
    />;

}