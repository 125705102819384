import {observable, action, runInAction} from "mobx";
import {functionsRoot} from "../utils/firebaseFunctions";
import {getFirebaseApp} from "firestorter";
import {sleep} from "../utils/sleep";
import {Context, Logger} from "../common/context";
import {CancelToken, withTimeoutUsingCancelToken} from "../utils/promiseUtils";
const auth = getFirebaseApp().auth();
const db = getFirebaseApp().firestore();
type DocumentSnapshot = firebase.firestore.DocumentSnapshot;

export interface UserData {
    orgId: string;
    role: string;
}

export class UserStore {

    @observable user: firebase.User | undefined;
    @observable userData: UserData | undefined;

    @observable isSignedIn = false;
    @observable isSigningIn = false;
    @observable hasAuthState = false;
    @observable hasAuthError = false;
    @observable isCreatingAccount = false;

    private signingInUser: firebase.User | null | undefined = undefined;

    //if we are logging in with a custom token in the url, we may receive a sign out
    //notification. We need to ignore it.
    ignoreAuthSignOut = false;

    private log: Logger;


    constructor(context: Context) {
        this.log = context.logger(this);
    }

    getUser = () => auth.currentUser;

    @action
    async onAuthStateChanged(user: firebase.User | null) {
        if (user?.uid !== this.signingInUser?.uid || (user === null && this.signingInUser === undefined)) {
            this.signingInUser = user;
            if (user) {
                await withTimeoutUsingCancelToken(
                    this.log,
                    (token) => this.setUserSignedIn(user, token),
                    30000,
                    "setUserSignedIn",
                    () => this.onSignOut()
                );
            } else {
                if (!this.ignoreAuthSignOut) {
                    this.onSignOut();
                }
            }
        }
    }

    @action async setUserSignedIn(user: firebase.User, cancelToken: CancelToken) {
        this.isSigningIn = true;
        let userDoc: DocumentSnapshot | undefined;

        try {
            this.log.debug("Fetching user doc");
            for (let i = 1; i < 18; i++) {
                if (i == 2) {
                    runInAction(() => {
                        this.log.info("Creating account");
                        this.isCreatingAccount = true;
                    });
                }
                userDoc = await db.collection("users").doc(user.uid).get();
                if (userDoc.exists || cancelToken.cancelled) {
                    break;
                } else {
                    await sleep(i * 100);
                }
            }

            if (cancelToken.cancelled) {
                return;
            }

            if (!userDoc?.data()?.orgId) {
                throw new Error("Error fetching userDoc with orgId")
            }

        } catch(e) {
            this.log.warn(`Error getting user id orgId: ${e}`);
            runInAction(() => {
                this.isCreatingAccount = false;
                this.hasAuthError = true;
            })
        } finally {
            runInAction(() => {
                this.isCreatingAccount = false;
                this.isSigningIn = false;
            });
        }

        if (userDoc?.data()?.orgId) {
            runInAction(() => {
                this.userData = {
                    role: "USER",
                    orgId: userDoc!.data()!.orgId
                };
                this.log.info("Built userData", this.userData);
                this.user = user;
                this.isSignedIn = true;
                this.hasAuthState = true;
            });
        }
    }

    @action onSignOut() {
        this.user = undefined;
        this.userData = undefined;
        this.isSignedIn = false;
        this.hasAuthState = true;
        this.isSigningIn = false;
    }

    async signOut() {
        this.onSignOut();
        await auth.signOut();
    }

    async getFormsToken(): Promise<string | undefined> {
        try {
            const startTime = new Date().getTime();
            const createBuilderToken = functionsRoot().httpsCallable('createFormsToken');
            this.log.info(`Fetched forms token in ${new Date().getTime() - startTime}ms`)
            const response = await createBuilderToken();
            return response.data.customToken;
        } catch(err) {
            this.log.error(err, "Error creating builder token");
            return undefined;
        }

    }

    async sendPasswordReset(): Promise<void> {
        if (this.user?.email) {
            await auth.sendPasswordResetEmail(this.user.email);
        }
    }

    @action
    async signInWithCustomToken(authToken: string) {
        this.ignoreAuthSignOut = true;
        try {
            await auth.signInWithCustomToken(authToken);
        } catch(e) {
            console.log(`Error signing in with custom token ${e}`);
        } finally {
            this.ignoreAuthSignOut = false;
        }
    }
}

export class AuthException extends Error {
    constructor(message?: string) {
        super(message);
        // see: typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
        this.name = AuthException.name; // stack traces display correctly now
    }
}
