import * as React from "react";
import {ChangeEvent, useRef} from "react";
import {FieldRenderMode, FieldType} from "shared/forms/model";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import {action, runInAction} from "mobx";
import {observer} from "mobx-react";
import {FieldProps} from "./field";
import {Controller, get, useFormContext} from "react-hook-form";
import {errorMessage} from "shared/utils/error";
import {DatePicker} from '@material-ui/pickers';
import {DateFormat, dateFormatters, formatTimestamp, TimestampFormat} from "shared/utils/dateUtils";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {textInputProps} from "./defaultProps";

const maskMap = {
    [DateFormat.Dmy]: "__/__/____",
    [DateFormat.Mdy]: "__/__/____",
    [DateFormat.Ymd]: "____/__/__",
};

export default observer((props: FieldProps<FieldType.Date | FieldType.Dob>, ref: React.Ref<HTMLInputElement>) => {


    const {field, fieldId} = props;
    const { errors, register } = useFormContext();
    const inputRef = useRef<HTMLInputElement>(null);

    const updateLabel = (event: any) => {
        runInAction(() => {
            field.isClean = false;
            field.label = event.target.value;
        });
    };

    const updateMinimumAge = action((event: any) => {
        if (field.type === FieldType.Dob) {
            field.config.minimumAge = parseInt(event.target.value, 10);
        }
    });

    const updateMaximumAge = action((event: any) => {
        if (field.type === FieldType.Dob) {
            field.config.maximumAge = parseInt(event.target.value, 10);
        }
    });

    const editFieldId = "e-" + field.id;

    return (
        <>
            {props.fieldRenderMode === FieldRenderMode.Edit &&
                <div key="edit" className="w-full">
                <div className="mb-2">
                    <TextField autoFocus
                           name={editFieldId}
                           id="label"
                           className="w-full"
                           label="Field title"
                           defaultValue={props.field.label}
                           onChange={updateLabel}
                           variant="outlined"
                           inputRef={register({required: true})}
                           error={errors && errors[editFieldId] !== undefined}
                           helperText={errorMessage(errors[editFieldId])}
                />
                </div>
                {field.type === FieldType.Dob && <>
                    <div className="w-full flex mb-4">
                        <span className="flex-1 mr-1 w-full">
                            <TextField {...textInputProps(`${editFieldId}-minimum`, errors)}
                                       id="minage"
                                       label="Minimum Age"
                                       defaultValue={field.config.minimumAge}
                                       onChange={updateMinimumAge}
                                       type="number"
                                       inputRef={register({pattern: /^[0-9]+$/})}
                            />
                             </span>
                        <span className="flex-1 ml-1 w-full">
                            <TextField {...textInputProps(`${editFieldId}-maximum`, errors)}
                                       id="maxage"
                                       label="Maximum Age"
                                       defaultValue={field.config.maximumAge}
                                       onChange={updateMaximumAge}
                                       type="number"
                                       inputRef={register({pattern: /^[0-9]+$/})}
                            />
                        </span>
                    </div>
                </>
                }

                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={action((event: any, checked: boolean) => { field.config.isRequired = checked; })}
                            checked={field.config.isRequired}
                            color="primary"
                        />
                    }
                    label="Is required?"
                />
                {field.type === FieldType.Date &&
                    <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={action((event: any, checked: boolean) => { field.config.defaultToToday = checked; })}
                                checked={field.config.defaultToToday}
                                color="primary"
                            />
                        }
                        label="Default to current date"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={action((event: any, checked: boolean) => {
                                    field.config.disablePastDates = !checked;
                                    if (field.config.disablePastDates) {
                                        field.config.disableFutureDates = false;
                                    }
                                })}
                                checked={!field.config.disablePastDates}
                                color="primary"
                            />
                        }
                        label="Allow past dates"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={action((event: any, checked: boolean) => {
                                    field.config.disableFutureDates = !checked;
                                    if (field.config.disableFutureDates) {
                                        field.config.disablePastDates = false;
                                    }
                                })}
                                checked={!field.config.disableFutureDates}
                                color="primary"
                            />
                        }
                        label="Allow future dates"
                    />
                </>}

                <FormControl variant="outlined">
                    <InputLabel id={`${field.id}-date-format`}>Date format (web)</InputLabel>
                    <Select<string>
                        labelId={`${field.id}-date-format`}
                        value={field.config.dateFormat}
                        onChange={action((event: ChangeEvent<{ name?: string | undefined; value: string; }>) => { field.config.dateFormat = event.target.value as DateFormat; })}
                        label="Date format (web)"
                    >
                        <MenuItem value="Mdy">mm/dd/yyyy</MenuItem>
                        <MenuItem value="Dmy">dd/mm/yyyy</MenuItem>
                        <MenuItem value="Ymd">yyyy/mm/dd</MenuItem>
                    </Select>
                </FormControl>
            </div>
            }
            {props.fieldRenderMode === FieldRenderMode.Fill &&
            <div key="fill" className="w-full">
                <Controller
                    name={fieldId}
                    rules={props.fillValidationOpts}
                    onFocus={() => {
                        inputRef.current?.scrollIntoView();
                        inputRef.current?.focus();
                    }}
                    defaultValue={(field.type === FieldType.Date && field.config.defaultToToday) ? new Date() : null}
                    render={({onChange, onBlur, value}) => {
                        const fieldError = get(errors, fieldId);

                        return <DatePicker
                            className="w-full mb-4"
                            renderInput={props => <div className="mb-4">
                                <TextField
                                {...props}
                                id={fieldId}
                                ref={inputRef}
                                variant="outlined"
                                className="w-full"
                                error={fieldError !== undefined}
                                helperText={errorMessage(fieldError)}
                                onBlur={onBlur}
                            /></div>}
                            label={field.label}
                            disablePast={field.type === FieldType.Date ? field.config.disablePastDates : false}
                            disableFuture={field.type === FieldType.Date ? field.config.disableFutureDates : true}
                            clearable={!field.config.isRequired}
                            inputFormat={dateFormatters[field.config.dateFormat]}
                            mask={maskMap[field.config.dateFormat]}
                            value={value}
                            onAccept={onBlur}
                            onChange={onChange}
                        />;}}
                />
            </div>
            }
            {props.fieldRenderMode === FieldRenderMode.Display &&
            <div key="display" className="w-full">
                <div className="field-display-name">
                    {field.label}:
                </div>
                <div className="field-display-data">
                    {props.data?.value ? formatTimestamp(props.data?.value, TimestampFormat.DateFormat) : "(none)"}
                </div>
            </div>
            }
        </>
    )
});