import {errorMessage} from "shared/utils/error";
import {get} from "react-hook-form";
import {KeyboardEvent, useCallback} from "react";

interface TextInputProps {
    name: string,
    error: boolean;
    helperText: string;
    className: string;
    variant: "outlined";
}

export const textInputProps: (name: string, errors: any, className?: string) => TextInputProps = (name: string, errors: any, className?: string) => {
    const fieldError = get(errors, name);
    return {
        name,
        error: fieldError !== undefined,
        helperText: errorMessage(fieldError),
        variant: "outlined",
        className: `w-full ${className || ""}`
    }
};

export const useHideOnEnterFn = () => useCallback((event: KeyboardEvent) => {
    if (event.key === "Enter" && /Android/.test(navigator.userAgent)) {
        event.preventDefault();
        (event.target as any).blur();
    }
}, []);