export interface GroupDoc {
    createdAt: firebase.firestore.Timestamp;
    clientCreatedAt: firebase.firestore.Timestamp;
    clientCreatedAtTz: string;
    name: string;
    nameLower: string;
    location: string;
    subCounter: number,
    eventStartAt: firebase.firestore.Timestamp;
    eventEndAt: firebase.firestore.Timestamp;
    formIds: string[],
    chooseFormTitle: string;
    chooseFormDescription: string;
    chooseFormImagePath: string | null;
    sentInvitations: string[];
    orgId: string;
}

export interface Group {
    createdAt: firebase.firestore.Timestamp;
    clientCreatedAt: firebase.firestore.Timestamp;
    clientCreatedAtTz: string;
    name: string;
    nameLower: string;
    location: string;
    subCounter: number,
    eventStartAt: firebase.firestore.Timestamp | null;
    eventEndAt: firebase.firestore.Timestamp | null;
    formIds: Set<string>;
    chooseFormTitle: string;
    chooseFormDescription: string;
    chooseFormImagePath: string | null;
    sentInvitations: string[];
    orgId: string;
}

export const groupsEntity = "groups";

export const allGroupsId = "all";