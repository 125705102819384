import * as React from "react";
import {SimpleCard, MatxLoading} from "matx";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

import {observer} from "mobx-react";
import {useForm} from "react-hook-form";
import {useHistory, useParams} from "react-router-dom";
import {formatTimestamp, TimestampFormat} from "shared/utils/dateUtils";
import {formLink} from "shared/forms/webLink";
import {QRCode} from "react-qr-svg";
import {
    EditCard,
    EditCardFieldData,
    EditCardFieldTitle,
} from "../editcard/EditCard";
import {useEffect, useRef, useState} from "react";
import {useMemo} from "use-memo-one";
import {useQuery} from "../../hooks/useQuery";
import {useStores} from "../../stores/context";
import {setTitle} from "../utilities/setTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {emailValidator} from "shared/forms/validationOpts";
import {AckDialog} from "../common/AckDialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import {FullWidthPageWrapper} from "../../matx/components/PageWrapper";
import {handleCopyUrlToClipboard} from "../utilities/copyUrl";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {downloadImage} from "../utilities/download";
import useBlockNavigation from "../../hooks/useBlockNavigation";

type FormData = {
    name: string;
};

type SubmissionFormData = {
    notificationEmail: string | undefined
};

export default observer(() => {
    const {id} = useParams();
    const {formEditStoreFactory, billingStore, settingsStore} = useStores();
    billingStore.ensureInitialized();
    settingsStore.ensureInitialized();
    const formEditStore = useMemo(() => formEditStoreFactory(id, true), [formEditStoreFactory, id]);
    const formConfigEditStore = formEditStore.formConfigEditStore;
    const urlRef = useRef<HTMLAnchorElement>(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [hasCopied, setHasCopied] = useState(false);
    const [showingUpgradeDialog, setShowingUpgradeDialog] = useState(false);
    const history = useHistory();
    const hookContext = useForm<FormData>({mode: "onBlur"});
    const submissionHookContext = useForm<SubmissionFormData>();
    const query = useQuery();
    const isNew = query.get("isNew") === "true";
    const clearUpgradeDialogRef = useRef(() => {
        setShowingUpgradeDialog(false);
    })
    const unblockNavigation = useBlockNavigation(() => isNew);

    useEffect(() => {
        if (!id) {
            unblockNavigation();
            history.push("/forms/builder");
        }
    }, [id, history, unblockNavigation]);

    const handleSaveFormSummary = async () => {
        await formEditStore.saveNameAndPublishStatus();
        setIsEditMode(false);
        return true;
    };

    const handleSaveFormSubmissionSettings = async () => {
        if (formConfigEditStore) {
            await formConfigEditStore.saveNotificationSettings();
            await formEditStore.saveSubmissionSettings();
            return true;
        } else {
            return false;
        }
    }

    const handleSetIsEditMode = (value: boolean) => {
        setIsEditMode(value);
    };

    const handleDone = async () => {
        unblockNavigation();
        history.push("/forms");
    };

    const handlePublish = async () => {
        if (await hookContext.trigger()) {
            const showFormWarning = billingStore.aboutToReachFormAllowance();
            formEditStore.setPublished(true);
            await formEditStore.save();
            if (showFormWarning) {
                setShowingUpgradeDialog(true);
                clearUpgradeDialogRef.current = () => {
                    unblockNavigation();
                    history.push("/forms");
                };
            } else {
                unblockNavigation();
                history.push("/forms");
            }
        }
    };

    const setPublished = (checked: boolean) => {
        if (checked) {
            setShowingUpgradeDialog(billingStore.aboutToReachFormAllowance());
            clearUpgradeDialogRef.current = () => {
                setShowingUpgradeDialog(false);
            };
        }
        formEditStore.setPublished(checked)
    }

    useEffect(() => {
        setTitle(`Form Details`);
    }, []);

    const handleGoFormBuilder = () => {
        unblockNavigation();
        history.push(`/forms/builder?formId=${formEditStore.id}&isNew=${isNew}`);
    };

    const handleBack = () => {
        if (isNew) {
            handleGoFormBuilder();
        } else {
            unblockNavigation();
            history.push("/forms");
        }
    };

    return (
        formEditStore.isLoading || !formConfigEditStore || formConfigEditStore.isLoading ? <MatxLoading/> :
            <FullWidthPageWrapper>
                <div className="max-w-6xl mx-auto">
                {showingUpgradeDialog &&
                <AckDialog
                    title="Form Allowance"
                    okText="Ok"
                    handleClose={clearUpgradeDialogRef.current}
                >
                    <DialogContentText>
                        Your current billing plan has allowance for {billingStore.maxForms} published form{billingStore.maxForms !== 1 ? "s" : ""}. If you need additional forms then please upgrade in the Billing section within 7 days.
                    </DialogContentText>
                </AckDialog>
                }
                <div className="flex justify-between h-full items-center mb-3">
                    <IconButton onClick={handleBack}><Icon aria-hidden={false} aria-label="Back">arrow_back</Icon></IconButton>
                    <div>
                        <span className="ml-2 mt-1 mb-1">
                            <Button onClick={handleGoFormBuilder} color="primary" variant="contained" disabled={isEditMode}>
                                <Icon>build</Icon>
                                <span className="pl-2 capitalize">Form Builder</span>
                            </Button>
                        </span>
                        {isNew &&
                        <span className="ml-2 mt-1 mb-1">
                            <Button onClick={handleDone} color="primary" variant="contained"
                                    disabled={isEditMode}>
                                <Icon>save</Icon>
                                <span
                                    className="pl-2 capitalize">Save {!formEditStore.entity.isPublished && "as draft"}</span>
                            </Button>
                        </span>
                        }
                        {!formEditStore.entity.isPublished &&
                        <span className="ml-2 mt-1 mb-1">
                            <Button onClick={handlePublish} color="secondary" variant="contained" disabled={isEditMode}>
                                <Icon>send</Icon>
                                <span className="pl-2 capitalize">Publish</span>
                            </Button>
                        </span>
                        }
                    </div>
                </div>
                <SimpleCard style={{overflow: "visible"}} title="Form Details">
                    <div>
                        <EditCard
                            title="Form Summary"
                            view={
                                <>
                                    <EditCardFieldTitle>Form Name</EditCardFieldTitle>
                                    <EditCardFieldData>{formEditStore.entity.name}</EditCardFieldData>
                                    <EditCardFieldTitle>Is published?</EditCardFieldTitle>
                                    <EditCardFieldData>{formEditStore.entity.isPublished ? "Yes" : "No"}</EditCardFieldData>
                                    {formEditStore.isNew &&
                                    <>
                                        <EditCardFieldTitle>Created at</EditCardFieldTitle>
                                        <EditCardFieldData>{formatTimestamp(formEditStore.entity.createdAt, TimestampFormat.DateTimeFormat)}</EditCardFieldData>
                                        <EditCardFieldTitle>Last updated at</EditCardFieldTitle>
                                        <EditCardFieldData>{formatTimestamp(formEditStore.entity.updatedAt, TimestampFormat.DateTimeFormat)}</EditCardFieldData>
                                    </>
                                    }
                                </>
                            }
                            onSave={handleSaveFormSummary}
                            openByDefault={formEditStore.entity.name === "" || !formEditStore.entity.name}
                            setIsEditMode={handleSetIsEditMode}
                            hookContext={hookContext}
                            editForm={
                                <div className="mb-4">
                                    <TextField className="w-full"
                                               id="name"
                                               name="name"
                                               autoFocus
                                               defaultValue={formEditStore.entity.name}
                                               label="Form Name"
                                               inputRef={hookContext.register({required: true})}
                                               error={hookContext.errors.name?.message !== undefined}
                                               helperText={hookContext.errors.name?.message}
                                               onChange={event => formEditStore.setFormName(event.target.value)}
                                               variant="outlined"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={formEditStore.entity.isPublished}
                                            onChange={(event, checked) => setPublished(checked)}
                                        />}
                                        className="mt-2"
                                        label="Published (available to be filled in)"
                                    />
                                </div>
                            }/>

                        <EditCard
                            title="Share Form"
                            view={<>
                                <EditCardFieldTitle>Web
                                    URL {!formEditStore.entity.isPublished && " - will be active after form is set to published in 'Form Summary' section above"}</EditCardFieldTitle>
                                <EditCardFieldData><a className="underline break-all" target="_blank" rel="noopener noreferrer"
                                                      href={formLink(formEditStore.id!)} ref={urlRef}>{formLink(formEditStore.id!)}</a>
                                    <Tooltip open={hasCopied} title="Copied">
                                        <Icon className="cursor-pointer" style={{marginLeft: 5, marginTop: -2, position: "absolute"}} fontSize="small"
                                              onClick={() => handleCopyUrlToClipboard(urlRef.current!, setHasCopied)}>content_copy</Icon>
                                    </Tooltip>
                                </EditCardFieldData>
                                <EditCardFieldTitle>QR Code
                                    {!formEditStore.entity.isPublished && " - will be active after form is set to published above"}
                                    </EditCardFieldTitle>
                                <EditCardFieldData>
                                    <div id="qrcode">
                                        <QRCode
                                            className="mt-1 inline-block"
                                            bgColor="#FFFFFF"
                                            fgColor="#000000"
                                            level="Q"
                                            style={{width: 128, verticalAlign: "text-bottom"}}
                                            value={formLink(formEditStore.id!)}
                                        />
                                        <button className="cursor-pointer ml-2" onClick={() => {
                                            const svg = 'data:image/svg+xml;base64,' + btoa(new XMLSerializer().serializeToString(document.querySelector("#qrcode svg") as Node));
                                            downloadImage(512, 512, svg);
                                        }}>(<span className="underline">download</span>)</button>
                                    </div>
                                </EditCardFieldData>
                                <EditCardFieldTitle>Kiosk Mode</EditCardFieldTitle>
                                <EditCardFieldData>
                                    Download the speedyWaiver app from the AppStore to use Kiosk Mode. This lets you turn a tablet, such as an iPad, into a dedicated waiver-filling device.
                                </EditCardFieldData>
                            </>}
                        />
                        <EditCard
                            title="Form Submission"
                            view={
                                <>
                                    <EditCardFieldTitle>Customer can e-mail waiver PDF to self</EditCardFieldTitle>
                                    <EditCardFieldData>{formEditStore.entity.canEmailWaiver ?
                                    `Yes` : "No"}</EditCardFieldData>
                                    <EditCardFieldTitle>E-mail notification on submission</EditCardFieldTitle>
                                    <EditCardFieldData>{formConfigEditStore.entity.notificationEmailEnabled ?
                                        `Yes - ${formConfigEditStore.entity.notificationEmail}` : "No"}</EditCardFieldData>
                                    <EditCardFieldTitle>Additional form submission acknowledgement text</EditCardFieldTitle>
                                    <EditCardFieldData>{!formEditStore.entity.ackText ? "(not set)" : formEditStore.entity.ackText}</EditCardFieldData>
                                    <EditCardFieldTitle>Send user to URL after completing form (web-only)</EditCardFieldTitle>
                                    <EditCardFieldData>{!formEditStore.entity.ackSubmitUrl ? "(not set)" : formEditStore.entity.ackSubmitUrl}</EditCardFieldData>
                                </>
                            }
                            onSave={handleSaveFormSubmissionSettings}
                            setIsEditMode={handleSetIsEditMode}
                            hookContext={hookContext}
                            editForm={
                                <div className="mb-4">

                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={formEditStore.entity.canEmailWaiver}
                                            onChange={(event, checked) => {
                                                formEditStore.setCanEmailWaiver(checked)
                                            }}
                                        />}
                                        className="mt-2 mb-2"
                                        label="Customer can e-mail waiver PDF to self"
                                    />

                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={formConfigEditStore.entity.notificationEmailEnabled}
                                            onChange={(event, checked) => {
                                                formConfigEditStore.setNotificationEmailEnabled(checked)
                                                if (!checked) {
                                                    submissionHookContext.setValue("notificationEmail", "");
                                                    formConfigEditStore.setNotificationEmail("");
                                                }
                                            }}
                                        />}
                                        className="mt-2 mb-2"
                                        label="E-mail a notification on form submission (up to one notification an hour)"
                                    />
                                    <TextField className="w-full mt-2"
                                               name="notificationEmail"
                                               id="notificationEmail"
                                               type="email"
                                               disabled={!formConfigEditStore.entity.notificationEmailEnabled}
                                               defaultValue={formConfigEditStore.entity.notificationEmail}
                                               label="E-mail address to notify"
                                               inputRef={submissionHookContext.register({validate: emailValidator})}
                                               error={submissionHookContext.errors.notificationEmail?.message !== undefined}
                                               helperText={submissionHookContext.errors.notificationEmail?.message}
                                               onChange={event => formConfigEditStore.setNotificationEmail(event.target.value)}
                                               variant="outlined"
                                    />
                                    <div className="mt-8"><TextField className="w-full"
                                                                     id="ackText"
                                                                     name="ackText"
                                                                     defaultValue={formEditStore.entity.ackText}
                                                                     label="Additional form submission acknowledgement text"
                                                                     multiline={true}
                                                                     rows={3}
                                                                     onChange={event => formEditStore.setAckText(event.target.value)}
                                                                     variant="outlined"
                                    />
                                    </div>
                                    <div className="mt-8"><TextField className="w-full mt-2"
                                                                     name="ackSubmitUrl"
                                                                     id="ackSubmitUrl"
                                                                     type="url"
                                                                     defaultValue={formEditStore.entity.ackSubmitUrl}
                                                                     label="Send user to URL after completing form (web-only)"
                                                                     onChange={event => formEditStore.setAckSubmitUrl(event.target.value)}
                                                                     variant="outlined"
                                    /></div>

                                </div>
                            }/>
                        {settingsStore.isMailchimpEnabled && <EditCard
                            title="Mailchimp integration"
                            view={
                                <>
                                    <EditCardFieldTitle>Add e-mail addresses to Mailchimp list</EditCardFieldTitle>
                                    <EditCardFieldData>{formEditStore.entity.addToMailchimpListName || "(none)"}</EditCardFieldData>
                                </>
                            }
                            onSave={async () => {await formEditStore.saveMailchimpSettings(); return true; }}
                            setIsEditMode={handleSetIsEditMode}
                            editForm={
                                <>
                                <div className="mb-4">
                                    <FormControl variant="outlined" className="py-2 mb-4 w-full">
                                        <InputLabel>Add e-mail addresses to Mailchimp list</InputLabel>
                                        <Select
                                            label="Mailchimp List"
                                            value={formEditStore.entity.addToMailchimpListId || "none"}
                                            onChange={(event) => { formEditStore.setMailchimpListId(event.target.value as string); }}
                                        >
                                            <MenuItem key="none" value="none">None</MenuItem>
                                            {formEditStore.mailchimpLists && formEditStore.mailchimpLists.lists.map((list) =>
                                                <MenuItem key={list.id} value={list.id}>{list.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="mb-4">
                                To make adding a submission to the list opt-in, create a Checkbox field in the Form Builder, and turn on 'Required for Mailchimp' in the field settings.
                                </div>
                                </>
                            }/>
                        }
                    </div>
                </SimpleCard>
                <div className="py-3"/>
                </div>
            </FullWidthPageWrapper>
    );
});