import * as React from "react";
import { SimpleCard } from "matx";
import ListingTable from "./ListingTable";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

import {useHistory} from "react-router-dom";
import {useEffect} from "react";
import {setTitle} from "../utilities/setTitle";
import {FullWidthPageWrapper} from "../../matx/components/PageWrapper";

export default () => {
    const history = useHistory();

    const topRightContent = <Button onClick={() => history.push('/forms/builder')} variant="contained" color="primary">
        <Icon>add</Icon><span className="pl-2">Create New Form</span>
    </Button>;

    useEffect(() => {
        setTitle(`Forms`);
    }, []);


    return (
        <FullWidthPageWrapper>
            <SimpleCard title="Forms" topRightContent={topRightContent}>
                <ListingTable />
            </SimpleCard>
        </FullWidthPageWrapper>
    );
};