import * as React from "react";
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

interface IProps {
    title: string,
    children: React.ReactNode;
    handleClose: () => void;
    handleOk?: () => void;
    okText?: string;
    cancelText?: string;
    loading?: boolean;
    extraActions?: JSX.Element;
    disableBackdropClick?: boolean;
}

export function AckDialog(props: IProps) {
    const handleOk = props.handleOk !== undefined ? props.handleOk : props.handleClose;
    const handleClose = props.handleClose;

    return (<Dialog
        open={true}
        onClose={props.handleClose}
        disableBackdropClick={props.disableBackdropClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
            {props.children}
        </DialogContent>
        <DialogActions>
            {props.extraActions}
            {props.cancelText &&
            <Button onClick={handleClose}>
                {props.cancelText}
            </Button>
            }
            <Button onClick={handleOk} disabled={props.loading} color="primary" className="font-bold"  autoFocus>
                {props.okText || "Done!"}
                {props.loading &&
                <CircularProgress className="ml-2"
                                  size={20}
                />
                }
            </Button>
        </DialogActions>
    </Dialog>);

}

