import * as React from "react";
import {authRoles} from "shared/auth/authRoles";
import GroupListingPage from "./GroupListingPage";
import GroupDetailPage from "./GroupDetailPage";

const groupRoutes = [
    {
        path: "/groups/new",
        component: () => <GroupDetailPage isNew={true}/>,
        auth: authRoles.user
    },
    {
        path: "/groups/:id",
        component: GroupDetailPage,
        auth: authRoles.user
    },
    {
        path: "/groups",
        component: GroupListingPage,
        auth: authRoles.user
    }

];

export default groupRoutes;
