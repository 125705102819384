import {computed, observable, runInAction, reaction} from "mobx";
import {Document, Mode} from "firestorter";
import {OrgDoc, orgsEntity} from "shared/users/model";
import {Context, Logger} from "shared/common/context";
import {RootStore} from "shared/common/rootStore";
import {UserStore} from "shared/auth/userStore";
import {NotFoundException} from "shared/common/editStore";
import {withTimeoutUsingCancelToken} from "../utils/promiseUtils";

export class SettingsStore {

    @observable document?: Document<OrgDoc> = undefined;
    private userStore: UserStore;
    private isInitialized = false;
    private log: Logger;

    constructor(context: Context, rootStore: RootStore) {
        this.userStore = rootStore.userStore;
        this.log = context.logger(this);
    }

    ensureInitialized() {
        if (!this.isInitialized) {
            this.isInitialized = true;
            reaction(() => this.userStore.userData, (userData) => {
                if (userData) {
                    const document = new Document<OrgDoc>(`${orgsEntity}/${userData.orgId}`);
                    withTimeoutUsingCancelToken(this.log, () => document.fetch().then(() => {
                        if (!document.hasData) {
                            throw new NotFoundException();
                        }
                        runInAction(() => {
                            this.document = document;
                        });
                    }), 30000, "SettingsStore", () => {
                        this.isInitialized = false;
                        this.ensureInitialized();
                    }).then();
                } else {
                    if (this.document) {
                        this.document.mode = Mode.Off;
                    }
                    runInAction(() => {
                        this.document = undefined;
                    });
                    this.isInitialized = false;
                }
            }, {fireImmediately: true});
        }
    }

    @computed get isLoading(): boolean {
        return this.document === undefined;
    }

    @computed get isMailchimpEnabled(): boolean {
        return this.document?.data?.mailchimpEnabled || false;
    }

    @computed get companyName(): string {
        return this.document?.data?.companyName || "";
    }

    async updateCompanyName(companyName: string) {
        await this.document?.update({companyName});
    }


}