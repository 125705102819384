import * as React from "react";

//move from being injected to available in runtime, so available to shared library
// @ts-ignore
window.process = {
    env: {
        ...(window?.process?.env),
        REACT_APP_ENV: process.env.REACT_APP_ENV,
        REACT_APP_PADDLE_VENDOR_ID: process.env.REACT_APP_PADDLE_VENDOR_ID,
        REACT_APP_HOST: process.env.REACT_APP_HOST
    }
};

if (process.env.NODE_ENV === 'development' && false) {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        trackAllPureComponents: true,
        logOnDifferentValues: true
    });
}

