export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    const debounced = (...args: Parameters<F>) => {
        if (timeout !== null) {
            clearTimeout(timeout);
            timeout = null;
        }
        timeout = setTimeout(() => func(...args), waitFor);
    };

    return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export const groupBy = <T>(
    array: T[],
    fn: (item: T) => any
) => {
    const ret: Record<string, T[]> = {};
    array.forEach((item) => {
        const value = fn(item);
        const key = String(value);
        if (!ret[key]) {
            ret[key] = [];
        }
        ret[key].push(item);
    });
    return ret;
};

export function flatten<T>(arr: (T | T[])[]): T[] {
    const res: T[] = [];
    for(const el of arr) {
        if(Array.isArray(el)) {
            res.push(...flatten(el))
        } else {
            res.push(el)
        }
    }
    return res;
}

//deep merge from https://gist.github.com/mir4ef/c172583bdb968951d9e57fb50d44c3f7

interface IIsObject {
    (item: any): boolean;
}

export interface IObject {
    [key: string]: any;
}

interface IDeepMerge {
    (target: IObject, ...sources: Array<IObject>): IObject;
}

/**
 * @description Method to perform a deep merge of objects
 * @param {Object} target - The targeted object that needs to be merged with the supplied @sources
 * @param {Array<Object>} sources - The source(s) that will be used to update the @target object
 * @return {Object} The final merged object
 */
export const deepMerge: IDeepMerge = (target: IObject, ...sources: Array<IObject>): IObject => {
    /**
     * @description Method to check if an item is an object. Date and Function are considered
     * an object, so if you need to exclude those, please update the method accordingly.
     * @param item - The item that needs to be checked
     * @return {Boolean} Whether or not @item is an object
     */
    const isObject: IIsObject = (item: any): boolean => {
        return (item === Object(item) && !Array.isArray(item));
    };

    // return the target if no sources passed
    if (!sources.length) {
        return target;
    }

    const result: IObject = target;

    if (isObject(result)) {
        const len: number = sources.length;

        for (let i = 0; i < len; i += 1) {
            const elm: any = sources[i];

            if (isObject(elm)) {
                for (const key in elm) {
                    if (Object.prototype.hasOwnProperty.call(elm, key)) {
                        if (isObject(elm[key])) {
                            if (!result[key] || !isObject(result[key])) {
                                result[key] = {};
                            }
                            deepMerge(result[key], elm[key]);
                        } else {
                            if (Array.isArray(result[key]) && Array.isArray(elm[key])) {
                                // concatenate the two arrays and remove any duplicate primitive values
                                result[key] = Array.from(new Set(result[key].concat(elm[key])));
                            } else {
                                result[key] = elm[key];
                            }
                        }
                    }
                }
            }
        }
    }

    return result;
};
