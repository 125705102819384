import React from "react";
import NotFound from "./NotFound";

const settings = {
  showSideNav: false,
  showHeader: false,
  showFooter: false,

  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false
    },
    leftSidebar: {
      show: false,
      mode: "close"
    }
  },
  footer: { show: false }
};

const sessionRoutes = [
  {
    path: "/session/signin",
    component: React.lazy(() => import("./SignIn")),
    settings
  },
  {
    path: "/session/404",
    component: NotFound,
    settings
  }
];

export default sessionRoutes;
