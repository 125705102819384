import {Group, GroupDoc, groupsEntity} from "./model";
import {EditStore} from "../common/editStore";

import {Context} from "../common/context";
import { action, computed, reaction, observable, runInAction } from "mobx";
import {FormListStore} from "../forms/formListStore";
import {RootStore} from "../common/rootStore";
import {notEmpty} from "../utils/tsUtils";
import {groupLink} from "../forms/webLink";
import {formsEntity} from "../forms/model";
import {getFirebaseApp} from "firestorter";
import {functionsRoot} from "../utils/firebaseFunctions";
import {SettingsStore} from "../users/settingsStore";
const db = getFirebaseApp().firestore();

export class GroupEditStore extends EditStore<Group, GroupDoc> {

    static scOffset = 25354;

    private publishedFormListStore: FormListStore;
    private settingsStore: SettingsStore;
    @observable formNamesByIdForView: {formId: string, formName: string}[] | undefined = undefined;

    @observable isSendingInvites = false;
    @observable hasSentInvites = false;

    constructor(context: Context, rootStore: RootStore, timeZone: string, id: string | undefined, isForView: boolean) {
        super(context, rootStore.userStore, groupsEntity, timeZone, id, id === undefined ? GroupEditStore.emptyEntity(rootStore.userStore.userData!.orgId) : undefined);
        this.log = context.logger(this);

        this.publishedFormListStore = rootStore.publishedFormListStore;
        this.settingsStore = rootStore.settingsStore;
        this.settingsStore.ensureInitialized();

        if (isForView) {
            reaction(() => this.entity, (entity) => {
                Promise.all(Array.from(entity.formIds.values()).map(async (formId) => {
                    try {
                        const formDoc = await db.collection(formsEntity).doc(formId).get()
                        const data = formDoc && formDoc.exists ? formDoc.data() : undefined;
                        if (data) {
                            return {formId, formName: data.name as string };
                        } else {
                            return undefined;
                        }
                    } catch (e) {
                        this.log.error("Error reading form")
                        return undefined;
                    }

                })).then(action(result => { this.formNamesByIdForView = result.filter(notEmpty); } ));
            })

        } else {
            this.publishedFormListStore.ensureInitialized();
        }
    }

    static emptyEntity(orgId: string): Group {
        return {
            name: "",
            nameLower: "",
            location: "",
            eventStartAt: null,
            eventEndAt: null,
            formIds: new Set<string>(),
            chooseFormTitle: "Choose Form",
            chooseFormDescription: "",
            chooseFormImagePath: null,
            sentInvitations: [],
            subCounter: this.scOffset,
            orgId,
            createdAt: undefined as any,
            clientCreatedAt: undefined as any,
            clientCreatedAtTz: undefined as any
        };
    }

    entity2Doc(entity: Group): GroupDoc {
        return {
            ...(entity) as any,
            formIds: Array.from(entity.formIds.values())
        };
    }

    doc2Entity(doc: GroupDoc): Group {
        return {
            ...(doc),
            formIds: new Set(doc.formIds)
        };
    }

    @action
    async saveFormIds() {
        await this.partialUpdate({formIds: Array.from(this.entity.formIds.values())});
    }

    @action
    async saveCustomization() {
        await this.partialUpdate({
            chooseFormTitle: this.entity.chooseFormTitle,
            chooseFormDescription: this.entity.chooseFormDescription,
            chooseFormImagePath: this.entity.chooseFormImagePath
        });
    }

    @action
    setGroupName = (name: string) => {
        this.entity.name = name;
        this.entity.nameLower = name.normalize('NFKC').toLowerCase().toUpperCase().toLowerCase();
    };

    @action
    setGroupLocation = (location: string) => {
        this.entity.location = location;
    };

    get groupLink(): string {
        return groupLink(this.id!);
    }

    @computed get formNamesById() {
        const formNamesById = this.publishedFormListStore.formNamesById;
        return Array.from(this.entity.formIds.values()).map((formId) => {
            const formName = formNamesById.get(formId);
            return formName ? {formId, formName} : undefined;
        }).filter(notEmpty);
    }

    @computed get formNames() {
        const formNamesById = this.publishedFormListStore.formNamesById;
        return Array.from(this.entity.formIds.values()).map((formId) =>
            formNamesById.get(formId)
        ).filter(notEmpty).join(", ");
    }

    @action
    async sendInvites(companyName: string, inviteAddresses: string) {
        if (!this.isSendingInvites) {
            this.isSendingInvites = true;

            const alreadySentTo = new Set(this.entity.sentInvitations);
            const inviteAddressesArray = inviteAddresses
                .split(/[,\n]/)
                .map(emailAddress => emailAddress.trim().toLowerCase())
                .filter(emailAddress => emailAddress !== "" && !alreadySentTo.has(emailAddress));
            this.entity.sentInvitations = (this.entity.sentInvitations || []).concat(...inviteAddressesArray);
            await this.partialUpdate({sentInvitations: this.entity.sentInvitations});

            const sendInvitesFn = functionsRoot().httpsCallable('sendGroupInvites');
            await sendInvitesFn({
                companyName,
                destinationAddresses: inviteAddressesArray,
                groupName: this.entity.name,
                groupId: this.id
            });

            if (companyName !== this.settingsStore.companyName) {
                await this.settingsStore.updateCompanyName(companyName);
            }

            runInAction(() => {
                this.isSendingInvites = false;
                this.hasSentInvites = true;
            });
        }
    }
}