import React from 'react'
import {FormListStore} from "shared/forms/formListStore";
import {SubmissionListStore} from "shared/submissions/submissionListStore";
import {AuthStore} from "./authStore";
import {UserStore} from "shared/auth/userStore";
import {FormEditStore} from "shared/forms/formEditStore";
import {FormConfigEditStore, FormConfigStoreFactory} from "shared/forms/formConfigEditStore";
import {SubmissionEditStore} from "shared/submissions/submissionEditStore";
import {SubmissionExportStore} from "shared/submissions/submissionExportStore";
import {UILayoutStore} from "./uiLayoutStore";
import {BillingStore} from "shared/users/billingStore";
import {logger} from "../sumoLogger";
import {Context} from "shared/common/context";
import {AnalyticsStore} from "shared/analytics/analyticsStore";
import {RootStore} from "shared/common/rootStore";
import history from "../browserHistory";
import {GroupListStore} from "shared/groups/groupListStore";
import {GroupEditStore} from "shared/groups/groupEditStore";
import {SettingsStore} from "shared/users/settingsStore";

const context: Context = {logger, notFoundHandler: () => { history.replace("/session/404"); } };
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const userStore = new UserStore(context);
const formConfigStoreFactory: FormConfigStoreFactory = (id: string | undefined, formId?: string) => new FormConfigEditStore(context, userStore, timeZone, id, formId);
const submissionListStore = new SubmissionListStore(userStore);

class WebStore implements RootStore {
    userStore = userStore;
    settingsStore: SettingsStore = new SettingsStore(context, this);
    formListStore = new FormListStore(context, this, timeZone, false);
    publishedFormListStore: FormListStore = new FormListStore(context, this, timeZone, true);
    submissionListStore = submissionListStore;
    groupListStore = new GroupListStore(userStore);
    groupEditStoreFactory = (id: string | undefined, isForView: boolean) => new GroupEditStore(context, this, timeZone, id, isForView);
    submissionExportStore = new SubmissionExportStore(submissionListStore);
    authStore = new AuthStore(context, userStore);
    formEditStoreFactory = (id: string | undefined, doLoadFormConfig: boolean) => new FormEditStore(context, this, timeZone, id, formConfigStoreFactory, doLoadFormConfig);
    formConfigEditStoreFactory = formConfigStoreFactory;
    submissionEditStoreFactory = (id: string | undefined, formId?: string) => new SubmissionEditStore(context, userStore, timeZone, id, formId);
    uiLayoutStore = new UILayoutStore();
    billingStore = new BillingStore(context, this);
    analyticsStoreFactory = (formId: string, fromDate: Date, toDate: Date) => new AnalyticsStore(formId, fromDate, toDate, timeZone);
}

const webStore = new WebStore();

export const useStores = () => React.useContext(React.createContext(webStore));