import { nanoid } from "nanoid";
import {ConsoleFormattedStream, createLogger, INFO, DEBUG, stdSerializers} from "browser-bunyan";
import {DebugLogger} from "shared/utils/debugLogger";

// const userAgent = typeof window !== 'undefined' ? window.navigator.userAgent : 'no-window';
// const isBot = /bot|crawler|spider|crawling/i.test(userAgent);

const SumoLogger = require('sumo-logger');
const opts = {
    endpoint: "https://endpoint1.collection.eu.sumologic.com/receiver/v1/http/ZaVnC4dhaV1Fia6xTDlYwQnJDjcjJa9N3qPRLEn3sybD5GWXjilQW1TovEZXm_CI5w2h319JjPFGpzTvrxsRAoFLcuhpu3h20ngxV1Jbo7sb6z_StfHzSA==",
    clientUrl: window.location.href,
    returnPromise: false,
    interval: 20000, // Send messages in batches every 20 seconds
    batchSize: 100000, // Or when total log length reaches 100k characters
    sendErrors: true,
    sessionKey: nanoid(),
    sourceName: 'speedywaiver-browser',
};

// Instantiate the SumoLogger
const sumoLogger = new SumoLogger(opts);

const isDebugLoggerEnabled = false;

class SumoStream {

    constructor() {
        window.addEventListener("beforeunload", () => {
            sumoLogger.flushLogs();
        });
    }

    write(rec: any) {
        sumoLogger.log(rec);
    }
}


export const debugLogger = isDebugLoggerEnabled ? new DebugLogger() : undefined;

const streams = [
    {
        level: INFO,
        stream: new SumoStream() as any
    },
    isDebugLoggerEnabled ? {
        level: DEBUG,
        stream: debugLogger
    } : {
        level: DEBUG,
        stream: new ConsoleFormattedStream()
    }
];

export const logger = (name?: string) => createLogger({
    name: name && name.constructor ? name.constructor.name : name || "logger",
    streams,
    serializers: stdSerializers,
});

const unhandledLogger = logger("unhandled");
window.onerror = (err) => {
    unhandledLogger.error({err}, "onerror" );
};

window.onunhandledrejection = (err: PromiseRejectionEvent) => {
    unhandledLogger.error({err: err.reason}, "onunhandledrejection");
};
