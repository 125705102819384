import * as React from "react";
import {AddressHelpers, FieldRenderMode, FieldType} from "shared/forms/model";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import {action} from "mobx";
import {observer} from "mobx-react";
import {FieldProps} from "./field";
import { Controller, useFormContext, useWatch, get } from "react-hook-form";
import {textInputProps} from "./defaultProps";
import {errorMessage} from "shared/utils/error";
import Autocomplete from '@material-ui/lab/Autocomplete';
import places, { ChangeEvent, Suggestion } from 'places.js';
import {useEffect, useState} from "react";
import { useCallback, useMemo } from "use-memo-one";
import {allCountries, Country} from "shared/common/countries";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {AppTextInput} from "../../common/AppTextInput";

function useAlgoliaPlaces( onClear: () => void, onChange: (event: ChangeEvent) => void, doesNeedAlgolia: boolean) {
    const [container, setContainer] = useState<HTMLInputElement>();
    const algoliaClose = React.useRef<() => void>();

    useEffect(() => {
        if (container && doesNeedAlgolia) {
            const algoliaPlaces = places({
                type: "address",
                useDeviceLocation: true,
                appId: 'pl8HKRBPQBKW',
                apiKey: process.env.REACT_APP_ALGOLIA_API_KEY,
                templates: {value: (suggestion: Suggestion) => suggestion.name } as any,
                container
            });
            algoliaPlaces.on("clear", onClear);
            algoliaPlaces.on("change", onChange);
            algoliaClose.current = algoliaPlaces.close;


            return () => {
                algoliaPlaces.removeAllListeners("clear");
                algoliaPlaces.removeAllListeners("change");
            }
        }
    }, [container, onChange, onClear, doesNeedAlgolia]);

    return {setContainer, algoliaClose};
}

export const useStyles = makeStyles((theme) => ({
    paper: {
        boxShadow: "0 1px 10px rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1)"
    }
}));

export default observer((props: FieldProps<FieldType.Address>) => {

    const {field, fieldId, data, isInFormBuilder} = props;
    const { errors, register, setValue, trigger } = useFormContext();

    function countryToFlag(isoCode: string) {
        return typeof String.fromCodePoint !== 'undefined'
            ? isoCode
                .toUpperCase()
                .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
            : isoCode;
    }

    const onClear = useCallback(() => undefined, []);

    const onChange = useCallback((event: ChangeEvent) => {
        setValue(AddressHelpers.address1FieldId(fieldId), event.suggestion.name);
        trigger(AddressHelpers.address1FieldId(fieldId));

        setValue(AddressHelpers.address2FieldId(fieldId), event.suggestion.suburb);
        trigger(AddressHelpers.address2FieldId(fieldId));

        setValue(AddressHelpers.zipCodeFieldId(fieldId), event.suggestion.postcode);
        trigger(AddressHelpers.zipCodeFieldId(fieldId));

        const city = event.suggestion.county && event.suggestion.city ?
            `${event.suggestion.city}, ${event.suggestion.county}` :
            event.suggestion.city || event.suggestion.county;

        setValue(AddressHelpers.cityFieldId(fieldId), city);
        trigger(AddressHelpers.cityFieldId(fieldId));

        setValue(AddressHelpers.stateFieldId(fieldId), event.suggestion.administrative);
        trigger(AddressHelpers.stateFieldId(fieldId));

        const countryCode = event.suggestion.countryCode?.toUpperCase();
        const country = allCountries.find((country) => country.value === countryCode);
        setValue(AddressHelpers.countryFieldId(fieldId), country);
        trigger(AddressHelpers.countryFieldId(fieldId));

    }, [fieldId, setValue, trigger]);

    const {setContainer, algoliaClose} = useAlgoliaPlaces(onClear, onChange, props.fieldRenderMode === FieldRenderMode.Fill && !props.isInFormBuilder);

    const algoliaPlacesRef = useCallback((node) => {
        if (node !== null) {
            setContainer(node);
        }
    }, [setContainer]);

    const watchAddress2 = useWatch({name: AddressHelpers.address2FieldId(fieldId)});
    const watchCity = useWatch({name: AddressHelpers.cityFieldId(fieldId)});
    const watchState = useWatch({name: AddressHelpers.stateFieldId(fieldId)});
    const watchZipCode = useWatch({name: AddressHelpers.zipCodeFieldId(fieldId)});

    const updateAddressRef = useCallback((ref: HTMLInputElement) => {
            register<HTMLInputElement>(ref, props.fillValidationOpts);
            algoliaPlacesRef(ref);
        }, [register, props.fillValidationOpts, algoliaPlacesRef]);

    const autocompleteClasses = useStyles();

    const labelFieldId = "f-" + field.id;

    const registerInFill = useMemo(
        () => isInFormBuilder ? (() => undefined) as any as typeof register : register,
        [isInFormBuilder, register])
    ;

    return (
        <>
            {props.fieldRenderMode === FieldRenderMode.Edit &&
            <div key="edit" className="w-full">
                <AppTextInput autoFocus
                           name={labelFieldId}
                           label="Field title"
                           defaultValue={props.field.label || "Address"}
                           onChange={action((event) => { field.label = event.target.value; })}
                           inputRef={register({required: true})}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={action((event, checked) => {
                                field.config.isRequired = checked;
                                if (!checked) {
                                    field.config.isZipRequired = false;
                                }
                            })}
                            checked={field.config.isRequired}
                            color="primary"
                        />
                    }
                    label="Is required?"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={action((event, checked) => {
                                field.config.isZipRequired = checked;
                                if (checked) {
                                    field.config.isRequired = true;
                                }
                            })}
                            checked={field.config.isZipRequired}
                            disabled={!field.config.isRequired}
                            color="primary"
                        />
                    }
                    label="Is zip/postcode required?"
                />
            </div>
            }
            {props.fieldRenderMode === FieldRenderMode.Fill &&
            <div key="fill" className="w-full mb-4">
                <FormLabel className="mb-4" component="legend">{field.label}</FormLabel>

                <ClickAwayListener onClickAway={() => algoliaClose.current && algoliaClose.current()}>
                    <TextField {...textInputProps(AddressHelpers.address1FieldId(fieldId), errors)}
                        label="Address 1"
                        id={AddressHelpers.address1FieldId(fieldId)}
                        inputRef={updateAddressRef}
                        inputProps={{
                           autoComplete: 'new-password',
                           form: {
                               autocomplete: 'off',
                           },
                        }}
                    />
                </ClickAwayListener>
                <div className="mt-4">
                    <TextField {...textInputProps(AddressHelpers.address2FieldId(fieldId), errors)}
                               id={AddressHelpers.address2FieldId(fieldId)}
                               label="Address 2"
                               InputLabelProps={{
                                   shrink: !!watchAddress2
                               }}
                               inputRef={registerInFill()}
                    />
                </div>
                <div className="mt-4">
                    <TextField {...textInputProps(AddressHelpers.cityFieldId(fieldId), errors)}
                               id={AddressHelpers.cityFieldId(fieldId)}
                               label="City"
                               InputLabelProps={{
                                   shrink: !!watchCity
                               }}
                               inputRef={registerInFill(props.fillValidationOpts)}
                    />
                </div>
                <div className="w-full flex mt-4">
                    <span className="flex-1 mr-1 w-full">
                        <TextField {...textInputProps(AddressHelpers.stateFieldId(fieldId), errors)}
                                   id={AddressHelpers.stateFieldId(fieldId)}
                                   label="State"
                                   InputLabelProps={{
                                       shrink: !!watchState
                                   }}
                                   inputRef={registerInFill(props.fillValidationOpts)}
                        />
                    </span>
                    <span className="flex-1 ml-1 w-full">
                        <TextField {...textInputProps(AddressHelpers.zipCodeFieldId(fieldId), errors )}
                                   id={AddressHelpers.zipCodeFieldId(fieldId)}
                                   label="Zip/Post Code"
                                   InputLabelProps={{
                                       shrink: !!watchZipCode
                                   }}
                                   inputRef={registerInFill(field.config.isZipRequired ? {required: "Required"} : undefined)}
                        />
                    </span>
                </div>
                <Controller
                    name={AddressHelpers.countryFieldId(fieldId)}
                    rules={props.fillValidationOpts}
                    defaultValue={null}
                    render={({onChange, onBlur, value}) => (
                        <Autocomplete
                            classes={{paper: autocompleteClasses.paper}}
                            className="mt-4 w-full"
                            autoHighlight
                            blurOnSelect="touch"
                            value={value}
                            onBlur={onBlur}
                            onChange={(event: React.ChangeEvent<{}>, newValue: Country | null) => {
                                if (newValue) {
                                    onChange(newValue);
                                } else {
                                    onChange(null);
                                }
                            }}
                            getOptionLabel={(option) => option.label}
                            options={allCountries}
                            renderOption={(option) => (
                                <React.Fragment>
                                    <span>{countryToFlag(option.value)}</span>&nbsp;
                                    {option.label}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Choose a country"
                                    error={get(errors, AddressHelpers.countryFieldId(fieldId)) !== undefined}
                                    helperText={errorMessage(get(errors, AddressHelpers.countryFieldId(fieldId)))}
                                    variant="outlined"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    )}
                />

            </div>
            }
            {props.fieldRenderMode === FieldRenderMode.Display &&
            <div key="display" className="w-full">
                <div className="field-display-name">{field.label}:</div>
                <div className="field-display-data">
                    {AddressHelpers.isEmpty(data) && "(none)"}
                    {data?.address1}{data?.address1 && <br/>}
                    {data?.address2}{data?.address2 && <br/>}
                    {data?.city}{data?.city && <br/>}
                    {data?.state}{data?.state && <br/>}
                    {data?.country?.label}{data?.country && <br/>}
                    {data?.zipCode}{data?.zipCode && <br/>}
                </div>
            </div>
            }
        </>
    )
});