import React, {useEffect, useMemo} from "react";
import Card from "@material-ui/core/Card";
import Icon from "@material-ui/core/Icon";
import CardActionArea from "@material-ui/core/CardActionArea";
import Link from "@material-ui/core/Link";
import {useHistory, useParams} from "react-router-dom";
import {useStores} from "../../stores/context";
import {MatxLoading} from "../../matx";
import {observer} from "mobx-react";
import {setTitle} from "../utilities/setTitle";
import {FullWidthPageWrapper} from "../../matx/components/PageWrapper";
import {useImageUrl} from "../forms/editFields/imageHooks";

const GroupChooseFormPage = observer(() => {

    const { groupId } = useParams();
    const {groupEditStoreFactory} = useStores();
    const groupEditStore = useMemo(() => groupEditStoreFactory(groupId, true), [groupEditStoreFactory, groupId]);

    const history = useHistory();

    const formListStore = useStores().publishedFormListStore;
    formListStore.ensureInitialized();

    const handleFormClick = (formId?: string) => () => {
        history.push(`/fill/${formId}?groupId=${groupId}`);
    };

    const backgroundImageUrl = useImageUrl(groupEditStore.entity?.chooseFormImagePath);

    const renderFormCard = (formId: string, formName: string) => {
        return (<Link onClick={handleFormClick(formId)} key={formId} underline='none'>
            <Card elevation={6} className="my-6 h-full">
                <CardActionArea>
                    <div className="flex py-5 px-6 justify-between items-center">
                        <div className="text-16">{formName}</div>
                        <div><Icon>chevron_right</Icon></div>
                    </div>
                </CardActionArea>
            </Card>
        </Link>);
    };

    const chooseFormTitle = groupEditStore.entity?.chooseFormTitle;

    useEffect(() => {
        setTitle(chooseFormTitle || `Choose form`);
    }, [chooseFormTitle]);

    return (
        <FullWidthPageWrapper>
            {backgroundImageUrl && <>
            <img style={{width: "100%", minHeight: "100%", objectFit: "cover", position: "fixed", top: 0, left: 0, zIndex: -2}}
                 src={backgroundImageUrl}
                 alt="Background"/>
                 <div style={{width: "100%", minHeight: "100%", position: "fixed", top: 0, left: 0, zIndex: -1,
                     backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.43), rgba(0, 0, 0, 0) 33%)"}}/>
                 </>}


            {formListStore.isLoading || groupEditStore.isLoading || groupEditStore.formNamesByIdForView === undefined ?
                <MatxLoading /> :
                <>
                    <div style={backgroundImageUrl ? {color: "white"} : {}} className="flex flex-wrap items-center text-center justify-center">
                        <h4 className="mx-0 pb-1px 20 font-bold align-middle">{groupEditStore.entity.chooseFormTitle}</h4>
                    </div>
                    <div style={backgroundImageUrl ? {color: "white"} : {}} className="flex flex-wrap items-center justify-center">
                        <p style={{whiteSpace: "pre-wrap"}}>{groupEditStore.entity.chooseFormDescription}</p>
                    </div>
                    <div className="layout-contained">
                        {groupEditStore.formNamesByIdForView.length === 0 &&
                        <div className="text-center text-muted text-24"
                             style={{marginTop: 100, marginBottom: 100}}>No forms to fill</div>
                        }
                        {groupEditStore.formNamesByIdForView
                            .map((formNameAndId) => renderFormCard(formNameAndId.formId, formNameAndId.formName))}
                    </div>
                </>
            }
        </FullWidthPageWrapper>
    );
});

export default GroupChooseFormPage;