import {Document, getFirebase} from "firestorter";
import {observable, action, reaction, computed} from "mobx";
const FieldValue = getFirebase().firestore.FieldValue;
import PagedCollection, {FirestoreQuery} from "./pagedCollection";
import {UserStore} from "../auth/userStore";

export abstract class ListStore<A extends object> {
    entityType: string;
    collection: PagedCollection<A> = undefined as any;
    protected userStore: UserStore;

    @computed get currentPage() { return this.collection.currentPage; }
    @computed get totalCount() { return this.collection.totalCount; }
    get docs() { return this.collection.pagedDocs; }
    @computed get entities() { return this.collection.pagedDocs.map(doc => doc.data); }

    @observable pageSize: number;

    constructor(userStore: UserStore, entityType: string, pageSize: number) {
        this.pageSize = pageSize;
        this.entityType = entityType;
        this.userStore = userStore;

        reaction(() => userStore.isSignedIn, (isSignedIn) => {
            if (!isSignedIn && this.collection) {
                this.collection.disconnectQuery();
                this.collection = undefined as any;
            }
        }, {name: `${this.constructor.name}-SignedIn`});
    }

    @computed get isLoading() {
        return !this.collection || this.collection.isLoading;
    }

    @computed get isLoaded() {
        return this.collection && this.collection.isLoaded;
    }

    @computed get isLoadingMore() {
        return (this.collection && this.collection.isLoading && this.collection.hasRequestedMore);
    }

    @action
    updateQuery(newQuery: FirestoreQuery) {
        this.collection.updateQuery({query: newQuery})
    }

    @action
    delete = async (id: string) => {
        const doc = new Document(`${this.entityType}/${id}`);
        await doc.update({
            isDeleted: true,
            deletedAt: FieldValue.serverTimestamp()
        });
    };

    @action
    handleChangeRowsPerPage = (event: any) => {
        const pageSize = +(event.target.value);
        this.pageSize = pageSize;
        this.collection.updateQuery({pageSize: pageSize});
    };

    handleChangePage = (_event: any, newPage: number) => {
      if (newPage > this.collection.currentPage) {
          this.collection.nextPage();
      } else if (newPage < this.collection.currentPage) {
          this.collection.prevPage();
      }
    };

    refresh = () => {
        this.collection.updateQuery({});
    }

    isEmpty(): boolean {
        return this.docs.length === 0;
    }

    retrieveMore = (count: number) => {
        this.collection.retrieveMore(count);
    }
}