import {
    DropdownFieldConfig,
    FieldInstance,
    FieldInstanceConfig,
    FieldType,
    FormConfig,
    FormConfigDoc,
    formConfigsEntity,
    RadioFieldConfig
} from "./model";
import {EditStore} from "../common/editStore";
import {action, computed, observable, runInAction} from "mobx";
import {nanoid} from "nanoid/non-secure";
import {UserStore} from "../auth/userStore";
import {DateFormat} from "../utils/dateUtils";
import {sleep} from "../utils/sleep";
import {Context} from "../common/context";
import {notEmpty} from "../utils/tsUtils";
import {getFirebase} from "firestorter";
const Timestamp = getFirebase().firestore.Timestamp;

const defaultConfig: Record<FieldType, FieldInstanceConfig> = {
    [FieldType.Text]: {type: FieldType.Text, config: {text: ""}},
    [FieldType.Heading]: {type: FieldType.Heading, config: {text: ""}},
    [FieldType.TextInput]: {type: FieldType.TextInput, config: {isRequired: true}},
    [FieldType.Number]: {type: FieldType.Number, config: {isRequired: true}},
    [FieldType.TextBlockInput]: {type: FieldType.TextBlockInput, config: {isRequired: true, numberOfLines: 3}},
    [FieldType.Checkbox]: {type: FieldType.Checkbox, config: {defaultValue: false, isRequired: true, requiredForMailchimp: false}},
    [FieldType.Email]: {type: FieldType.Email, config: {isRequired: true}},
    [FieldType.Name]: {type: FieldType.Name, config: {isRequired: true}},
    [FieldType.Phone]: {type: FieldType.Phone, config: {isRequired: true}},
    [FieldType.Dob]: {type: FieldType.Dob, config: {isRequired: true, dateFormat: DateFormat.Mdy}},
    [FieldType.Date]: {type: FieldType.Date, config: {defaultToToday: false, disablePastDates: false, disableFutureDates: false, isRequired: true, dateFormat: DateFormat.Mdy}},
    [FieldType.Radio]: {type: FieldType.Radio, config: {isRequired: true, options: [], canSelectOther: false, canSelectMultiple: false}},
    [FieldType.Dropdown]: {type: FieldType.Dropdown, config: {isRequired: true, options: [], canSelectOther: false}},
    [FieldType.Signature]: {type: FieldType.Signature, config: {isRequired: true, allowTyping: true}},
    [FieldType.Image]: {type: FieldType.Image, config: {isRequired: true, allowFromCamera: true}},
    [FieldType.Address]: {type: FieldType.Address, config: {isRequired: true, isZipRequired: false}},
    [FieldType.RepeatedGroup]: {type: FieldType.RepeatedGroup, config: {isRequired: true, maximumInstances: null, fixedInstances: null, fieldInstances: []}}
};

const defaultLabel: {[fieldType: string]: string} = {
    [FieldType.Email]: "E-mail",
    [FieldType.Name]: "Name",
    [FieldType.Phone]: "Phone Number",
    [FieldType.Date]: "Date",
    [FieldType.Dob]: "Date of Birth",
    [FieldType.Image]: "Your photo",
    [FieldType.Address]: "Address",
    [FieldType.Signature]: "Signature",
};

export type FormConfigStoreFactory = (id: string | undefined, formId?: string) => FormConfigEditStore;

export enum SpecialFieldType {
    RepeatedGroupEnd = "endtoken"
}

export type FlatFieldInstance = {
    instance: FieldInstance | {type: SpecialFieldType.RepeatedGroupEnd;};
    id: string;
    parent?: Extract<FieldInstance, { type: FieldType.RepeatedGroup }>;
    index: number;
}

export class FormConfigEditStore extends EditStore<FormConfig, FormConfigDoc> {

    constructor(context: Context, userStore: UserStore, timeZone: string, id: string | undefined, formId?: string) {
        super(context, userStore, formConfigsEntity, timeZone, id, formId ? FormConfigEditStore.emptyEntity(formId) : undefined);
    }

    @observable deletingItems: number[] = [];
    @observable hasAttemptedSave = false;

    static emptyEntity(formId: string): FormConfig {
        return {
            fieldInstances: [],
            createdAt: undefined as any,
            formId,
            notificationEmailEnabled: false,
            notificationEmail: "",
            sentLastNotificationDate: Timestamp.fromDate(new Date(0))
        };
    }

    @action
    deleteFieldWithDelay = async (index: number) => {
        this.deletingItems.push(index);

        await sleep(250);
        runInAction(() => {
            this.deletingItems.shift();
            this.deleteField(index);
        });
    };

    @action
    deleteField(index: number): FieldInstance {
        const fieldToDelete = this.flatFieldInstances[index];
        const fieldInstances = fieldToDelete.parent ? fieldToDelete.parent.config.fieldInstances : this.entity.fieldInstances;
        return fieldInstances.splice(fieldToDelete.index, 1)[0];
    }

    @action
    moveItem = (index: number, insertBeforeIndex: number) => {
        const itemToInsertBefore = insertBeforeIndex < this.flatFieldInstances.length ? this.flatFieldInstances[insertBeforeIndex] : undefined;

        const removedField = this.deleteField(index);

        if (removedField.type === FieldType.RepeatedGroup && itemToInsertBefore?.parent) { //if we're inserting a repeated group inside a repeated group
            const destinationGroup = itemToInsertBefore.parent;
            const destinationGroupIndex = this.entity.fieldInstances.findIndex((instance) => instance.id === destinationGroup.id);
            if (destinationGroupIndex !== -1) {
                this.entity.fieldInstances.splice(destinationGroupIndex + 1, 0, removedField);
            } else { //we're trying to insert into our own group
                this.insertField(index, removedField);
            }
        } else {
            if (itemToInsertBefore) {
                const fieldInstances = itemToInsertBefore.parent ? itemToInsertBefore.parent.config.fieldInstances : this.entity.fieldInstances;
                const itemToInsertBeforeIndex = fieldInstances.findIndex((instance) => instance.id === itemToInsertBefore.id);
                if (itemToInsertBeforeIndex === -1) { //we are probably inserting before repeated group end
                    fieldInstances.push(removedField);
                } else{
                    fieldInstances.splice(itemToInsertBeforeIndex, 0, removedField);
                }
            } else {
                this.entity.fieldInstances.push(removedField);
            }
        }
    };

    moveFieldUp = (index: number) => {
        if (index > 0) {
            const itemToMove = this.flatFieldInstances[index];
            const aboveItem = this.flatFieldInstances[index - 1];
            const extraOffset = itemToMove.instance.type === FieldType.RepeatedGroup && aboveItem.parent ? itemToMove.instance.config.fieldInstances.length : 0;
            this.moveItem(index, index - 1 - extraOffset);
        }
    };

    moveFieldDown = (index: number) => {
        if (index < this.flatFieldInstances.length - 1) {
            const itemToMove = this.flatFieldInstances[index];
            const extraOffset = itemToMove.instance.type === FieldType.RepeatedGroup ? itemToMove.instance.config.fieldInstances.length + 1 : 0;
            this.moveItem(index, index + 2 + extraOffset);
        }
    };

    @action
    insertNewFieldInstance = (index: number, fieldType: FieldType): string => {
        const newItem = {
            ...defaultConfig[fieldType],
            id: nanoid(),
            label: defaultLabel[fieldType] || "",
            required: false,
            isClean: true
        };

        this.insertField(index, newItem);
        return newItem.id;
    };

    insertField(index: number, newField: FieldInstance) {
        if (index === this.flatFieldInstances.length) {
            this.entity.fieldInstances.push(newField);
        } else {
            const itemToInsertBefore = this.flatFieldInstances[index];
            const fieldInstances = itemToInsertBefore.parent ? itemToInsertBefore.parent.config.fieldInstances : this.entity.fieldInstances;

            fieldInstances.splice(itemToInsertBefore.index, 0, newField);
        }
    }

    @computed get flatFieldInstances(): FlatFieldInstance[] {
        return this.entity.fieldInstances ? this.entity.fieldInstances.flatMap((instance, i) => {
            if (instance.type === FieldType.RepeatedGroup) {
                const groupFieldInstances: FlatFieldInstance[] =  [
                    {id: instance.id, instance: instance, index: i},
                    ...(instance.config.fieldInstances.map((childInstance, j) => ({id: childInstance.id, instance: childInstance, parent: instance, index: j}))),
                    {id: instance.id + "-r", instance: { type: SpecialFieldType.RepeatedGroupEnd }, parent: instance, index: instance.config.fieldInstances.length}
                    ];
                return groupFieldInstances;
            } else {
                return [{instance, id: instance.id, index: i}];
            }
        }) : [];
    }

    @computed get emptyRepeatedGroups() {
        return this.hasAttemptedSave ? this.entity.fieldInstances.map((fieldInstance) =>
            fieldInstance.type === FieldType.RepeatedGroup && fieldInstance.config.fieldInstances.length === 0 ?
                fieldInstance.id : undefined
        ).filter(notEmpty) : [];
    }

    async saveAsNew(): Promise<string> {
        this.entity.orgId = this.userStore.userData!.orgId;
        return await super.saveAsNew();
    }

    async saveNotificationSettings() {
        await this.partialUpdate({
            notificationEmailEnabled: this.entity.notificationEmailEnabled,
            notificationEmail: this.entity.notificationEmailEnabled ? this.entity.notificationEmail : ""
        });
    }

    @action
    setNotificationEmail = async (notificationEmail: string, doSave?: boolean) => {
        this.entity.notificationEmail = notificationEmail;
        this.entity.notificationEmailEnabled = notificationEmail.trim().length > 0;
        if (doSave) await this.partialUpdate({
            notificationEmail,
            notificationEmailEnabled: this.entity.notificationEmailEnabled
        });
    };

    @action
    setNotificationEmailEnabled = async (notificationEmailEnabled: boolean, doSave?: boolean) => {
        this.entity.notificationEmailEnabled = notificationEmailEnabled;
        if (!notificationEmailEnabled) {
            this.entity.notificationEmail = "";
        }
        if (doSave) await this.partialUpdate({notificationEmailEnabled});
    };
}

export const radioDisplayOptions = (field: RadioFieldConfig | DropdownFieldConfig) => {
    const displayOptions = field.config.options.filter((option) => option.text !== "");
    if (field.config.canSelectOther) {
        displayOptions.push({id: "other", text: "Other"});
    }
    if (!field.config.isRequired && ((field.type === FieldType.Radio && !field.config.canSelectMultiple) || field.type === FieldType.Dropdown)) {
        displayOptions.unshift({id: "none", text: "None"});
    }
    return displayOptions;
}