import React from "react";
import { Redirect } from "react-router-dom";

import analyticsRoutes from "./views/analytics/AnalyticsRoutes";
import sessionRoutes from "./views/sessions/SessionRoutes";
import settingsRoutes from "./views/settings/SettingsRoutes";

import formRoutes from "./views/forms/FormRoutes";
import submissionRoutes from "./views/submissions/SubmissionRoutes";
import groupRoutes from "./views/groups/groupRoutes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/submissions" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />
  }
];

const routes = [
  ...sessionRoutes,
  ...analyticsRoutes,
  ...formRoutes,
  ...submissionRoutes,
  ...settingsRoutes,
  ...groupRoutes,
  ...redirectRoute,
  ...errorRoute
];

export default routes;
