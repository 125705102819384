import React, {useState} from "react";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Link from "@material-ui/core/Link";
import {useStores} from "../../stores/context";
import {observer} from "mobx-react";
import {Link as RouterLink, useHistory} from "react-router-dom";
import {formatTimestamp, TimestampFormat} from "shared/utils/dateUtils";
import {Document} from "firestorter";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useIsSmallScreen} from "../../hooks/screen";
import {MatxLoading} from "../../matx";
import StoreTablePagination from "../common/StoreTablePagination";
import { Group } from "shared/groups/model";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import DotsHorizontalIcon from 'mdi-material-ui/DotsHorizontal';
import {AckDialog} from "../common/AckDialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import {useIsLg} from "../../hooks/useWindowSize";

export default observer(() => {
    const {groupListStore} = useStores();
    groupListStore.ensureInitialized();

    const history = useHistory();
    const isSmallScreenBrowser = useIsSmallScreen();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<Element | null>(null);
    const [deletingId, setDeletingId] = useState<string | undefined>(undefined);
    const [deletingName, setDeletingName] = useState<string>("");
    const [activeGroup, setActiveGroup] = useState<Document<Group> | undefined>(undefined);

    const isLg = useIsLg();

    const handleEditGroup = (groupId: string) => () => {
        if (!isSmallScreenBrowser) { //small screen browsers are handled by 'onRowPress'
            history.push(`/groups/${groupId}`);
        }
    };

    const handleEditActiveGroup = () => {
        history.push(`/groups/${activeGroup!.id}`);
    };

    const handleSortChange = (event: any) => {
        groupListStore.handleSortChange(event.target.value);
    }

    const handleMenuOpen = (element: Element, group: Document<Group>) => {
        setMenuAnchorEl(element);
        setActiveGroup(group);
    }

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    }

    const handleDeleteOk = () => {
        groupListStore.delete(deletingId as string);
        setDeletingId(undefined);
    };

    const handleDeleteCancel = () => {
        setDeletingId(undefined);
    };

    const handleSubmissionListing = () => {
        history.push(`/submissions?groupId=${activeGroup!.id}&groupName=${activeGroup!.data.name}`);
    };

    const handleFillForm = () => {
        history.push(`/fillgroup/${activeGroup!.id}`);
    };


    const handleDeleteGroup = () => {
        handleMenuClose();
        setDeletingId(activeGroup!.id);
        setDeletingName(activeGroup!.data.name || "");
    };

    const dateTimeFormat = isSmallScreenBrowser ? TimestampFormat.ShortDateTimeFormat : TimestampFormat.DateTimeFormat;

    return (
        <div className="w-full overflow-auto">
            {deletingId !== undefined &&
            <AckDialog
                title="Delete group?"
                cancelText="Cancel"
                okText="Yes"
                handleClose={handleDeleteCancel}
                handleOk={handleDeleteOk}
            >
                <DialogContentText>
                    Are you sure you want to delete '{deletingName}' group?
                </DialogContentText>
            </AckDialog>
            }
            <div className="mt-2 mb-4 md:flex">
                <TextField
                    name="searchText"
                    id="searchtext"
                    label="Search name"
                    onChange={(event) => groupListStore.handleSearch(event.target.value)}
                    variant="outlined"
                    InputProps={{ startAdornment: <InputAdornment position="start"><Icon>search</Icon></InputAdornment> }}
                    style={{maxWidth: 300}}
                    className="w-full"
                />
                <div className="flex-grow"></div>
                <div className="mt-4 md:mt-0">
                    <TextField id="orderby" style={{width: 180}} onChange={handleSortChange} label="Order by" value={groupListStore.currentSortOrder.order} variant="outlined" select>
                        <MenuItem value="nameLower">Name</MenuItem>
                        <MenuItem value="eventStartAt">Start Date/Time</MenuItem>
                    </TextField>
                </div>
            </div>


            {groupListStore.isLoading ?
                <MatxLoading verticalSpace={true}/>:

                groupListStore.isEmpty() ?
                    <div className="w-full text-center pt-16 pb-16 text-16"><div>No groups/events matching your criteria.</div>

                        <IconButton onClick={groupListStore.refresh}>
                            <Icon aria-hidden={false} aria-label="Refresh" >refresh</Icon>
                        </IconButton>
                    </div>
                    :
                    <>
                        <Table className="whitespace-pre">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="px-0">Name</TableCell>
                                    {isLg && <TableCell className="px-0">Location</TableCell>}
                                    <TableCell className="px-0">Start/Time</TableCell>
                                    {isLg && <TableCell className="px-0">End</TableCell>}
                                    <TableCell className="px-0">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {groupListStore.docs
                                    .map((group, index) => (
                                        <GroupListingRow key={group.id} group={group}
                                                              onViewGroup={handleEditGroup}
                                                              dateTimeFormat={dateTimeFormat}
                                                              handleMenuOpen={handleMenuOpen}
                                                              isLg={isLg}
                                                              />
                                    ))}
                            </TableBody>
                        </Table>

                        <StoreTablePagination store={groupListStore}/>

                        <Menu
                            anchorEl={menuAnchorEl}
                            keepMounted
                            open={Boolean(menuAnchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleEditActiveGroup}>Edit/Share Group</MenuItem>
                            <MenuItem onClick={handleSubmissionListing}>Submission List</MenuItem>
                            <MenuItem onClick={handleFillForm}>Fill Form</MenuItem>
                            <MenuItem onClick={handleDeleteGroup}>Delete Group</MenuItem>
                        </Menu>
                    </>
            }
        </div>
    );
});

interface GroupListingRowProps {
    group: Document<Group>;
    onViewGroup: (groupId: string) => () => void;
    handleMenuOpen: (element: Element, group: Document<Group>) => void;
    dateTimeFormat: TimestampFormat;
    isLg: boolean;
}

const GroupListingRow = (props: GroupListingRowProps) => {
    const { group, dateTimeFormat } = props;
    const isSmallScreenBrowser = useIsSmallScreen();
    const history = useHistory();
    const groupLink = `/groups/${group.id}`;

    const onRowPress = () => {
        if (isSmallScreenBrowser) {
            history.push(groupLink);
        }
    }

    return (<TableRow
        onClick={onRowPress}
        className="select-none"
    >
        <TableCell className="px-0" align="left">
            <Link component={RouterLink} color="inherit" to={groupLink}>{group.data.name}</Link>
        </TableCell>
        {props.isLg && <TableCell className="px-0">
            {group.data.location}
        </TableCell>}
        <TableCell className="px-0">
            {formatTimestamp(group.data.eventStartAt, dateTimeFormat)}
        </TableCell>
        {props.isLg && <TableCell className="px-0">
            {formatTimestamp(group.data.eventEndAt, dateTimeFormat)}
        </TableCell>}
        <TableCell className="hidden md:block px-0">
            <IconButton onClick={(event) => {
                props.handleMenuOpen(event.currentTarget, group);
                event.stopPropagation();
            }}>
                <DotsHorizontalIcon aria-hidden={false} aria-label="Actions"/>
            </IconButton>
        </TableCell>
    </TableRow>);
};
