import {Logger} from "../common/context";
import {sleep} from "./sleep";

export async function withTimeoutUsingCancelToken(log: Logger, promise: (token: CancelToken) => Promise<void>, timeout: number, timeoutText: string, onTimeout: () => void | Promise<void>) {
    let completedPromise = false;
    const cancelToken = {cancelled: false};
    try {
        return await Promise.race([
            promise(cancelToken).finally(() => { completedPromise = true; }),
            (async () => {
                await sleep(timeout);
                if (!completedPromise) {
                    log.warn(`Timeout after ${timeout} ${timeoutText}`);
                    cancelToken.cancelled = true;
                    return onTimeout();
                }
            })()
        ]);
    } finally {
        completedPromise = true;
    }
}


export interface CancelToken {
    cancelled: boolean;
}