import React, {useEffect} from "react";
import Card from "@material-ui/core/Card";
import Icon from "@material-ui/core/Icon";
import CardActionArea from "@material-ui/core/CardActionArea";
import Link from "@material-ui/core/Link";
import {useHistory} from "react-router-dom";
import {useStores} from "../../stores/context";
import {MatxLoading} from "../../matx";
import {Form} from "shared/forms/model";
import {observer} from "mobx-react";
import {Document} from "firestorter";
import {setTitle} from "../utilities/setTitle";
import {FullWidthPageWrapper} from "../../matx/components/PageWrapper";

const NewSubmissionPage = observer(() => {

    const history = useHistory();
    const formListStore = useStores().publishedFormListStore;
    formListStore.ensureInitialized();

    const handleFormClick = (formId?: string) => () => {
        history.push(`/submissions/form/${formId}`);
    };

    const renderFormCard = (form: Document<Form>) => {
        return (<Link onClick={handleFormClick(form.id)} key={form.id} underline='none'>
            <Card elevation={6} className="my-6 h-full">
                <CardActionArea>
                    <div className="flex py-5 px-6 justify-between items-center">
                        <div className="text-16">{form.data.name}</div>
                        <div><Icon>chevron_right</Icon></div>
                    </div>
                </CardActionArea>
            </Card>
        </Link>);
    };

    useEffect(() => {
        setTitle(`Choose form`);
    }, []);

    return (
        <FullWidthPageWrapper>
            <div className="flex flex-wrap items-center justify-center">
                <h4 className="mx-0 pb-1px 20 align-middle">Choose a form to fill</h4>
            </div>

            {formListStore.isLoading ?
                <MatxLoading /> :
                <div className="layout-contained">
                    {formListStore.docs.length === 0 &&
                        <div className="text-center text-muted text-24"
                             style={{marginTop: 100, marginBottom: 100}}>No forms published</div>
                    }
                    {formListStore.docs
                        .map((form, index) => renderFormCard(form))}
                </div>
            }
        </FullWidthPageWrapper>
    );
});

export default NewSubmissionPage;