import {functionsRoot} from "../utils/firebaseFunctions";
import { endOfDay, subDays, startOfDay, startOfToday } from "date-fns";
import { SubmissionListStore } from "./submissionListStore";
import {reaction, observable, action, computed} from "mobx";
import {allFormsId} from "../forms/model";

export class SubmissionExportStore {

    @observable fromDate: Date = startOfToday();
    @observable toDate: Date = startOfToday();
    @observable formId: string | undefined = allFormsId;

    constructor(private submissionListStore: SubmissionListStore) {
        reaction(
            () => ({currentFilterDays: this.submissionListStore.currentFilterDays, currentFormId: this.submissionListStore.currentFilterFormId}),
            ({currentFilterDays, currentFormId}) => {
                this.fromDate = subDays(startOfToday(), currentFilterDays > 200 ? 14 : currentFilterDays);
                this.formId = currentFormId
            }
            , {fireImmediately: true});
    }

    @computed get errorText(): string | undefined {
        return this.toDate < this.fromDate ? "'To Date' must be after 'From Date'" : undefined;
    }

    async export(): Promise<string> {
        const getExportUrl = functionsRoot().httpsCallable('exportCsv');
        try {
            const formId = this.formId === allFormsId ? undefined : this.formId;
            const response = await getExportUrl({formId: formId, fromDate: startOfDay(this.fromDate).getTime(), toDate: endOfDay(this.toDate).getTime()});
            if (response.data.url.length) {
                return response.data.url[0]; //react-native is returning url as an array for some reason
            } else {
                return response.data.url;
            }
        } catch(e) {
            throw new Error(`Error with export: ${e}`);
        }
    }

    @action
    updateFromDate = (newDate: Date | undefined) => {
        if (newDate) {
            this.fromDate = newDate;
        }
    }

    @action
    updateToDate = (newDate: Date | undefined) => {
        if (newDate) {
            this.toDate = newDate;
        }
    }

    @action
    updateFormId = (formId: string | undefined) => {
        if (formId) {
            this.formId = formId;
        }
    }
}