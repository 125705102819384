import { themeColors } from "./MatxTheme/themeColors";
import { createMuiTheme } from "@material-ui/core/styles";
import themeOptions from "./MatxTheme/themeOptions";

function createMatxThemes() {
  let themes: any = {};

  for (const color of Object.keys(themeColors)) {
    const theme = JSON.parse(JSON.stringify(themeOptions));
    theme.palette = (themeColors as any)[color].palette;
    themes[color] = createMuiTheme(theme);
  }

  return themes;
}

export const themes = createMatxThemes();