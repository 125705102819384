export const handleCopyUrlToClipboard = (urlRef: HTMLAnchorElement, setHasCopied: (hasCopied: boolean) => void) => {
    const range = document.createRange();
    range.selectNode(urlRef);
    window.getSelection()?.removeAllRanges();
    window.getSelection()?.addRange(range);
    document.execCommand("copy");
    setHasCopied(true);
    window.setTimeout(() => {
        setHasCopied(false)
    }, 2000)
};

