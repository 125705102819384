import * as React from "react";
import {SimpleCard, MatxLoading} from "matx";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

import {observer} from "mobx-react";
import {useForm} from "react-hook-form";
import {useHistory, useParams} from "react-router-dom";
import {formatTimestamp, TimestampFormat} from "shared/utils/dateUtils";
import {groupLink} from "shared/forms/webLink";
import {QRCode} from "react-qr-svg";
import {
    EditCard,
    EditCardFieldData,
    EditCardFieldTitle,
} from "../editcard/EditCard";
import {useEffect, useRef, useState} from "react";
import {useMemo} from "use-memo-one";
import {useStores} from "../../stores/context";
import {setTitle} from "../utilities/setTitle";
import { FullWidthPageWrapper } from "matx/components/PageWrapper";
import { action } from "mobx";
import {handleCopyUrlToClipboard} from "../utilities/copyUrl";
import {getFirebase} from "firestorter";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ImageUpload from "../forms/editFields/ImageUpload";
import { nanoid } from 'nanoid'
import {useImageUrl} from "../forms/editFields/imageHooks";
import { DatePicker } from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {emailListValidator} from "shared/forms/validationOpts";
import {downloadImage} from "../utilities/download";
const Timestamp = getFirebase().firestore.Timestamp;

type FormData = {
    name: string;
    location: string;
};

type CustomizationData = {
    chooseFormTitle: string;
    chooseFormDescription: string;
}

export default observer((props: {isNew: boolean}) => {
    const isNew = props.isNew;
    const {id} = useParams();
    const {groupEditStoreFactory, publishedFormListStore, settingsStore, userStore} = useStores();
    const groupEditStore = useMemo(() => groupEditStoreFactory(id, false), [groupEditStoreFactory, id]);
    settingsStore.ensureInitialized();
    const [hasUploaded, setHasUploaded] = useState(false);

    const history = useHistory();
    const hookContext = useForm<FormData>();
    const customizationHookContext = useForm<CustomizationData>();
    const inviteHookContext = useForm<{ companyName: string, inviteAddresses: string }>();

    const urlRef = useRef<HTMLAnchorElement>(null);

    const [hasCopied, setHasCopied] = useState(false);

    const handleSaveGroupSummary = async () => {
        await groupEditStore.save();
        return true;
    };

    const handleSaveFormIds = async () => {
        await groupEditStore.saveFormIds();
        return true;
    }

    const handleSaveCustomization = async () => {
        await groupEditStore.saveCustomization();
        return true;
    };

    useEffect(() => {
        setTitle(`Groups/Events`);
    }, []);

    const handleBack = () => {
        history.push("/groups");
    };

    const sendInvites = async (formValues: any) => {
        await groupEditStore.sendInvites(formValues.companyName, formValues.inviteAddresses);
        setTimeout(() => {
            inviteHookContext.reset({companyName: formValues.companyName});
        });
    }

    const backgroundImageUrl = useImageUrl(groupEditStore.entity?.chooseFormImagePath);

    return (
        groupEditStore.isLoading ? <MatxLoading/> :
            <FullWidthPageWrapper>
                <div className="max-w-6xl mx-auto">
                <div className="flex justify-between h-full items-center mb-3">
                    <IconButton onClick={handleBack}><Icon aria-hidden={false} aria-label="Back">arrow_back</Icon></IconButton>
                    <div>
                        {isNew &&
                        <span className="ml-2 mt-1 mb-1">
                            <Button onClick={handleBack} color="primary" variant="contained">
                                <Icon>save</Icon>
                                <span
                                    className="pl-2 capitalize">Save</span>
                            </Button>
                        </span>
                        }
                    </div>
                </div>
                <SimpleCard style={{overflow: "visible"}} title="Group/Event Details">
                    <div>
                        <EditCard
                            title="Group/Event Summary"
                            view={
                                <>
                                    <EditCardFieldTitle>Name</EditCardFieldTitle>
                                    <EditCardFieldData>{groupEditStore.entity.name}</EditCardFieldData>
                                    {groupEditStore.entity.location !== "" && <><EditCardFieldTitle>Location</EditCardFieldTitle>
                                    <EditCardFieldData>{groupEditStore.entity.location}</EditCardFieldData></>}
                                    {groupEditStore.entity.eventStartAt && <>
                                        <EditCardFieldTitle>Starts at</EditCardFieldTitle>
                                        <EditCardFieldData>{formatTimestamp(groupEditStore.entity.eventStartAt, TimestampFormat.DateTimeFormat)}</EditCardFieldData>
                                        </>
                                    }
                                    {groupEditStore.entity.eventEndAt && <>
                                    <EditCardFieldTitle>Ends at</EditCardFieldTitle>
                                    <EditCardFieldData>{formatTimestamp(groupEditStore.entity.eventEndAt, TimestampFormat.DateTimeFormat)}</EditCardFieldData>
                                    </>
                                    }
                                </>
                            }
                            onSave={handleSaveGroupSummary}
                            openByDefault={isNew || groupEditStore.entity.name === "" || !groupEditStore.entity.name}
                            hookContext={hookContext}
                            editForm={
                                <>
                                <div className="mb-4">
                                    <TextField className="w-full"
                                               id="name"
                                               name="name"
                                               autoFocus
                                               defaultValue={groupEditStore.entity.name}
                                               label="Group/Event Name"
                                               inputRef={hookContext.register({required: true})}
                                               error={hookContext.errors.name?.message !== undefined}
                                               helperText={hookContext.errors.name?.message}
                                               onChange={event => groupEditStore.setGroupName(event.target.value)}
                                               variant="outlined"
                                    />
                                </div>
                                <div className="mb-4">
                                    <TextField className="w-full"
                                               id="location"
                                               name="name"
                                               defaultValue={groupEditStore.entity.location}
                                               label="Location"
                                               onChange={action(event => groupEditStore.entity.location = event.target.value)}
                                               variant="outlined"
                                    />
                                </div>
                                    <div className="my-4">
                                        <span className="mr-4">
                                            <DatePicker
                                                label={"Starts at"}
                                                renderInput={props => <TextField
                                                    {...props}
                                                    style={{width: 200}}
                                                />}

                                                clearable={true}
                                                value={groupEditStore.entity.eventStartAt?.toDate() || null}
                                                onChange={action((date: any) => groupEditStore.entity.eventStartAt = date ? Timestamp.fromDate(date) : null)}
                                            />
                                        </span>
                                        <DatePicker
                                            label={"Ends at"}
                                            renderInput={props => <TextField
                                                {...props}
                                                style={{width: 200}}
                                            />}
                                            clearable={true}
                                            value={groupEditStore.entity.eventEndAt?.toDate() || null}
                                            onChange={action((date: any) => groupEditStore.entity.eventEndAt = date ? Timestamp.fromDate(date) : null)}
                                        />
                                    </div>
                                </>
                            }/>
                        <EditCard
                            title="Forms"
                            view={<>
                                <EditCardFieldData>
                                    {groupEditStore.formNames.length > 0 ? groupEditStore.formNames : "(none)"}
                                </EditCardFieldData>
                            </>}
                            onSave={handleSaveFormIds}
                            hookContext={hookContext}
                            editForm={
                                <div className="mb-4">
                                    {publishedFormListStore.isLoaded && publishedFormListStore.docs.map(form =>
                                        <FormControlLabel
                                            key={form.id}
                                            className="w-full"
                                            control={
                                                <Checkbox
                                                    onChange={action((event) => {
                                                        if (event.target.checked) {
                                                            groupEditStore.entity.formIds.add(form.id!);
                                                        } else {
                                                            groupEditStore.entity.formIds.delete(form.id!);
                                                        }
                                                    })}
                                                    checked={groupEditStore.entity.formIds.has(form.id!)}
                                                    color="primary"/>
                                            }
                                            label={form.data.name}
                                        />)}
                                </div>
                            }
                        />
                        <EditCard
                            title="Send Invitation"
                            view={<>
                            <EditCardFieldTitle>Invitations Sent</EditCardFieldTitle>
                            <EditCardFieldData>{
                                groupEditStore.entity.sentInvitations && groupEditStore.entity.sentInvitations.length > 0 ?
                                groupEditStore.entity.sentInvitations.join(", ") : "None" }</EditCardFieldData>
                            </>
                            }
                            editForm={
                                <>
                                    <div className="mb-4">
                                        <TextField className="w-full"
                                                   name="companyName"
                                                   id="companyName"
                                                   autoFocus
                                                   defaultValue={settingsStore.companyName}
                                                   label="Company Name (sender)"
                                                   inputRef={inviteHookContext.register({required: true})}
                                                   error={inviteHookContext.errors.companyName?.message !== undefined}
                                                   helperText={inviteHookContext.errors.companyName?.message}
                                                   variant="outlined"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <TextField className="w-full"
                                                   name="inviteAddresses"
                                                   id="inviteAddresses"
                                                   autoFocus
                                                   multiline
                                                   rows={3}
                                                   defaultValue=""
                                                   label="E-mails (separate with comma)"
                                                   inputRef={inviteHookContext.register({required: true, validate: emailListValidator})}
                                                   error={inviteHookContext.errors.inviteAddresses?.message !== undefined}
                                                   helperText={inviteHookContext.errors.inviteAddresses?.message}
                                                   variant="outlined"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <Button
                                            className="mt-4"
                                            onClick={inviteHookContext.handleSubmit(sendInvites)}
                                            variant="contained"
                                            disabled={groupEditStore.isSendingInvites}
                                        >Send invitation to fill forms
                                            {groupEditStore.isSendingInvites &&
                                            <CircularProgress className="ml-2"
                                                              size={20}
                                            />
                                            }
                                        </Button> {groupEditStore.hasSentInvites && "Sent"}
                                    </div>
                                    </>
                            }
                            />
                        <EditCard
                            title="Branding/Customization"
                            view={<>
                                <EditCardFieldTitle>Title</EditCardFieldTitle>
                                <EditCardFieldData>{groupEditStore.entity.chooseFormTitle}</EditCardFieldData>
                                {groupEditStore.entity.chooseFormDescription !== "" && <><EditCardFieldTitle>Description</EditCardFieldTitle>
                                    <EditCardFieldData>{groupEditStore.entity.chooseFormDescription}</EditCardFieldData></>}
                                {backgroundImageUrl && <><EditCardFieldTitle>Background Image</EditCardFieldTitle>
                                    <EditCardFieldData><img className="mb-2" style={{maxWidth: 500, maxHeight: 300}} src={backgroundImageUrl} alt="Background"/></EditCardFieldData></>}
                            </>}
                            onSave={handleSaveCustomization}
                            hookContext={customizationHookContext}
                            onEdit={() => { setHasUploaded(false); }}
                            editForm={
                                <>
                                    <div className="mb-4">
                                        <TextField className="w-full"
                                                   name="chooseFormTitle"
                                                   id="chooseFormTitle"
                                                   autoFocus
                                                   defaultValue={groupEditStore.entity.chooseFormTitle}
                                                   label="Group/Event Page Title"
                                                   inputRef={customizationHookContext.register({required: true})}
                                                   error={customizationHookContext.errors.chooseFormTitle?.message !== undefined}
                                                   helperText={customizationHookContext.errors.chooseFormTitle?.message}
                                                   onChange={action(event => groupEditStore.entity.chooseFormTitle = event.target.value)}
                                                   variant="outlined"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <TextField className="w-full"
                                                   name="chooseFormDescription"
                                                   id="chooseFormDescription"
                                                   defaultValue={groupEditStore.entity.chooseFormDescription}
                                                   label="Group/Event Page Description"
                                                   multiline={true}
                                                   rows={3}
                                                   inputRef={customizationHookContext.register()}
                                                   onChange={action(event => groupEditStore.entity.chooseFormDescription = event.target.value)}
                                                   variant="outlined"
                                        />
                                    </div>
                                    <EditCardFieldTitle>Background Image</EditCardFieldTitle>
                                    <div className="mb-4">
                                        {backgroundImageUrl && !hasUploaded ?
                                            <div className="relative"><img style={{maxWidth: 500, maxHeight: 300}} src={backgroundImageUrl} alt="Background"/>
                                                <IconButton
                                                    size="small"
                                                    style={{
                                                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                                                        position: 'absolute',
                                                        left: 4,
                                                        top: 4
                                                    }}
                                                    onClick={action(() => {
                                                        groupEditStore.entity.chooseFormImagePath = null;
                                                    })}
                                                ><Icon aria-hidden={false} aria-label="Close" fontSize="small" style={{color: "white"}}>close</Icon></IconButton>
                                            </div>
                                            :
                                            <ImageUpload
                                                path={`/groups/bgimages/${userStore.user!.uid}/${nanoid()}`}
                                                handleIsUploading={action(() => {
                                                    groupEditStore.entity.chooseFormImagePath = null;
                                                })}
                                                handleFileRemoved={action((file: string, reason: string) => {
                                                    if (reason === 'removed-by-user') {
                                                        groupEditStore.entity.chooseFormImagePath = null;
                                                    }
                                                })}
                                                handleUploaded={action((result) => {
                                                    if (result.successful.length > 0) {
                                                        setHasUploaded(true);
                                                        groupEditStore.entity.chooseFormImagePath = result.successful[0].path;
                                                    }
                                                })}
                                                allowFromCamera={false}
                                            />
                                        }
                                    </div>
                                </>
                            }
                        />
                        <EditCard
                            title="Share Group/Event"
                            view={groupEditStore.isNew ? <>Save group/event in order to share it</> :
                                <>
                                <EditCardFieldTitle>Web URL</EditCardFieldTitle>
                                <EditCardFieldData><a className="underline break-all" target="_blank" rel="noopener noreferrer"
                                                      href={groupLink(groupEditStore.id!)} ref={urlRef}>{groupLink(groupEditStore.id!)}</a>
                                    <Tooltip open={hasCopied} title="Copied">
                                        <Icon className="cursor-pointer" style={{marginLeft: 5, marginTop: -2, position: "absolute"}} fontSize="small"
                                              onClick={() => handleCopyUrlToClipboard(urlRef.current!, setHasCopied)}>content_copy</Icon>
                                    </Tooltip>
                                </EditCardFieldData>
                                <EditCardFieldTitle>QR Code</EditCardFieldTitle>
                                <EditCardFieldData>
                                    <div id="qrcode">
                                        <QRCode
                                            className="mt-1 inline-block"
                                            bgColor="#FFFFFF"
                                            fgColor="#000000"
                                            level="Q"
                                            style={{width: 128, verticalAlign: "text-bottom"}}
                                            value={groupLink(groupEditStore.id!)}
                                        />
                                        <button className="cursor-pointer ml-2" onClick={() => {
                                            const svg = 'data:image/svg+xml;base64,' + btoa(new XMLSerializer().serializeToString(document.querySelector("#qrcode svg") as Node));
                                            downloadImage(512, 512, svg);
                                        }}>(<span className="underline">download</span>)</button>
                                    </div>
                                </EditCardFieldData>
                            </>}
                        />


                    </div>
                </SimpleCard>
                <div className="py-3"/>
                </div>
            </FullWidthPageWrapper>
    );
});