import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from '@material-ui/core/CircularProgress'
import {useStores} from "../../stores/context";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {observer} from "mobx-react";
import {DatePicker} from '@material-ui/pickers';
import { useState } from "react";
import DialogContentText from "@material-ui/core/DialogContentText";
import { downloadUrl } from "../utilities/download";
import FormHelperText from "@material-ui/core/FormHelperText";
import {allFormsId} from "shared/forms/model";

interface Props {
    handleClose: () => void;
}

export const ExportDialog = observer((props: Props) => {
    const {handleClose} = props;

    const {formListStore, submissionExportStore} = useStores();
    formListStore.ensureInitialized();

    const [isExporting, setIsExporting] = useState(false);

    const handleExport = async () => {
        setIsExporting(true);
        try {
            const url = await submissionExportStore.export();
            await downloadUrl(url, "export.csv");
        } catch (e) {
            window.alert(`Error in exporting CSV: ${e}`);
        }
        setIsExporting(false);
        handleClose();
    }

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Export Data</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Export submissions CSV file matching:

                </DialogContentText>
                <div className="mt-4">
                    <TextField id="formselect" label="Form" select onChange={(event) => submissionExportStore.updateFormId(event.target.value)}
                               value={submissionExportStore.formId}>
                        <MenuItem value={allFormsId}>All Forms</MenuItem>
                        {formListStore.isLoaded && formListStore.docs.map(form =>
                            <MenuItem key={form.id} value={form.id}>{form.data.name}</MenuItem>
                        )}
                    </TextField>
                </div>
                <div className="mt-4">
                    <span className="mr-4">
                        <DatePicker
                            label={"From Date"}
                            renderInput={props => <TextField
                                {...props}
                                id="fromdate"
                                style={{width: 140}}
                            />}

                            disableFuture={true}
                            clearable={true}
                            value={submissionExportStore.fromDate}
                            onChange={(fromDate: any) => submissionExportStore.updateFromDate(fromDate)}
                        />
                    </span>

                    <DatePicker
                        label={"To Date"}
                        renderInput={props => <TextField
                            {...props}
                            id="todate"
                            style={{width: 140}}
                        />}
                        disableFuture={true}
                        clearable={true}
                        value={submissionExportStore.toDate}
                        onChange={(toDate: any) => submissionExportStore.updateToDate(toDate)}
                    />
                </div>
                <div>
                    {submissionExportStore.errorText && <FormHelperText error={true}>{submissionExportStore.errorText}</FormHelperText>}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
                <Button onClick={handleExport} disabled={isExporting} color="primary" autoFocus>
                    Export
                    {isExporting &&
                    <CircularProgress className="ml-2"
                                      size={20}
                    />
                    }
                </Button>
            </DialogActions>
        </Dialog>);
});
