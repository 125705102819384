import {functionsRoot} from "../utils/firebaseFunctions";
import {QueryType} from "./querySubmissions";
import {observable, computed, runInAction} from "mobx";
import { isToday, subDays, startOfDay, endOfDay, startOfToday, parse } from "date-fns";
import {groupBy} from "../utils/tsUtils";
import {allFormsId} from "../forms/model";
import * as d3Scale from "d3-scale";

interface ChartDataPoint<T> {
    x: T,
    y: number,
    index: number
}


export class AnalyticsStore {

    private queryFn = functionsRoot().httpsCallable('querySubmissions');

    @observable submissionsByDay: ChartDataPoint<Date>[] = [];
    @observable submissionsByHour: ChartDataPoint<number>[] = [];
    @observable formSelections: {label: string, data: ChartDataPoint<string>[]}[] = [];
    @observable isLoading = true;

    constructor(formId: string, fromDate: Date, toDate: Date, timeZone: string) {
        this.initialize(formId, fromDate, toDate, timeZone).then();
    }

    async initialize(formId: string, fromDate: Date, toDate: Date, timeZone: string) {
        const fromTime = isNaN(fromDate.getTime()) ? subDays(startOfDay(new Date()), 14).getTime() : startOfDay(fromDate).getTime();
        const toTime = isNaN(toDate.getTime()) || isToday(toDate) || toDate.getTime() < fromTime ?
            undefined : endOfDay(toDate).getTime();

        const params = {
            queryType: QueryType.Dashboard,
            fromDateTime: fromTime,
            ...(toTime ? {toDateTime: toTime} : {}),
            timeZone,
            ...(formId === allFormsId ? {} : {formId})
        }

        const queryResults = await this.queryFn(params);

        const submissionsPerDay = queryResults.data[QueryType.SubmissionsPerDay] || [];
        const submissionsByHour = queryResults.data[QueryType.SubmissionTimeOfDay] || [];
        const formSelections = queryResults.data[QueryType.FormSelections] || [];

        runInAction(() => {
            this.submissionsByDay = this.fillData(
                submissionsPerDay.map((point: {date: {value: string}, count: number}) => (
                    [parse(point.date.value, "yyyy-MM-dd", startOfToday()).getTime(), point.count]
                )),
                fromTime,
                toTime ? startOfDay(new Date(toTime)).getTime() : startOfToday().getTime(),
                60 * 60 * 24 * 1000
            ).map(({x, y, index}) => ({x: new Date(x), y, index}));

            this.submissionsByHour = this.fillData(
                submissionsByHour.map((point: {hour: number, count: number}) => (
                    [point.hour, point.count]
                )),
                0,
                23,
                1
            );

            const formSelectionsByLabel = groupBy(formSelections as {label: string, value: string, count: number}[], (item) => item.label);
            this.formSelections = Object.keys(formSelectionsByLabel).map(label =>
                ({label, data: formSelectionsByLabel[label].map((point, i) => ({x: point.value, y: point.count, index: i}))})
            );
            this.isLoading = false;
        });
    }

    private fillData(
        array: [[number, number]],
        start: number,
        end: number,
        interval: number
    ): {x: number, y: number, index: number}[] {
        const map = new Map<number, number>(array);
        for (let i = start; i <= end; i = i + interval) {
            if (!map.get(i)) {
                map.set(i, 0);
            }
        }
        return Array.from(map.entries())
            .sort((a, b) => a[0] - b[0])
            .map(([x, y], i) =>
            ({x, y, index: i}));
    }

    private calculateTicks(data: {y: number}[], tickCount: number, includeTopPadding: boolean) {
        const min = 0;
        const max = Math.max(...data.map((point) => point.y));
        const maxWithPadding = includeTopPadding ? max * 1.1 + 1 : max;
        return d3Scale.scaleLinear()
            .domain([min, maxWithPadding])
            .ticks(tickCount).filter(n => Number.isInteger(n));
    }

    formatHour(hour: number, includeAmPm: boolean) {
        return hour < 12 ? `${hour}${includeAmPm ? "am" : ""}` : hour == 12 ? `12${includeAmPm ? "pm" : ""}` : `${hour - 12}${includeAmPm ? "pm" : ""}`;
    }

    barWidths = (N: number, space: number) => {
        const unit = 1/(N+1);
        const barFraction = 1/(space+1);
        return {
            barWidth: unit*barFraction,
            barSpacing: unit*(1 - barFraction),
            domainPadding: unit*(1 - barFraction/2),
        };
    }

    @computed get submissionsByDayTicks() {
        if (this.submissionsByDay) {
            return this.calculateTicks(this.submissionsByDay, 5, true)
        } else {
            return [];
        }
    }

    @computed get submissionsByHourTicks() {
        if (this.submissionsByHour) {
            return this.calculateTicks(this.submissionsByHour, 5, true)
        } else {
            return [];
        }
    }

    @computed get submissionsByHourBarWidths() {
        return this.barWidths(24, 1/4);
    }

    @computed get formSelectionTicks() {
        if (this.formSelections) {
            return this.formSelections.map(field =>
                this.calculateTicks(field.data, 5, false)
            )
        } else {
            return [];
        }
    }

    @computed get formSelectionBarWidths() {
        return this.formSelections.map(field =>
            this.barWidths(field.data.length, 1/4)
        )
    }


}