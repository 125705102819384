import React, {Fragment, useCallback, useLayoutEffect, useState} from "react";
import {useStores} from "../stores/context";
import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import routes from "../RootRoutes";
import { MatxLoading } from "matx";
import {UserData, UserStore} from "shared/auth/userStore";
import { Location} from "history";
import {authRoles} from "shared/auth/authRoles";

const isAuthenticatedForRoute = (userData: UserData | undefined, pathname: string, routes: any[]) => {
  const matched = routes.find(r => r.path === pathname);
  //default to user if not matched
  const matchedAuth = matched ? (matched.auth && matched.auth.length ? matched.auth : undefined) : authRoles.user;
  const authenticated = matchedAuth
      ? userData && userData.role && matchedAuth.includes(userData.role)
      : true;

  return authenticated;
};

const doRedirectToSignin = (userStore: UserStore, pathname: string) => {
  return userStore.hasAuthState && !isAuthenticatedForRoute(userStore.userData, pathname, routes);
};

const AuthGuard = observer(({ children, ...props }: any) => {
  const { userStore } = useStores();
  const history = useHistory();
  const [routeAllowed, setRouteAllowed] = useState(false);

  const redirectToSignInIfRequired = useCallback((pathname: string) => {
    if (doRedirectToSignin(userStore, pathname)) {
      setRouteAllowed(false);
      history.push({
        pathname: "/session/signin",
        state: { redirectUrl: pathname }
      })
    } else if (userStore.hasAuthState) {
      setRouteAllowed(true);
    }
  }, [userStore, history]);

  useLayoutEffect(() => {
    redirectToSignInIfRequired(history.location.pathname);
    history.listen((location: Location<any>) => {
      redirectToSignInIfRequired(location.pathname);
    });
  }, [history, redirectToSignInIfRequired, userStore.hasAuthState]);

  if (userStore.hasAuthError) {
    throw new Error("Auth error");
  }

  return routeAllowed ? <Fragment>{children}</Fragment> : <MatxLoading/>;
});

export default AuthGuard;
