import * as React from "react";
import Card from "@material-ui/core/Card";
import cx from "classnames";
import {ReactNode} from "react";
import {useIsSmallScreen} from "../../../hooks/screen";

interface Props {
    children: ReactNode;
    title: string;
    subtitle?: string;
    topRightContent?: ReactNode;
    style?: React.CSSProperties;
    className?: string;
}

const SimpleCard = ({children, title, subtitle, topRightContent, style, className}: Props) => {
    const isSmallScreenBrowser = useIsSmallScreen();

    const cardClasses = isSmallScreenBrowser ? "px-3 py-3" : "px-6 py-5";

    return (
        <Card elevation={6} className={`${cardClasses} h-full ${className}`} style={style}>
            <div
                className={cx({
                    "card-title": true,
                    "mb-4": !subtitle,
                    "flex": true,
                    "justify-between": true
                })}
            >
                <div>{title}</div>
                <div>
                    {topRightContent}
                </div>
            </div>
            {subtitle && <div className="card-subtitle mb-4">{subtitle}</div>}
            {children}
        </Card>
    );
};

export default SimpleCard;
