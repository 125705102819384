import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {FieldRenderMode, FieldType} from "shared/forms/model";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import {action} from "mobx";
import {observer} from "mobx-react";
import {FieldProps} from "./field";
import {useFormContext, useWatch} from "react-hook-form";
import {textInputProps} from "./defaultProps";
import FormLabel from "@material-ui/core/FormLabel";
import {useCallback} from "use-memo-one";
import { AppTextInput } from "views/common/AppTextInput";

export default observer((props: FieldProps<FieldType.Email | FieldType.TextInput | FieldType.TextBlockInput | FieldType.Number | FieldType.Phone>, ref: React.Ref<HTMLInputElement>) => {

    const {field, fieldId, data} = props;
    const { errors, register } = useFormContext();
    const inputLabelRef = useRef<HTMLLabelElement>(null);
    const [isLongLabel, setIsLongLabel] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const watchText = useWatch({name: fieldId, defaultValue: ""});

    const editFieldId = "e-" + field.id;

    const updateMinimum = action((event: any) => {
        if (field.type === FieldType.Number) {
            field.config.minimum = parseInt(event.target.value, 10);
        }
    });

    const updateMaximum = action((event: any) => {
        if (field.type === FieldType.Number) {
            field.config.maximum = parseInt(event.target.value, 10);
        }
    });

    const updateNumberOfLines = action((event: any) => {
        if (field.type === FieldType.TextBlockInput) {
            field.config.numberOfLines = parseInt(event.target.value, 10);
        }
    });

    const onFocus = useCallback(() => {
        setIsFocused(true);
    }, [setIsFocused]);

    const onBlur = useCallback(() => {
        setIsFocused(false);
    }, [setIsFocused]);

    useEffect(() => {
       const placeholder = inputLabelRef.current;
       if (placeholder && (placeholder.offsetWidth < placeholder.scrollWidth || placeholder.offsetHeight > 20)) {
           setIsLongLabel(true);
       }
    }, [watchText]);

    const showPlaceholderLabel = isLongLabel &&
        (field.type !== FieldType.TextBlockInput ||
            watchText.length > 0 ||
            isFocused);

    return (
        <>
            {props.fieldRenderMode === FieldRenderMode.Edit &&
            <div key="edit" className="w-full">
                <AppTextInput autoFocus
                           name={editFieldId}
                           label="Field title"
                           defaultValue={props.field.label}
                           onChange={action((event) => { field.isClean = false; field.label = event.target.value; })}
                           inputRef={register({required: true})}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={action((event, checked) => { field.config.isRequired = checked; })}
                            checked={field.config.isRequired}
                            color="primary"
                        />
                    }
                    label="Is required?"
                />
                {field.type === FieldType.Number && <>
                    <div className="w-full flex mb-4">
                        <span className="flex-1 mr-1 w-full">
                            <TextField {...textInputProps(`${editFieldId}-minimum`, errors)}
                               label="Minimum"
                               id="minimum"
                               defaultValue={field.config.minimum}
                               onChange={updateMinimum}
                               type="number"
                               inputRef={register({pattern: /^[0-9]+$/})}
                            />
                        </span>
                        <span className="flex-1 ml-1 w-full">
                            <TextField {...textInputProps(`${editFieldId}-maximum`, errors)}
                               label="Maximum"
                               id="maximum"
                               defaultValue={field.config.maximum}
                               onChange={updateMaximum}
                               type="number"
                               inputRef={register({pattern: /^[0-9]+$/})}
                            />
                        </span>
                    </div>
                </>
                }
                {field.type === FieldType.TextBlockInput && <>
                    <TextField {...textInputProps(`${editFieldId}-lines`, errors)}
                               label="Number of Lines"
                               id="numberlines"
                               defaultValue={field.config.numberOfLines}
                               onChange={updateNumberOfLines}
                               type="number"
                               inputRef={register({required: "Required", pattern: /^[0-9]+$/})}
                    />
                </>
                }
                {field.type === FieldType.Email &&
                <div className="text-xs mt-2">
                    {props.isInsideGroup ? "NOTE: This field will not be used for the submission listing/Mailchimp export as it's inside a repeated group." :
                        "NOTE: If the form contains multiple 'E-mail' fields, the first one is used for the submission listing/Mailchimp export."
                    }
                </div>
                }
            </div>
            }
            {props.fieldRenderMode === FieldRenderMode.Fill &&
            <div key="fill" className="w-full">
                {showPlaceholderLabel && <FormLabel className="mb-4" component="legend">{field.label}</FormLabel>}
                <div className="mb-4">
                    <TextField {...textInputProps(fieldId, errors)}
                               label={showPlaceholderLabel ? "" : field.label}
                               onFocus={onFocus}
                               onBlur={onBlur}
                               id={fieldId}
                               inputRef={register<HTMLInputElement>(props.fillValidationOpts as any)}
                               multiline={field.type === FieldType.TextBlockInput}
                               inputProps={{inputMode: field.type === FieldType.Number ? "numeric" : undefined}}
                               InputLabelProps={{ref: inputLabelRef, style: {paddingRight: 24, width: "100%"}}}
                               rows={field.type === FieldType.TextBlockInput ? field.config.numberOfLines : undefined}
                               type={field.type === FieldType.Email ? "email" : field.type === FieldType.Number ? "number" : field.type === FieldType.Phone ? "tel" : ""}
                    />
                </div>
            </div>
            }
            {props.fieldRenderMode === FieldRenderMode.Display &&
            <div key="display" className="w-full">
                <div className="field-display-name">
                    {field.label}:
                </div>
                <div className="field-display-data">
                    {data?.value !== "" ? data?.value : "(none)"}
                </div>
            </div>
            }
        </>
    )
});