import { differenceInYears } from 'date-fns'
import {format, utcToZonedTime} from 'date-fns-tz';
import firebase from 'firebase/app';

export enum TimestampFormat {
    ShortDateFormat = "d MMM yy",
    ShortDateTimeFormat = "d MMM yy HH:mm",
    DateTimeFormat = "d MMMM yyyy HH:mm",
    DateTimeFormatWithTz = "d MMMM yyyy HH:mm zzz",
    DateFormat = "d MMMM yyyy",
    CsvDateFormat = "yyyy-LL-dd",
    CsvDateTimeFormat = "yyyy-LL-dd HH:mm",
    CsvDateTimeWithTimeZoneFormat = "yyyy-LL-dd HH:mm zzz"
}

export const calculateAge = (timestamp: firebase.firestore.Timestamp) => {
    return differenceInYears(new Date(), timestamp.toDate());
}

export const formatTimestamp = (timestamp: firebase.firestore.Timestamp | undefined | null, formatDefinition: TimestampFormat) => {
    return timestamp && timestamp.toDate ? format(timestamp.toDate(), formatDefinition) : "";
};

export const formatTimestampAtTimeZone = (timestamp: firebase.firestore.Timestamp | undefined | null, formatDefinition: TimestampFormat, timeZone: string) => {
    return timestamp && timestamp.toDate ? format(utcToZonedTime(timestamp.toDate(), timeZone), formatDefinition, {timeZone}) : "";
};

export enum DateFormat {
    Dmy = "Dmy",
    Mdy = "Mdy",
    Ymd = "Ymd"
}

export const dateFormatters = {
    [DateFormat.Dmy]: "dd/MM/yyyy",
    [DateFormat.Mdy]: "MM/dd/yyyy",
    [DateFormat.Ymd]: "yyyy/MM/dd",
};

export const startOfTodayUTC = () => new Date(new Date().setUTCHours(0,0,0,0));
export const startOfDayUTC = (date: Date) => new Date(date.setUTCHours(0,0,0,0));
//export const endOfTodayUTC = () => new Date(new Date().setUTCHours(23,59,59,999));
