import {observable, action} from "mobx";

export class UILayoutStore {
    @observable showHeader = true;
    @observable showFooter = true;
    @observable showSideNav = true;
    @observable isSideNavOpen = false;

    @action
    reset() {
        this.showHeader = true;
        this.showFooter = true;
        this.showSideNav = true;
    }
}
