import React, {useEffect, useState} from "react";
import {useMemo} from "use-memo-one";
import {useParams} from "react-router-dom";
import {observer} from "mobx-react";
import Card from "@material-ui/core/Card";
import {FieldData, FieldInstance, FieldRenderMode, FieldType} from "shared/forms/model";
import {formatTimestamp, TimestampFormat} from "shared/utils/dateUtils";
import {userSubmissionId} from "shared/submissions/model";
import {useStores} from "../../stores/context";
import {ThemeProvider, useTheme} from "@material-ui/core/styles";
import {createFormTheme, FieldStyle} from "../forms/editFields/field";
import {MatxLoading} from "../../matx";
import {setTitle} from "../utilities/setTitle";
import {FormProvider, useForm} from "react-hook-form";
import Button from "@material-ui/core/Button";
import {webPathReader} from "../utilities/webPathReader";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextDisplayField from "views/forms/editFields/TextDisplayField";
import TextInputField from "../forms/editFields/TextInputField";
import CheckboxField from "../forms/editFields/CheckboxField";
import DateField from "../forms/editFields/DateField";
import NameField from "../forms/editFields/NameField";
import RadioField from "../forms/editFields/RadioField";
import AddressField from "../forms/editFields/AddressField";
import {RepeatedGroupField} from "../forms/editFields/RepeatedGroupField";
import SignatureField from "../forms/editFields/SignatureField";

const ImageField = React.lazy(() => import("../forms/editFields/ImageField"));

export const ViewSubmissionPage = observer(function ViewSubmissionPage() {
    const { formId, submissionId } = useParams();
    const {formEditStoreFactory, formConfigEditStoreFactory, submissionEditStoreFactory} = useStores();
    const submissionEditStore = useMemo(() => {
        return submissionEditStoreFactory(submissionId, formId)
    }, [submissionEditStoreFactory, submissionId, formId]);
    const submissionFormId = submissionEditStore.entity?.formId;
    const formEditStore = useMemo(() => formEditStoreFactory(submissionFormId, false), [formEditStoreFactory, submissionFormId]);
    const formConfigId = submissionEditStore.entity?.formConfigId;
    const formConfigEditStore = useMemo(() => formConfigId ? formConfigEditStoreFactory(formConfigId) : undefined, [formConfigEditStoreFactory, formConfigId]);

    const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);

    const theme = useTheme();
    const hookContext = useForm();

    const isLoading = submissionEditStore.isLoading || formEditStore.isLoading;
    const fieldInstances = formConfigEditStore?.entity?.fieldInstances;
    const formName = formEditStore?.entity?.name;

    useEffect(() => {
        if (isLoading) {
            setTitle("Loading ...");
        } else {
            setTitle(formName);
        }
    }, [isLoading, formName]);

    const fieldRenderMode = FieldRenderMode.Display;

    const fieldStyle = useMemo(() => ({
        fontSize: 14,
        theme: createFormTheme(theme, 14)
    }), [theme]);

    const handleDownloadPdf = async () => {
        if (fieldInstances) {
            setIsDownloadingPdf(true);
            const pdf = await submissionEditStore.renderPdf(fieldInstances, webPathReader);
            pdf.download(submissionEditStore.pdfFileName());
            setIsDownloadingPdf(false);
        }
    }

    return (isLoading || !fieldInstances) ?
        <MatxLoading/> :
        <ThemeProvider theme={fieldStyle.theme}>
            <FormProvider {...hookContext}>
                <Card elevation={6} className="my-6 mx-auto max-w-2xl">
                    <div className="px-6 pb-3 pt-5 bg-light-gray">
                        <Button
                            onClick={handleDownloadPdf}
                            className="float-right"
                            color="primary"
                            disabled={isDownloadingPdf}
                            variant="contained">
                            Download as PDF
                            {isDownloadingPdf &&
                            <CircularProgress className="ml-2"
                                              size={20}
                            />
                            }</Button>
                        <div>
                            <div className="field-display-name">Submission id:</div>
                            <div className="field-display-data">
                                {userSubmissionId(submissionEditStore.id!)}
                            </div>
                        </div>
                        <div className="mb-4 mt-6">
                            <div className="field-display-name">Submitted at:</div>
                            <div className="field-display-data">
                                {formatTimestamp(submissionEditStore.entity.clientCreatedAt, TimestampFormat.DateTimeFormatWithTz)}
                            </div>
                        </div>

                        {submissionEditStore.entity.createdAt.toMillis() > submissionEditStore.entity.clientCreatedAt.toMillis() + 60000 &&
                        <div className="mb-4 mt-6">
                            <div className="field-display-name">Received at:</div>
                            <div className="field-display-data">
                                {formatTimestamp(submissionEditStore.entity.createdAt, TimestampFormat.DateTimeFormatWithTz)}
                            </div>
                        </div>
                        }
                    </div>

                    <div className="px-6 py-5">
                        <div className="text-xl font-bold pb-6">{submissionEditStore.entity.formName}</div>
                        <RenderFields
                            fieldStyle={fieldStyle}
                            fieldInstances={fieldInstances}
                            fieldRenderMode={fieldRenderMode}
                            submissionId={submissionEditStore.id}
                            data={submissionEditStore.entity.data}
                            isInsideGroup={false}
                        />
                    </div>
                </Card>
            </FormProvider>
        </ThemeProvider>;
});

interface RenderFieldProps {
    fieldInstances: FieldInstance[];
    fieldRenderMode: FieldRenderMode;
    fieldStyle: FieldStyle;
    data: {[fieldId: string]: FieldData};
    submissionId: string | undefined;
    isInsideGroup: boolean;
}

export const RenderFields = ({fieldInstances, fieldRenderMode, fieldStyle, data, submissionId, isInsideGroup}: RenderFieldProps) => {

    const renderField = (
        field: FieldInstance,
        nodeType: React.FunctionComponent<any>
    ) => {
        return React.createElement(nodeType, {
            field,
            fieldId: field.id,
            fieldRenderMode,
            data: data[field.id],
            fieldStyle,
            submissionId: submissionId,
            isInsideGroup
        });
    }

    return <>{fieldInstances.map((field) => {
            return (<div className={field.type !== FieldType.RepeatedGroup ? "pb-6" : ""} key={field.id}>
                {field.type === FieldType.Text && renderField(field, TextDisplayField)}
                {field.type === FieldType.Heading && renderField(field, TextDisplayField)}
                {field.type === FieldType.Email && renderField(field, TextInputField)}
                {field.type === FieldType.TextInput && renderField(field, TextInputField)}
                {field.type === FieldType.Number && renderField(field, TextInputField)}
                {field.type === FieldType.TextBlockInput && renderField(field, TextInputField)}
                {field.type === FieldType.Checkbox && renderField(field, CheckboxField)}
                {field.type === FieldType.Date && renderField(field, DateField)}
                {field.type === FieldType.Dob && renderField(field, DateField)}
                {field.type === FieldType.Name && renderField(field, NameField)}
                {field.type === FieldType.Phone && renderField(field, TextInputField)}
                {field.type === FieldType.Radio && renderField(field, RadioField)}
                {field.type === FieldType.Dropdown && renderField(field, RadioField)}
                {field.type === FieldType.Signature && renderField(field, SignatureField)}
                {field.type === FieldType.Image && renderField(field, ImageField)}
                {field.type === FieldType.Address && renderField(field, AddressField)}
                {field.type === FieldType.RepeatedGroup && renderField(field, RepeatedGroupField)}
            </div>);
        })}</>;
};