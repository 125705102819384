import React, { useState } from "react";
import FillSubmissionForm from "./FillSubmissionForm";

const FillSubmissionPage = function FillSubmissionPage() {
    const [displayId, setDisplayId] = useState(0);

    return <div className="layout1">
            <div className="content-wrap">
                <div className="scrollable-content">
                    <div className="content">
                        <FillSubmissionForm key={displayId} isStandalone={true} onSubmit={() => {
                            setDisplayId(displayId + 1)
                        }}/>
                    </div>
                </div>
            </div>
        </div>;

};

export default FillSubmissionPage;