import {authRoles} from "shared/auth/authRoles";
import SubmissionListingPage from "./SubmissionListingPage";
import NewSubmissionPage from "./NewSubmissionPage";
import FillSubmissionForm from "./FillSubmissionForm";
import {ViewSubmissionPage} from "./ViewSubmissionPage";

const submissionRoutes = [
  {
    path: "/submissions/new",
    component: NewSubmissionPage,
    auth: authRoles.user
  },
  {
    path: "/submissions/form/:formId",
    component: FillSubmissionForm,
    auth: authRoles.user
  },
  {
    path: "/submissions/:submissionId",
    component: ViewSubmissionPage,
    auth: authRoles.user
  },
  {
    path: "/submissions",
    component: SubmissionListingPage,
    auth: authRoles.user
  }

];

export default submissionRoutes;
