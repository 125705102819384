import React, {useLayoutEffect, useEffect, useState} from "react";
import {useMemo} from "use-memo-one";
import {useHistory, useParams} from "react-router-dom";
import {observer} from "mobx-react";
import Card from "@material-ui/core/Card";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import {useForm, FormProvider, useWatch} from "react-hook-form";
import {AckDialog} from "../common/AckDialog";
import {useStores} from "../../stores/context";
import {MatxLoading} from "../../matx";
import {uploadImages} from "shared/submissions/imageHelpers";
import {setTitle} from "../utilities/setTitle";
import {FillSubmissionFields} from "./FillSubmissionFields";
import {webPathReader} from "../utilities/webPathReader";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField/TextField";
import DialogContentText from "@material-ui/core/DialogContentText";
import { emailValidator } from "shared/forms/validationOpts";
import {logger} from "../../sumoLogger";
import {useIsSmallScreen} from "../../hooks/screen";
import {useQuery} from "../../hooks/useQuery";

interface IProps {
    isStandalone?: boolean;
    onSubmit?: () => void;
}

const log = logger("FillSubmissionForm");

const FillSubmissionForm = observer(function FillSubmissionForm(props: IProps) {
    const { formId, submissionId } = useParams();
    const query = useQuery();
    const groupId = query.get("groupId") || undefined;
    const {formEditStoreFactory, submissionEditStoreFactory} = useStores();
    const submissionEditStore = useMemo(() => submissionEditStoreFactory(submissionId, formId)
    , [submissionEditStoreFactory, submissionId, formId]);
    const submissionFormId = submissionEditStore.entity?.formId;
    const formEditStore = useMemo(() => formEditStoreFactory(submissionFormId, false), [formEditStoreFactory, submissionFormId]);

    const [doDisplayAckDialog, setDoDisplayAckDialog] = useState(false);
    const [formInitialized, setFormInitialized] = useState(false);
    const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [hasSentEmail, setHasSentEmail] = useState(false);

    const hookContext = useForm({});
    const { handleSubmit } = hookContext;
    const sendEmailHookContext = useForm<{email: string}>({mode: "onBlur"});
    const watchEmail = useWatch({name: "email", control: sendEmailHookContext.control, defaultValue: ""});

    const history = useHistory();
    const isSmallScreen = useIsSmallScreen();

    const isLoading = submissionEditStore.isLoading || formEditStore.isLoading;
    const currentFieldInstances = formEditStore.entity?.currentFieldInstances;
    const formName = formEditStore?.entity?.name;

    useLayoutEffect(() => {
        if (!isLoading && currentFieldInstances) {
            setFormInitialized(true);
            window.scrollTo(0,0);
        }
    }, [isLoading, currentFieldInstances, setFormInitialized]);

    useEffect(() => {
        if (isLoading) {
            setTitle("Loading ...");
        } else {
            setTitle(`Fill ${formName}`);
        }
    }, [isLoading, formName]);

    const handleAckClose = async () => {
        const submitUrl = formEditStore?.entity?.ackSubmitUrl;
        if (!!submitUrl) {
            window.location.href = submitUrl;
        } else if (props.isStandalone) {
            setDoDisplayAckDialog(false);
            if (groupId) {
                history.push(`/fillgroup/${groupId}`);
            } else if (props.onSubmit) {
                props.onSubmit();
            }
        } else {
            history.push("/submissions");
        }
    };

    const handleDownloadPdf = async () => {
        setIsDownloadingPdf(true);
        const pdf = await submissionEditStore.renderPdf(currentFieldInstances, webPathReader);
        pdf.download(submissionEditStore.pdfFileName());
        setIsDownloadingPdf(false);
    }

    const handleSendEmail = async () => {
        if (await sendEmailHookContext.trigger()) {
            setIsSendingEmail(true);
            const destinationEmail = sendEmailHookContext.getValues("email");
            try {
                await submissionEditStore.sendEmail(destinationEmail);
                setIsSendingEmail(false);
                setHasSentEmail(true);
            } catch(e) {
                log.error(e, "Error sending e-mail");
                setIsSendingEmail(false);
            }
        }
    }

    if (!formInitialized) {
        return <MatxLoading/>;
    } else {
        const onSubmit = async (values: any) => {
            submissionEditStore.updateFormData(formEditStore.entity);
            submissionEditStore.updateEntityUsingFormData(currentFieldInstances, values, formEditStore.entity.orgId);
            submissionEditStore.updateGroupId(groupId);

            const images = await submissionEditStore.imagesToSave(currentFieldInstances, values, submissionEditStore.entity.data);
            const uploadTasks = await uploadImages(images);
            await(Promise.all(uploadTasks));

            await submissionEditStore.save();
            setDoDisplayAckDialog(true);
        };

        return <>
            {doDisplayAckDialog &&
            <AckDialog
                title="Great!"
                handleOk={handleAckClose}
                handleClose={handleAckClose}
                disableBackdropClick={true}
                okText="Done!"
                extraActions={
                <Button onClick={handleDownloadPdf} disabled={isDownloadingPdf}>
                    Download as PDF
                    {isDownloadingPdf &&
                    <CircularProgress className="ml-2"
                                      size={20}
                    />
                    }
                </Button>
            }>
                <DialogContentText>{submissionEditStore.ackText}</DialogContentText>
                {!!formEditStore.entity.ackText &&
                <DialogContentText>
                    {formEditStore.entity.ackText}
                </DialogContentText>
                }
                {!!formEditStore.entity.canEmailWaiver &&
                <div className="flex flex-row mt-8">
                    <TextField className="flex-grow"
                               name="email"
                               id="email"
                               label="E-mail waiver"
                               variant="outlined"
                               inputRef={sendEmailHookContext.register({validate: emailValidator})}
                               error={sendEmailHookContext.errors.email?.message !== undefined}
                               helperText={sendEmailHookContext.errors.email?.message}
                               InputProps={{
                                   endAdornment: <Button onClick={handleSendEmail} variant="contained"
                                                         style={{color: hasSentEmail ? "#1fa91f" : undefined}}
                                                         disabled={watchEmail.length === 0 || isSendingEmail || hasSentEmail}>
                                       {isSendingEmail ? "Sending" : hasSentEmail ? "Sent" : "Send"}
                                   </Button>
                               }}

                    />
                </div>
                }<div>

            </div>
            </AckDialog>
            }
            <form onSubmit={handleSubmit(onSubmit)} className="md:mx-4 pb-6">
                <FormProvider {...hookContext}>
                    <Card elevation={isSmallScreen ? 0 : 6} className="md:my-6 mx-auto max-w-2xl">
                        <div className="px-4 md:px-6 py-5">
                            <FillSubmissionFields
                                fieldInstances={currentFieldInstances}
                                submissionId={submissionEditStore.id}
                                data={submissionEditStore.entity.data}
                                isInsideGroup={false}
                                orgId={formEditStore.entity.orgId}
                            />
                            <div className="flex justify-end mt-6 pb-8">
                                <Button color="secondary" variant="contained" type="submit">
                                    Submit
                                    <Icon className="ml-2">send</Icon>
                                </Button>
                            </div>
                            <div className="text-center mt-4">
                                <a className="underline" rel="noopener noreferrer" target="_blank" href="https://www.speedywaiver.io">Created with speedyWaiver</a> - hassle-free online waivers.
                            </div>
                        </div>
                    </Card>
                </FormProvider>
            </form>
        </>;
    }
});

export default FillSubmissionForm;