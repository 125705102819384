import React from "react";
import { NavLink } from "react-router-dom";
import TouchRipple from "@material-ui/core/ButtonBase";
import FormIcon from 'mdi-material-ui/TextBoxMultipleOutline';
import SubmissionIcon from 'mdi-material-ui/TextBoxCheckOutline';
import SettingsIcon from 'mdi-material-ui/Cog';
import SupportIcon from 'mdi-material-ui/HelpCircle';
import DashboardIcon from 'mdi-material-ui/ChartLine';
import BillingIcon from 'mdi-material-ui/CashUsdOutline';
import GroupIcon from 'mdi-material-ui/AccountGroup';

const NavItem = props => {
  const itemBody = <TouchRipple name="child" className="w-full">
    {props.icon}
    <span className="align-middle item-text">{props.name}</span>
    <div className="mx-auto"/>
    {props.badge && (
        <div className={`badge bg-${props.badge.color}`}>
          {props.badge.value}
        </div>
    )}
  </TouchRipple>;

  if (props.type === "extLink") {
    return (
        <a
            key={props.name}
            href={props.path}
            className="nav-item"
            rel="noopener noreferrer"
            target="_blank"
        >
          {itemBody}
        </a>
    );
  } else {
    return (
        <NavLink key={props.name} to={props.path} className="nav-item">
          {itemBody}
        </NavLink>
    );
  }
};

const MatxVerticalNav = props => {

  return <div className="navigation">
    <NavItem name="Submissions" icon={<SubmissionIcon className="item-icon align-middle"/>} path="/submissions"/>
    <NavItem name="Forms" icon={<FormIcon style={{fontSize: "17px", marginLeft: "1px"}} className="item-icon align-middle"/>} path="/forms"/>
    <NavItem name="Groups/Events" icon={<GroupIcon style={{fontSize: "17px", marginLeft: "1px"}} className="item-icon align-middle"/>} path="/groups"/>
    <NavItem name="Analytics" icon={<DashboardIcon className="item-icon align-middle"/>} path="/analytics"/>
    <NavItem name="Billing" icon={<BillingIcon className="item-icon align-middle"/>} path="/billing"/>
    <NavItem name="Settings" icon={<SettingsIcon className="item-icon align-middle"/>} path="/settings"/>
    <NavItem name="Support" icon={<SupportIcon className="item-icon align-middle"/>} type="extLink" path="https://www.speedywaiver.io/support"/>
  </div>;
};

export default MatxVerticalNav;
