import React from "react";
import {debugLogger} from "../../sumoLogger";
import { observer } from "mobx-react";

export const DebugLogger = observer(() =>
    <div style={{zIndex: 5000, pointerEvents: "none", top: 0, backgroundColor: "rgba(255, 255, 255, 0.6)"}} className="w-full absolute">
        {debugLogger?.log.map((item, i) =>
            <div style={{fontSize: 10}} key={i}>
                ➡{JSON.stringify(item)}
                <br/>
            </div>)}
    </div>
)