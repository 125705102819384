import {DataParameters, FieldInstance, FieldRenderMode, FieldType} from "shared/forms/model";
import { ValidationRules } from "react-hook-form";
import { Theme } from "@material-ui/core/styles";

export interface FieldStyle {
    fontSize: number;
    theme: Theme;
}

export interface FieldProps<T extends FieldType> {
    fieldRenderMode: FieldRenderMode;
    field: Extract<FieldInstance, { type: T }>;
    fieldId: string;
    data?: DataParameters<T>;
    isInFormBuilder?: boolean;
    fillValidationOpts?: ValidationRules;
    fieldStyle: FieldStyle;
    submissionId?: string;
    orgId: string;
    isInsideGroup: boolean;
}

export const createFormTheme = (theme: Theme, fontSize: number) => {
    const newTheme = {
        ...theme,
        typography: {
            ...(theme.typography),
            body1: {
                ...(theme.typography.body1),
                fontSize
            },
            button: {
                ...(theme.typography.button),
                fontSize
            },
            caption: {
                ...(theme.typography.caption),
                fontSize: fontSize * 0.75
            }
        }
    };

    return newTheme;
}
