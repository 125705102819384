import * as React from "react";
import {useEffect} from "react";
import {Dashboard} from '@uppy/react';
import Uppy from '@uppy/core';
import Webcam from '@uppy/webcam';
import {getFirebaseApp} from "firestorter";

import { useMemo } from "use-memo-one";
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/webcam/dist/style.css'
import '@uppy/core/dist/style.css'
import '@uppy/progress-bar/dist/style.css'
import { nanoid } from "nanoid";
import { logger } from "sumoLogger";

interface Props {
    path: string;
    handleIsUploading: () => void;
    handleFileRemoved: (file: string, reason: string) => void;
    handleUploaded: (result: any) => void;
    allowFromCamera: boolean;
    overrideHeight?: number;
}

const storage = getFirebaseApp().storage();

export default function ImageUpload(props: Props) {
    const uppy = useMemo(() => {

        const uppyBuilder = Uppy({
            autoProceed: true,
            restrictions: {
                maxFileSize: 5000000,
                maxNumberOfFiles: 1,
                allowedFileTypes: ['image/*']
            },
        })
            .use(FirebaseCloudStorage, {path: props.path})
            .on('upload', props.handleIsUploading)
            .on('file-removed', props.handleFileRemoved)
            .on('complete', props.handleUploaded);

        if (props.allowFromCamera) {
            uppyBuilder.use(Webcam);
            return uppyBuilder;
        } else {
            return uppyBuilder;
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return () => uppy?.close();
    }, [uppy]);

    return uppy && <div className="mt-4"><Dashboard
            uppy={uppy}
            width="100%"
            plugins={['Webcam']}
            height={props.overrideHeight || 0}
            showRemoveButtonAfterComplete={true}
            showLinkToFileUploadResult={false}
            note="Images only, up to 5 MB"
        /></div>;
}

interface FirebaseCloudStorageOpts extends Uppy.PluginOptions {
    path: string;
}

const log = logger("ImageUpload");

class FirebaseCloudStorage extends Uppy.Plugin {
    path: string;

    constructor(uppy: Uppy.Uppy, opts: FirebaseCloudStorageOpts) {
        super(uppy, opts);
        this.type = "uploader";
        this.id = "FirebaseCloudStorage";
        this.path = opts.path;
        //this.title = "Firebase Cloud Storage";
        this.uploadFile = this.uploadFile.bind(this);
    }

    async uploadFile(fileIds: string[]): Promise<void> {
        const path = this.path.replace("$RANDOM$", nanoid());
        log.info(`Uploading to ${path}`);
        await Promise.all(
            fileIds.map(id => {
                return new Promise((resolve, reject) => {
                    const file = this.uppy.getFile(id);
                    (file as any).path = path;
                    const fileRef = storage.ref(path);
                    const metaData = {
                        contentType: file.type
                    };
                    this.uppy.emit("upload-started", file);
                    const uploadTask = fileRef.put(file.data, metaData);
                    uploadTask.on(
                        "state_changed",
                        snapshot => {
                            const progressInfo = {
                                uploader: this,
                                bytesUploaded: snapshot.bytesTransferred,
                                bytesTotal: snapshot.totalBytes
                            };
                            this.uppy.emit("upload-progress", file, progressInfo);
                        },
                        error => {
                            this.uppy.emit("upload-error", file, error);
                            reject(error);
                        },
                        () => {
                            this.uppy.emit(
                                "upload-success",
                                file,
                                uploadTask.snapshot
                            );
                            resolve();
                        }
                    );
                });
            })
        );
    }

    install() {
        this.uppy.addUploader(this.uploadFile);
    }

    uninstall() {
        this.uppy.removeUploader(this.uploadFile);
    }
}