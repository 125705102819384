import firebase from 'firebase/app';
import {AuthException, UserStore} from "shared/auth/userStore";
import LogRocket from 'logrocket';
import {Context, Logger} from "shared/common/context";
import {functionsRoot} from "shared/utils/firebaseFunctions";

export class AuthStore {

    onSignIn: () => void = () => {};

    userStore: UserStore;

    private log: Logger;

    constructor(context: Context, userStore: UserStore) {
        this.log = context.logger(this);

        this.userStore = userStore;

        firebase.auth().onAuthStateChanged(
            async (user: firebase.User | null) => {
                this.log.debug(`Auth state changed`, user);
                await userStore.onAuthStateChanged(user);
                if (user && userStore.isSignedIn) {
                    if (urlParams.get('mailchimpCallback') === "true") {
                        await this.handleMailchimpCallback(urlParams);
                    }

                    if ((LogRocket as any)._isInitialized) {
                        LogRocket.identify(user.uid, {
                            name: user.displayName as any,
                            email: user.email as any,
                        });
                    }
                    if (window.heap.identify) {
                        window.heap.identify(user.uid);
                        window.heap.addUserProperties({"email": user.email});
                    }

                    if (this.onSignIn) {
                        this.onSignIn();
                    }
                }
            });

        const urlParams = new URLSearchParams(window.location.search);
        const authToken = urlParams.get('authToken');
        if (authToken) {
            this.userStore.signInWithCustomToken(authToken).then();
        }
    }

    handleMailchimpCallback = async (urlParams: URLSearchParams) => {
        const code = urlParams.get('code');
        const getMailchimpTokenFn = functionsRoot().httpsCallable('getMailchimpToken');
        await getMailchimpTokenFn({code, callbackUrl: mailchimpCallbackUrl()});
    }

    abortIfNotSignedIn() {
        if (!this.userStore.isSignedIn) {
            throw new AuthException();
        }
    }

}

export const mailchimpCallbackUrl = () => {
    return `${window.location.protocol}//${window.location.host}/settings?mailchimpCallback=true`;
}
