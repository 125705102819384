import React from "react";
import {useHistory} from "react-router-dom";

const Brand = () => {
    const history = useHistory();

  return (
    <div className="flex items-center justify-between brand-area">
      <div className="flex items-center brand">
          <a className="cursor-pointer" href="/submissions" onClick={(event) => { event.preventDefault(); history.push("/submissions"); }}>
              <img src="/assets/images/logo-and-text.png" alt="speedyForms Logo" />
          </a>
          {process.env.REACT_APP_ENV === 'staging' && <span className="brand__text">(s)</span>}
      </div>
    </div>
  );
};

export default Brand;
