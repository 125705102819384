import {ListStore} from "../common/listStore";
import {UserStore} from "../auth/userStore";
import {Group, groupsEntity} from "./model";
import { reaction, observable, action } from "mobx";
import PagedCollection, {IPagedCollectionOptions} from "../common/pagedCollection";
import {normalizeForIndexing} from "../utils/stringUtils";
type CollectionReference = firebase.firestore.CollectionReference;

export class GroupListStore extends ListStore<Group> {

    constructor(userStore: UserStore) {
        super(userStore, groupsEntity, 20);

        reaction(() => userStore.isSignedIn, (isSignedIn) => {
            if (!isSignedIn && this.collection) {
                this.collection.disconnectQuery();
                this.collection = undefined as any;
            }
        }, {name: "GroupListStore-SignedIn"})
    }

    @observable currentSearchString = "";
    @observable currentSortOrder: Partial<IPagedCollectionOptions<Group>> = {order: "nameLower", directionStr: "asc", order2: null, directionStr2: "asc"};

    private basicQuery = () => (r: CollectionReference) => {
        const query = r
            .where('isDeleted', '==', false)
            .where("orgId", "==", this.userStore.userData!.orgId)

        return query;
    }


    ensureInitialized(): void {
        if (!this.collection) {
            this.collection = new PagedCollection<Group>(this.entityType, {pageSize: this.pageSize, query: this.basicQuery(), ...this.currentSortOrder});
            reaction(() => `${this.currentSearchString} ${this.currentSortOrder.order}`, () => {
                if (this.currentSearchString === "") {
                    this.collection.updateQuery({query: this.basicQuery(), pageSize: this.pageSize, ...this.currentSortOrder });
                } else {
                    const normalizedStr = normalizeForIndexing(this.currentSearchString);
                    this.collection.updateQuery({query: (r: CollectionReference) => this.basicQuery()(r).where('keywords', 'array-contains', normalizedStr), pageSize: this.pageSize, ...this.currentSortOrder});
                }
            }, {delay: 250});
        }
    }

    @action
    handleSearch = (str: string) => {
        this.currentSearchString = str;
    };

    @action
    handleSortChange = (sortField: string) => {
        if (sortField === "eventStartAt") {
            this.currentSortOrder = {order: "eventStartAt", directionStr: "desc", order2: "nameLower", directionStr2: "asc"};

        } else {
            this.currentSortOrder = {order: "nameLower", directionStr: "asc", order2: null, directionStr2: "asc"};
        }

    }

}