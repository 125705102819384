import * as React from "react";
import { SimpleCard } from "matx";
import {observer} from "mobx-react";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import {useEffect} from "react";
import {setTitle} from "../utilities/setTitle";
import GroupListingTable from "./GroupListingTable";
import { FullWidthPageWrapper } from "matx/components/PageWrapper";

export default observer(() => {
    const history = useHistory();

    useEffect(() => {
        setTitle(`Groups/Events`);
    }, []);

    const topRightContent = <>
        <span className="ml-2">
            <Button onClick={() => history.push('/groups/new')} variant="contained" color="primary">
                <Icon>add</Icon><span className="pl-2">Add New Group/Event</span>
            </Button>
        </span>
        </>;

    return (
        <FullWidthPageWrapper>
            <SimpleCard title="Groups/Events" topRightContent={topRightContent}>
                <GroupListingTable />
            </SimpleCard>
        </FullWidthPageWrapper>
    );
});