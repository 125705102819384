import {useCallback, useEffect, useRef} from 'react';
import {useHistory} from "react-router-dom";


export default (navigationBlockCondition?: () => boolean) => {
    const blockNavigationRef = useRef(true);
    const history = useHistory();

    const shouldBlock = useCallback(() => {
        return blockNavigationRef.current &&
            (navigationBlockCondition === undefined || navigationBlockCondition());
    }, [navigationBlockCondition]);

    const unblockNavigation = () => {
        blockNavigationRef.current = false;
    };

    useEffect(() => {

        const handler = (event: Event) => {
            if (shouldBlock()) {
                event.preventDefault();
                event.returnValue = true;
            }
        };

        const historyClearCallback = history.block((_location, action) => {
            if (shouldBlock()) {

                //workaround for https://stackoverflow.com/questions/38083702/why-are-alert-confirm-and-prompt-not-working-after-using-js-history-api
                if (action === "POP" && (window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i))) {
                    unblockNavigation();
                }

                return 'Are you sure you want to leave without saving changes?';
            }

        });

        window.addEventListener("beforeunload", handler);

        return () => {
            historyClearCallback();
            window.removeEventListener("beforeunload", handler);
        }
    }, [history, shouldBlock]);

    return unblockNavigation;
};