import * as React from "react";
import {FieldInstance, FieldRenderMode, FieldType} from "shared/forms/model";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Icon from "@material-ui/core/Icon";
import {action, runInAction} from "mobx";
import {observer} from "mobx-react";
import {FieldProps} from "./field";
import { Controller, get } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import {errorMessage} from "shared/utils/error";
import FormHelperText from "@material-ui/core/FormHelperText";
import {useRef} from "react";
import {useStores} from "../../../stores/context";
import {AppTextInput} from "../../common/AppTextInput";

export default observer(React.forwardRef((props: FieldProps<FieldType.Checkbox>, ref: React.Ref<HTMLInputElement>) => {

    const {field, fieldId, data} = props;
    const { errors } = useFormContext();
    const inputRef = useRef<HTMLButtonElement>(null);

    return (
        <>
            {props.fieldRenderMode === FieldRenderMode.Edit && <CheckboxFieldEdit isInsideGroup={props.isInsideGroup} field={field}/>}
            {props.fieldRenderMode === FieldRenderMode.Fill &&
            <>
                <FormControlLabel
                    control={
                        <Controller
                            render={({onChange, onBlur, value}) => (
                                <Checkbox
                                    onChange={(event) => onChange(event.target.checked)}
                                    onBlur={onBlur}
                                    value={value}
                                    defaultChecked={field.config.defaultValue}
                                    color="primary"
                                    style={{top: -7}}
                                    ref={inputRef}/>
                            )}
                            name={fieldId}
                            defaultValue={field.config.defaultValue}
                            rules={props.fillValidationOpts}
                            onFocus={() => {
                                inputRef.current?.scrollIntoView();
                                inputRef.current?.focus();
                            }}
                        />
                    }
                    style={{alignItems: "start"}}
                    label={field.label}
                />
                {[get(errors, fieldId)].map(error => error && <FormHelperText key={1} error={true}>{errorMessage(error)}</FormHelperText>)}
            </>
            }
            {props.fieldRenderMode === FieldRenderMode.Display &&
            <div key="display" className="items-center flex w-full">
                <span className="field-display-name">{props.field.label}:</span>
                {data?.value ? <Icon aria-hidden={false} aria-label="Checked">done</Icon> : <Icon aria-hidden={false} aria-label="Unchecked">clear</Icon>}
            </div>
            }
        </>
    );
}));

interface CheckboxFieldEditProps {
    field: Extract<FieldInstance, { type: FieldType.Checkbox }>;
    isInsideGroup: boolean;
}

const CheckboxFieldEdit = observer(({field, isInsideGroup}: CheckboxFieldEditProps) => {
    const editFieldId = "e-" + field.id;
    const { register } = useFormContext();

    const {settingsStore} = useStores();
    settingsStore.ensureInitialized();
    const isMailchimpEnabled = settingsStore.isMailchimpEnabled

    const updateLabel = (event: any) => {
        runInAction(() => {
            field.isClean = false;
            field.label = event.target.value;
        });
    };

    const updateDefault = (event: any, checked: boolean) => {
        runInAction(() => {
            field.config.defaultValue = checked;
        });
    };

    const updateRequiredForMailchimp = (event: any, checked: boolean) => {
        runInAction(() => {
            field.config.requiredForMailchimp = checked;
        });
    };

    return <><div className="w-full">
        <AppTextInput
            autoFocus
            name={editFieldId}
            label="Checkbox title"
            defaultValue={field.label}
            multiline={true}
            onChange={updateLabel}
            inputRef={register({required: true})}
        />
    </div>
        <FormControlLabel
            control={
                <Checkbox
                    onChange={action((event, checked) => { field.config.isRequired = checked; })}
                    checked={field.config.isRequired}
                    color="primary"
                />
            }
            label="Is required?"
        />
        <FormControlLabel
            control={
                <Checkbox
                    onChange={updateDefault}
                    checked={field.config.defaultValue}
                    color="primary"
                />
            }
            label="Checked by default?"
        />
        {isMailchimpEnabled && !isInsideGroup &&
        <FormControlLabel
            control={
                <Checkbox
                    onChange={updateRequiredForMailchimp}
                    checked={field.config.requiredForMailchimp}
                    color="primary"
                />
            }
            label="Required to add e-mail from this form to Mailchimp?"
        />
        }
    </>;
});