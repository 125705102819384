import React from "react";
import {authRoles} from "shared/auth/authRoles";

const analyticsRoutes = [
  {
    path: "/analytics",
    component: React.lazy(() => import("./Analytics")),
    auth: authRoles.user
  }
];

export default analyticsRoutes;
