import * as firebaseApp from 'firebase/app';
import {Country} from "../common/countries";
import {DateFormat} from "../utils/dateUtils";
type Timestamp = firebaseApp.firestore.Timestamp;

export const formsEntity = "forms";
export const formStatsEntity = "formStats";
export const formConfigsEntity = "formConfigs";

export enum FieldType {
    Text = "text",
    Heading = "heading",
    TextInput = "textinput",
    TextBlockInput = "textblockinput",
    Number = "number",
    Checkbox = "checkbox",
    Email = "email",
    Name = "name",
    Phone = "phone",
    Dob = "dob",
    Date = "date",
    Radio = "radio",
    Dropdown = "dropdown",
    Signature = "signature",
    Image = "image",
    Address = "address",
    RepeatedGroup = "group"
}

export enum FieldRenderMode {
    Edit,
    Fill,
    Display
}

interface TextFieldConfig {
    type: FieldType.Text,
    config: { text: string }
}

interface HeadingFieldConfig {
    type: FieldType.Heading,
    config: { text: string }
}

interface TextInputFieldConfig {
    type: FieldType.TextInput,
    config: {
        isRequired: boolean;
    }
}

interface EmailFieldConfig {
    type: FieldType.Email,
    config: {
        isRequired: boolean;
    }
}

interface CheckboxFieldConfig {
    type: FieldType.Checkbox,
    config: {
        isRequired: boolean;
        defaultValue: boolean;
        requiredForMailchimp: boolean;
    }
}

interface NameFieldConfig {
    type: FieldType.Name,
    config: {
        isRequired: boolean;
    }
}

interface PhoneFieldConfig {
    type: FieldType.Phone,
    config: {
        isRequired: boolean;
    }
}

interface TextBlockFieldConfig {
    type: FieldType.TextBlockInput,
    config: {
        isRequired: boolean;
        numberOfLines: number;
    }
}

interface NumberFieldConfig {
    type: FieldType.Number,
    config: {
        isRequired: boolean;
        minimum?: number;
        maximum?: number;
    }
}

interface DobFieldConfig {
    type: FieldType.Dob,
    config: {
        isRequired: boolean;
        dateFormat: DateFormat;
        minimumAge?: number;
        maximumAge?: number;
    }
}

export interface MultipleChoiceOption {
    text: string;
    id: string;
}

export interface RadioFieldConfig {
    type: FieldType.Radio,
    config: {
        isRequired: boolean;
        options: MultipleChoiceOption[];
        defaultOptionId?: string;
        canSelectOther: boolean;
        canSelectMultiple: boolean;
    }
}

export interface DropdownFieldConfig {
    type: FieldType.Dropdown,
    config: {
        isRequired: boolean;
        options: MultipleChoiceOption[];
        defaultOptionId?: string;
        canSelectOther: boolean;
    }
}

interface DateFieldConfig {
    type: FieldType.Date,
    config: {
        isRequired: boolean;
        defaultToToday: boolean;
        disablePastDates: boolean;
        disableFutureDates: boolean;
        dateFormat: DateFormat;
    }
}


interface SignatureFieldConfig {
    type: FieldType.Signature,
    config: {
        isRequired: boolean;
        allowTyping: boolean;
    }
}

interface ImageFieldConfig {
    type: FieldType.Image,
    config: {
        isRequired: boolean;
        allowFromCamera: boolean;
    }
}

interface AddressFieldConfig {
    type: FieldType.Address,
    config: {
        isRequired: boolean;
        isZipRequired: boolean;
    }
}

interface RepeatedGroupConfig {
    type: FieldType.RepeatedGroup,
    config: {
        isRequired: boolean;
        fieldInstances: FieldInstance[];
        maximumInstances: number | null;
        fixedInstances: number | null;
    }
}

export type FieldInstanceConfig = TextFieldConfig |
    HeadingFieldConfig |
    EmailFieldConfig |
    CheckboxFieldConfig |
    TextInputFieldConfig |
    TextBlockFieldConfig |
    NumberFieldConfig |
    NameFieldConfig |
    PhoneFieldConfig |
    DobFieldConfig |
    DateFieldConfig |
    RadioFieldConfig |
    DropdownFieldConfig |
    SignatureFieldConfig |
    ImageFieldConfig |
    AddressFieldConfig |
    RepeatedGroupConfig;

interface FieldInstanceOther {
    id: string,
    label: string;
    required?: boolean
    isClean: boolean
}

export type FieldInstance = FieldInstanceConfig & FieldInstanceOther;

export interface TextFieldData { type: FieldType.Text }
export interface HeadingFieldData { type: FieldType.Heading }
export interface TextInputFieldData { type: FieldType.TextInput, value: string }
export interface EmailFieldData { type: FieldType.Email, value: string }
export interface NumberFieldData { type: FieldType.Number, value: string }
export interface TextBlockFieldData { type: FieldType.TextBlockInput, value: string }
export interface CheckboxFieldData { type: FieldType.Checkbox, value: boolean }
export interface NameFieldData { type: FieldType.Name, firstName: string, lastName: string }
export interface PhoneFieldData { type: FieldType.Phone, value: string }
export interface DobFieldData { type: FieldType.Dob, value: Timestamp }
export interface DateFieldData { type: FieldType.Date, value: Timestamp }
export interface RadioFieldData { type: FieldType.Radio, value: string | string[], other?: string }
export interface DropdownFieldData { type: FieldType.Dropdown, value: string, other?: string  }
export interface SignatureFieldData { type: FieldType.Signature, path?: string, text?: string  }
export interface ImageFieldData { type: FieldType.Image, path?: string  }
export interface AddressFieldData { type: FieldType.Address, address1: string, address2: string, city: string, state: string, zipCode: string, country?: Country }
export interface RepeatedGroupData { type: FieldType.RepeatedGroup, value: Array<{[fieldId: string]: FieldData}> }

export type FieldData = TextFieldData |
    HeadingFieldData |
    EmailFieldData |
    CheckboxFieldData |
    NumberFieldData |
    TextInputFieldData |
    TextBlockFieldData |
    NameFieldData |
    PhoneFieldData |
    DobFieldData |
    DateFieldData |
    RadioFieldData |
    DropdownFieldData |
    SignatureFieldData |
    ImageFieldData |
    AddressFieldData |
    RepeatedGroupData

type ExcludeTypeField<A> = { [K in Exclude<keyof A, "type">]: A[K] }
export type DataParameters<T> = ExcludeTypeField<Extract<FieldData, { type: T }>>

export interface FormDoc {
    name: string
    currentFormConfigId: string;
    currentFieldInstances: FieldInstance[];
    nameLower: string,
    isPublished: boolean,
    ackText: string;
    ackSubmitUrl: string;
    orgId: string;
    canEmailWaiver: boolean;
    addToMailchimpListId: string | null;
    addToMailchimpListName: string | null;
}

export interface Form {
    name: string,
    nameLower: string,
    isPublished: boolean,
    subCounter: number,
    ackText: string;
    ackSubmitUrl: string;
    canEmailWaiver: boolean;
    addToMailchimpListId: string | null;
    addToMailchimpListName: string | null;
    createdAt: firebase.firestore.Timestamp,
    updatedAt: firebase.firestore.Timestamp,
    currentFormConfigId?: string;
    currentFieldInstances: FieldInstance[];
    orgId: string;
}

export interface FormConfigDoc {
    fieldInstances: FieldInstance[];
    notificationEmail: string | undefined;
    notificationEmailEnabled: boolean;
    sentLastNotificationDate: firebase.firestore.Timestamp;
    formId: string;
    orgId: string;
    isPublished: boolean;
}

export interface FormConfig {
    createdAt: firebase.firestore.Timestamp;
    fieldInstances: FieldInstance[];
    notificationEmail: string | undefined;
    notificationEmailEnabled: boolean;
    sentLastNotificationDate: firebase.firestore.Timestamp;
    formId: string;
    orgId?: string;
}

export const NameHelpers = {
    firstNameFieldId: (fieldId: string) => {
        return `${fieldId}-fn`;
    },
    lastNameFieldId: (fieldId: string) => {
        return `${fieldId}-ln`;
    }
}

export const AddressHelpers = {
    address1FieldId: (fieldId: string) => {
        return fieldId;
    },
    address2FieldId: (fieldId: string) => {
        return `${fieldId}-a2`;
    },
    cityFieldId: (fieldId: string) => {
        return `${fieldId}-city`;
    },
    stateFieldId: (fieldId: string) => {
        return `${fieldId}-state`;
    },
    zipCodeFieldId: (fieldId: string) => {
        return `${fieldId}-zip`;
    },
    countryFieldId: (fieldId: string) => {
        return `${fieldId}-country`;
    },
    isEmpty: (data?: DataParameters<FieldType.Address>) => {
        return !data || (!data.address1 && !data.address2 && !data.city && !data.state && !data.zipCode && !data.country);
    }
}

export const radioText = (data?: ExcludeTypeField<RadioFieldData | DropdownFieldData>) => {
    return (data && Array.isArray(data.value) && data.value.length > 0 ? data!.value.join(", ") : data && data.value ? data.value : "(none)") +
        (data?.other !== undefined ? ` - ${data?.other}` : '');
}

export const allFormsId = "all";