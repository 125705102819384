export enum QueryType {
    SubmissionTimeOfDay = "SubmissionTimeOfDay",
    SubmissionsPerDay = "SubmissionsPerDay",
    FormSelections = "FormSelections",
    Dashboard = "Dashboard"
}

export interface QuerySubmissionsParameters {
    queryType: QueryType;
    fromDateTime: number;
    toDateTime?: number;
    timeZone: string;
    formId?: string;
}