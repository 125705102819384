import {Form, formsEntity} from "./model";
import {ListStore} from "../common/listStore";
import PagedCollection from "../common/pagedCollection";
import {FormEditStore} from "./formEditStore";
import { computed } from "mobx";
import { Context } from "common/context";
import {RootStore} from "../common/rootStore";

export class FormListStore extends ListStore<Form> {

    publishedOnly: boolean;
    context: Context;
    private rootStore: RootStore;
    private timeZone: string;

    constructor(context: Context, rootStore: RootStore, timeZone: string, publishedOnly: boolean) {
        super(rootStore.userStore, formsEntity, 80);
        this.context = context;
        this.publishedOnly = publishedOnly;
        this.rootStore = rootStore;
        this.timeZone = timeZone;
    }

    ensureInitialized(): void {
        if (!this.collection) {
            const query = (r: firebase.firestore.CollectionReference) => {
                if (!this.userStore.isSignedIn) return null;
                const baseQuery = r.where('isDeleted', '==', false).where("orgId", "==", this.userStore.userData!.orgId);
                return this.publishedOnly ? baseQuery.where("isPublished", "==", true) : baseQuery;
            };
            this.collection = new PagedCollection<Form>(this.entityType, {pageSize: this.pageSize, query, order: "nameLower"});

        }
    }

    async duplicate(id: string, name: string) {
        const formEditStore = new FormEditStore(this.context, this.rootStore, this.timeZone, id);
        await formEditStore.loadingPromise;
        formEditStore.setFormName(`${name} #2`);
        formEditStore.setPublished(false);
        await formEditStore.saveAsNew();
    }

    @computed get formNamesById() {
        const formMap = new Map<string, string>();
        this.docs.forEach(form => {
            if (form.data.name) {
                formMap.set(form.id!, form.data.name);
            }
        });
        return formMap;
    }
}