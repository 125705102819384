import {FieldInstance, FieldType} from "./model";
import {ImageHookData, SignatureHookData} from "../submissions/submissionEditStore";
import {ValidationRules, get} from "react-hook-form";
import memoize from "fast-memoize";
import {useMemoOne} from "use-memo-one";
import {isMobile, isWeb} from "../utils/utils";
import { isFuture, addYears, isPast } from "date-fns";
import {notEmpty} from "../utils/tsUtils";

export const emailRegex = /^ *[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4} *$/i;

export const emailValidator = (str: string) => {
    if (str) {
        return emailRegex.test(str.trim()) ? undefined : "Invalid e-mail address";
    } else {
        return undefined;
    }
};

export const emailListValidator = (str: string) => {
    if (str) {
        const addresses = str.split(/[,\n]/);
        const addressErrors = addresses.map(address =>
            emailRegex.test(address.trim()) ? undefined : `Invalid e-mail address: ${address}`
        ).filter(notEmpty);
        if (addressErrors.length > 0) {
            return addressErrors.join(", ");
        } else {
            if (addresses.length > 100) {
                return "Please enter no more than 100 e-mail addresses.";
            } else {
                return undefined;
            }
        }
    } else {
        return undefined;
    }
}

export const phoneValidator = (str: string) => {
    if (str) {
        return /^[0-9()\-+ ]*$/.test(str.trim()) ? undefined : "Invalid phone number";
    } else {
        return undefined;
    }
};

export const useValidationOpts: () => (field: FieldInstance, fieldId: string, getValues: any) => ValidationRules = () =>
    useMemoOne(() => memoize((field: FieldInstance, fieldId: string, getValues: any) => {
        const validationOptions: ValidationRules = {};
        if (field.type === FieldType.Email) {
            validationOptions.validate = emailValidator;
        } else if (field.type === FieldType.Phone) {
            validationOptions.validate = phoneValidator;
        } else if (field.type === FieldType.Number) {
            validationOptions.validate = (str: string) => {
                const numberAsInt = parseInt(str, 10);
                if (!field.config.isRequired && str.trim() === "") {
                    return undefined;
                } else if (isNaN(numberAsInt)) {
                    return "Not a number";
                } else if (field.config.minimum && numberAsInt < field.config.minimum) {
                    return `Must be at least ${field.config.minimum}`;
                } else if (field.config.maximum && numberAsInt > field.config.maximum) {
                    return `Must be at most ${field.config.maximum}`;
                } else {
                    return undefined;
                }
            };
        } else if (field.type === FieldType.Checkbox && field.config.isRequired) {
            validationOptions.validate = (isChecked: boolean) => {
                return !isChecked ? "Required" : undefined;
            };
        } else if (field.type === FieldType.Date || field.type === FieldType.Dob) {
            validationOptions.validate = (date: Date) => {
                if (date) {
                    if (isNaN(date.getTime())) {
                        return "Please enter a valid date"
                    } else if (
                        field.type === FieldType.Dob &&
                        field.config.minimumAge &&
                        isFuture(addYears(date, field.config.minimumAge))) {
                        return `Minimum age: ${field.config.minimumAge}`;
                    } else if (
                        field.type === FieldType.Dob &&
                        field.config.maximumAge &&
                        isPast(addYears(date, field.config.maximumAge))) {
                        return `Maximum age: ${field.config.maximumAge}`;
                    } else {
                        return undefined;
                    }
                } else {
                    return undefined;
                }
            };

        } else if (field.type === FieldType.Radio &&
            (isMobile || field.config.canSelectMultiple) &&
            field.config.isRequired) {
            validationOptions.validate = () => {
                const values = get(getValues(), fieldId);
                if (values) {
                    const isValid = Object.keys(values).some(key => values[key] === true);
                    return isValid ? undefined : "Required";
                } else {
                    return "Required";
                }
            };
        } else if (field.type === FieldType.Signature && field.config.isRequired) {
            validationOptions.validate = (value: SignatureHookData) => {
                const isValid = value && (value.hasStrokes || (value.text && value.text.trim().length > 0));
                return isValid ? undefined : "Required";
            };
        } else if (field.type === FieldType.Image) {
            validationOptions.validate = (value: ImageHookData) => {
                if (value.isUploading) {
                    return "Upload still in progress";
                } else {
                    if (field.config.isRequired && (!value.paths || value.paths.length === 0)) {
                        return "Required";
                    }
                }
                return undefined;
            };
        }


        if (field.type === FieldType.Email ||
            field.type === FieldType.TextInput ||
            field.type === FieldType.Name ||
            field.type === FieldType.Phone ||
            field.type === FieldType.Number ||
            field.type === FieldType.TextBlockInput ||
            field.type === FieldType.Date ||
            field.type === FieldType.Dob ||
            field.type === FieldType.Address ||
            field.type === FieldType.Dropdown ||
            (field.type === FieldType.Radio && isWeb && !field.config.canSelectMultiple)
            ) {
            validationOptions.required = field.config.isRequired ? "Required" : false;
        }
        return validationOptions;
    }), []);