import * as React from "react";
import {FieldRenderMode, FieldType} from "shared/forms/model";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import {action} from "mobx";
import {observer} from "mobx-react";
import {FieldProps} from "./field";
import {useFormContext, useWatch, Controller, get} from "react-hook-form";
import {textInputProps} from "./defaultProps";
import SignatureCanvas from 'react-signature-canvas';
import {RefObject, useEffect, useRef, useState} from "react";
import {useCallback} from "use-memo-one";
import ReactSignatureCanvas from "react-signature-canvas";
import useDimensions from "react-cool-dimensions";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import {errorMessage} from "shared/utils/error";
import FormHelperText from "@material-ui/core/FormHelperText";
import DrawIcon from 'mdi-material-ui/Draw';
import {useImageCommon} from "./imageHooks";
import {SignatureHookData} from "shared/submissions/submissionEditStore";
import {AvailableImageUrl, ImageReference} from "shared/submissions/imageHelpers";
import {generateSvgFromText} from "shared/utils/textToSvg";
import {signaturePath} from "shared/submissions/formDataToSubmissionData";
import { nanoid } from "nanoid";

export const signatureRatio = 0.333;

export default observer(function SignatureField(props: FieldProps<FieldType.Signature>) {

    const {field, fieldId} = props;
    const { errors, register, trigger } = useFormContext();
    const signatureCanvas = useRef<HTMLCanvasElement>();
    const signaturePad = useRef<SignaturePad>();
    const [useText, setUseText] = useState(false);
    const textInputRef = useRef<HTMLInputElement>(null);
    const labelRef = useRef<HTMLLabelElement>(null);
    const textWidthCanvas = useRef<HTMLCanvasElement>(null);

    const {setFieldValue, updateLabel, DisplayImage} = useImageCommon<SignatureHookData>(props);

    const watchText = useWatch({name: fieldId, defaultValue: {hasStrokes: false, text: ""}}).text;

    const getDestinationPath = useCallback(() =>
        signaturePath(props.orgId, props.submissionId!).replace("$RANDOM$", nanoid()),
        [props.orgId, props.submissionId]);

    const refresh = useCallback(() => {
        setFieldValue({hasStrokes: false, text: "", path: getDestinationPath()});
        signaturePad.current?.clear();
        if (useText) {
            textInputRef.current?.focus();
        }
    }, [useText, setFieldValue, getDestinationPath]);

    useEffect(() => {
        refresh();
    }, [useText, refresh])

    const onSignatureStroke = () => {
        setFieldValue({
            hasStrokes: true,
            text: "",
            imageAccessor: async () => {
                const devicePixelRatio = window.devicePixelRatio; //hack because otherwise we can get bad viewbox co-ordinates in the SVG
                (window as any).devicePixelRatio = 1;
                const url = signaturePad.current!.toDataURL("image/svg+xml");
                (window as any).devicePixelRatio = devicePixelRatio;
                return {type: "dataURL", url};
            },
            path: getDestinationPath()
        });
        trigger(fieldId);
    }

    const imageAccessorFn: (text: string) => () => Promise<ImageReference> = (text) => () => {
        return new Promise((resolve, reject) => {
            import("text-to-svg").then((TextToSVG) =>
                TextToSVG.default.load('/assets/fonts/Handwriting.ttf', function (err: any, textToSVG: any) {
                    if (err) {
                        reject(err);
                    } else {
                        const svg = generateSvgFromText(textToSVG, text);
                        if (typeof svg === "string") {
                            resolve({type: "svg", svg});
                        } else {
                            reject(svg);
                        }
                    }
                })
            );
        })
    };

    const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        const imageAccessor = imageAccessorFn(text);

        setFieldValue({
            hasStrokes: false,
            text,
            imageAccessor,
            path: getDestinationPath()
        })
    }

    const fillParentRef = useDimensions({
        onResize: ({ width }) => {
            const canvas = signatureCanvas.current;
            if (canvas) {
                const data = signaturePad.current!.toData();
                const ratio = width / canvas.width;
                data.forEach(points => points.forEach(point => {
                    point.x = point.x * ratio;
                    point.y = point.y * ratio;
                }))
                signaturePad.current?.clear();

                canvas.width = width;
                canvas.height = width * signatureRatio;
                signaturePad.current?.fromData(data);
            }
        },
    }).ref as any as RefObject<HTMLDivElement>;

    const measuredRef = useCallback((node: ReactSignatureCanvas) => {
        if (node !== null) {
            signatureCanvas.current = node.getCanvas();
            signaturePad.current = node.getSignaturePad();
        }
    }, []);

    const getTextWidth = (text: string, font: string) => {
        // re-use canvas object for better performance
        var canvas = textWidthCanvas.current!;
        var context = canvas.getContext("2d")!;
        context.font = font;
        var metrics = context.measureText(text);
        return metrics.width;
    }

    const getTextSizeForWidth = (text: string, width: number, minFontPx: number, maxFontPx: number) => {
        const font = "?px Handwriting";
        for (;;) {
            let candidateFont = font.replace("?", maxFontPx.toString(10));
            let currentWidth = getTextWidth(text, candidateFont);
            if (currentWidth <= width) {
                return maxFontPx;
            }

            var g = (minFontPx + maxFontPx) / 2;

            if (Math.round(g) === Math.round(minFontPx) || Math.round(g) === Math.round(maxFontPx)) {
                return g;
            }

            candidateFont = font.replace("?", g.toString(10));
            currentWidth = getTextWidth(text, candidateFont);
            if (currentWidth >= width) {
                maxFontPx = g;
            } else {
                minFontPx = g;
            }
        }

    }

    const editFieldId = "e-" + field.id;

    // @ts-ignore
    return (
        <>
            {props.fieldRenderMode === FieldRenderMode.Edit &&
            <div key="edit" className="w-full">
                <TextField autoFocus
                           {...textInputProps(editFieldId, errors)}
                           id="label"
                           label="Field title"
                           defaultValue={props.field.label}
                           onChange={updateLabel}
                           inputRef={register({required: true})}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={action((event, checked) => { field.config.isRequired = checked; })}
                            checked={field.config.isRequired}
                            color="primary"
                        />
                    }
                    label="Is required?"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={action((event, checked) => { field.config.allowTyping = checked; })}
                            checked={field.config.allowTyping}
                            color="primary"
                        />
                    }
                    label="Allow typed signature?"
                />
            </div>
            }
            {props.fieldRenderMode === FieldRenderMode.Fill &&
            <div key="fill">
                <canvas ref={textWidthCanvas} className="hidden"/>
                <InputLabel ref={labelRef} className="mt-2">{field.label}</InputLabel>
                <div ref={fillParentRef} className="w-full mt-4 relative" style={{paddingBottom: (signatureRatio * 100) + "%"}}>
                    <div className="absolute" style={{top: 0, bottom:0, left:0, right:0 }}>
                        <Controller
                            name={`${fieldId}`}
                            rules={props.fillValidationOpts}
                            defaultValue={[{hasStrokes: false, text: ""}]}
                            onFocus={() => {
                                labelRef.current?.scrollIntoView();
                                if (useText) {
                                    textInputRef.current?.focus();
                                }
                            }}
                        render={() => <></>}/>
                        <div className="relative h-full w-full rounded border-color-gray" style={{border: "1px solid"}}>
                            <div className="absolute" >
                                <SignatureCanvas
                                    ref={measuredRef}
                                    clearOnResize={false}
                                    onEnd={onSignatureStroke}
                                    canvasProps={{style: {cursor: "url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 64 64\" width=\"24\"><g><g><g><polygon points=\"0,52.6 0,64 11.4,64 53.7,21.7 42.3,10.3 			\"/></g></g><g><g><rect x=\"48.4\" y=\"1.8\" transform=\"matrix(0.7071 -0.7071 0.7071 0.7071 8.9388 41.1928)\" width=\"11.7\" height=\"16.1\"/></g></g></g></svg>') 0 44, auto"}}}
                                />
                            </div>
                            {useText &&
                            <div className="absolute w-full h-full">
                                <input
                                    className="w-full h-full px-2"
                                    style={{
                                        fontFamily: "Handwriting",
                                        background: "transparent",
                                        border: "none",
                                        fontSize: getTextSizeForWidth(watchText, (textInputRef.current?.clientWidth || 100) - 20, 30, 80)
                                    }}
                                    ref={textInputRef}
                                    placeholder="Type here"
                                    value={watchText || ""}
                                    onBlur={() => trigger(fieldId)}
                                    onChange={onChangeText}
                                />
                            </div>
                            }
                            <div className="absolute right-0">
                                {field.config.allowTyping &&
                                <>
                                    <IconButton color={useText ? undefined : "primary"} disabled={!useText} onClick={() => setUseText(false)}><DrawIcon
                                        aria-hidden={false} aria-label="Draw Mode"  style={{margin: "4px"}}/></IconButton>
                                    <IconButton color={useText ? "primary" : undefined} disabled={useText}
                                                onClick={() => setUseText(true)}><Icon aria-hidden={false} aria-label="TextMode" >text_fields</Icon></IconButton>
                                </>
                                }
                                <IconButton onClick={refresh}><Icon aria-hidden={false} aria-label="Clear" >delete</Icon></IconButton>
                            </div>
                            <div className="absolute w-full" style={{bottom: 13, borderBottom: "1px dashed"}}/>
                        </div>
                    </div>
                </div>
                {[get(errors, fieldId)].map(error => error && <FormHelperText key={1} error={true}>{errorMessage(error)}</FormHelperText>)}
            </div>
            }
            {props.fieldRenderMode === FieldRenderMode.Display &&
            <div key="display" className="w-full">
                <div className="field-display-name">
                    {field.label}:
                </div>
                <div className="field-display-data">
                    <DisplayImage render={(displayImageUrl: AvailableImageUrl) =>
                        <img src={displayImageUrl.url} style={{height: 50}} alt="Signature"/>
                    }/>
                </div>
            </div>
            }
        </>
    )
});