import React, {Fragment, useEffect} from "react";

import { withRouter } from "react-router-dom";
import {runInAction} from "mobx";
import {observer} from "mobx-react";
import SidenavTheme from "../MatxTheme/SidenavTheme/SidenavTheme";
import {useStores} from "../../stores/context";
import {themes} from "../settings";
import Brand from "../SharedCompoents/Brand";
import Drawer from "@material-ui/core/Drawer";
import {useHistory} from "react-router-dom";
import {MatxVerticalNav} from "../../matx";

const Layout1Sidenav = observer(() => {

  const {uiLayoutStore} = useStores();
  const history = useHistory();

  const handleDrawerToggle = () => {
    runInAction(() => {
      uiLayoutStore.isSideNavOpen = !uiLayoutStore.isSideNavOpen
    });

  };

  useEffect(() => {
    return history.listen((location, action) => {
      runInAction(() => {
        uiLayoutStore.isSideNavOpen = false;
      });
    })
  }, [history, uiLayoutStore.isSideNavOpen]);

  return uiLayoutStore.showSideNav ?
    <SidenavTheme theme={themes["white"]}>
      <nav className="sidenav" aria-label="navigation">
        <div className="lg:hidden">
          <Drawer
              container={window.document.body}
              variant="temporary"
              open={uiLayoutStore.isSideNavOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
          >
            <SidenavTheme theme={themes["white"]}>
            <div className="sidenav">
              <Brand/>
              <div className="px-5">
                <MatxVerticalNav/>
              </div>
            </div>
            </SidenavTheme>
          </Drawer>
        </div>
        <div className="hidden lg:block w-64">
          <Drawer
              variant="permanent"
              open
          >
            <Fragment>
              <Brand/>
              <div className="px-5">
                <MatxVerticalNav/>
              </div>
            </Fragment>
          </Drawer>
        </div>
      </nav>

    </SidenavTheme> : <></>;
});

export default withRouter(Layout1Sidenav);