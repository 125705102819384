import React from "react";
import { FallbackProps } from "react-error-boundary";
import {AuthException} from "shared/auth/userStore";

export const ErrorFallback = (props: FallbackProps) => {
    let displayErrorMessage = true;
    if (props.error instanceof AuthException) {
        window.location.href = "/session/signin";
        displayErrorMessage = false;
    }
    return (
        displayErrorMessage ? <div className="justify-center flex items-center h-full text-center max-w-3xl mx-auto flex-col">
            <div className="text-xl font-bold">It looks like we're having issues</div>
            <div className="text-lg mt-8">Our team has been notified, if you'd like to help then please send any additional information to support@speedywaiver.io</div>
        </div> : <></>
    )
};
