import React, {useCallback, useState} from "react";
import {SimpleCard} from "../../matx";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { useForm, Controller } from "react-hook-form";
import {functionsRoot} from "shared/utils/firebaseFunctions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useStores} from "../../stores/context";

interface Form {
    subject: string;
    message: string;
}

const SupportPage = () => {
    const {userStore} = useStores();

    const {control, handleSubmit, errors} = useForm();

    const [isSending, setIsSending] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const sendMessage = useCallback(async (values: Form) => {
        setIsSending(true);
        const contactUsFn = functionsRoot().httpsCallable('contactUs');
        await contactUsFn({
            subject: values.subject,
            message: `From: ${userStore.user!.email}\n\n${values.message}`
        });
        setIsSent(true);
        setIsSending(false);
    }, [userStore.user]);

    return <div className="ml-4 mr-4"><div className="mt-4 mx-auto layout-contained">
        <SimpleCard title="Support">
            We are here to help with any queries. Simply e-mail us at support@speedywaiver.io, or submit your request:

            <div className="mt-8">
                <Controller
                    name="subject"
                    control={control}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={props =>
                        <TextField {...props}
                                   className="max-w-md w-full"
                                   id="subject"
                                   label="Subject"
                                   variant="outlined"
                                   error={errors.subject?.message !== undefined}
                                   helperText={errors.subject && "Required"}
                        />
                }/>
            </div>
            <div className="mt-4">
                <Controller
                    name="message"
                    control={control}
                    defaultValue={""}
                    rules={{ required: true }}
                    render={props =>
                        <TextField {...props}
                                   className="max-w-md w-full"
                                   id="message"
                                   label="Message"
                                   variant="outlined"
                                   multiline={true}
                                   rows={8}
                                   error={errors.message?.message !== undefined}
                                   helperText={errors.message && "Required"}

                        />
                    }/>
            </div>
            <div className="mt-4 mb-4">
                <Button onClick={handleSubmit(sendMessage)} disabled={isSending || isSent} color="secondary" variant="contained">
                    <Icon>send</Icon>
                    <span className="pl-2 capitalize">{isSent ? "Sent" : "Send"}</span>
                    {isSending &&
                        <CircularProgress className="ml-2" size={20}/>
                    }
                </Button>
            </div>


        </SimpleCard>
        </div></div>;

};


export default SupportPage;