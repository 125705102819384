import * as React from "react";
import {FormEvent, ReactNode, useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import cx from "classnames";
import { UseFormMethods } from "react-hook-form";
import {useIsLg} from "../../hooks/useWindowSize";
import {useIsTouch} from "../../hooks/screen";

interface EditCardProps {
    title: string;
    editForm?: ReactNode;
    view: ReactNode;
    openByDefault?: boolean;
    setIsEditMode?: (isEditMode: boolean) => void;
    onSave?: () => Promise<boolean>;
    onEdit?: () => void;
    hasErrors?: boolean;
    hookContext?: UseFormMethods<any>;
}

interface ChildrenProps {
    children: ReactNode;
}

const EditCard = (props: EditCardProps) => {
    const setParentIsEditMode = props.setIsEditMode;
    const [isHover, setIsHover] = useState(false);
    const [isEditMode, setIsEditMode] = useState(!!props.openByDefault);
    const isLg = useIsLg();
    const isTouchBrowser = useIsTouch();

    const handleMouseEnter = () => {
        if (!isTouchBrowser) {
            setIsHover(true);
        }
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };

    useEffect(() => {
        if (setParentIsEditMode) {
            setParentIsEditMode(isEditMode);
        }
    }, [isEditMode, setParentIsEditMode]);

    const startEditMode = () => {
        if (props.hookContext) {
            props.hookContext.reset();
        }
        setIsEditMode(true);
        if (props.onEdit) {
            props.onEdit();
        }
    };

    const handleDiscard = () => {
        setIsEditMode(false);
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (props.hookContext) {
            if (await new Promise<boolean>(res => {
                props.hookContext!.handleSubmit(() => {res(true);}, () => {res(false);})();
            })) {
                if (!props.onSave || (await props.onSave())) {
                    setIsEditMode(false);
                }
            }
        } else {
            if (!props.onSave || (await props.onSave())) {
                setIsEditMode(false);
            }
        }
    };

    const handleDoubleClick = () => {
        if (showEditButton && !isEditMode) {
            startEditMode();
        }
    };

    const showEditButton = props.editForm !== undefined && !isEditMode;

    const showHoverBackground = isHover && !isEditMode && props.editForm !== undefined;

    const hasErrors = props.hookContext && Object.keys(props.hookContext.errors).length > 0;
    const isDirty = props.hookContext && props.hookContext.formState.isDirty;

    const popupStyle = isEditMode ? {
      boxShadow: "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2)",
      marginLeft: isLg ? "-36px" : "-20px",
      marginRight: isLg ? "-36px" : "-20px",
      marginBottom: "0px",
      width: `calc(100% + ${isLg ? "72px" : "40px"})`
    } : {};

    return (
        <div className="h-full" onDoubleClick={handleDoubleClick}>
            <form onSubmit={handleSubmit}>
                <div style={{marginLeft: isLg ? "-24px" : "-12px", marginRight: isLg ? "-24px" : "-12px", transition: "all 0.25s ease", ...popupStyle}}
                     className={cx("flex", "mt-3", "pt-6", "flex-wrap", "relative", {"bg-white": !showHoverBackground, "bg-light-gray": showHoverBackground})}
                     onMouseOver={handleMouseEnter}
                     onMouseLeave={handleMouseLeave}
                >
                    {showEditButton && <IconButton style={{position: "absolute", top: "12px", right: "6px"}} onClick={startEditMode}><Icon aria-hidden={false} aria-label="Edit">edit</Icon></IconButton>}
                    <div className={`w-full ${isLg ? "px-6" : "px-3"} pb-4 md:w-1/3`}>
                        <span className="font-semibold">{props.title}</span>
                    </div>
                    <div className={`w-full ${isLg ? "px-6" : "px-3"} md:w-2/3`}>
                        {isEditMode ? props.editForm : props.view}
                    </div>
                    {isEditMode &&
                        <div className={cx("w-full flex justify-end p-3",{"bg-light-gray": isDirty})} style={{borderTop: "1px solid lightgray"}}>
                            {props.onSave ?
                                <>
                                    <Button type="button" onClick={handleDiscard}>CANCEL</Button>
                                    <Button type="submit" color="primary" className="font-bold" style={{color: hasErrors ? "rgba(0, 0, 0, 0.26)" : undefined}}>SAVE</Button>
                                </> :
                                <>
                                    <Button className="font-bold" onClick={handleDiscard}>DONE</Button>
                                </>
                            }

                        </div>
                    }
                </div>
            </form>
        </div>
    )
};

const EditCardFieldTitle = (props: ChildrenProps) => {
    return (<div className="text-muted" style={{marginBottom: "1px"}}>
        {props.children}
    </div>);
};

const EditCardFieldData = (props: ChildrenProps) => {
    return (<div className="pb-4">
        {props.children}
    </div>);
};

export { EditCard, EditCardFieldTitle, EditCardFieldData};
