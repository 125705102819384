import {
    AddressHelpers,
    DropdownFieldData,
    FieldData, FieldInstance,
    FieldType, ImageFieldData,
    NameHelpers,
    RadioFieldData,
    SignatureFieldData
} from "../forms/model";

import {getFirebase} from "firestorter";
import {ImageHookData, SignatureHookData} from "./submissionEditStore";
import {notEmpty} from "../utils/tsUtils";
const Timestamp = getFirebase().firestore.Timestamp;

export function imagePath(orgId: string, submissionId: string | undefined) {
    return `/submissions/images/${orgId}/${submissionId}/$RANDOM$`;
}

export function signaturePath(orgId: string, submissionId: string) {
    return `/submissions/signatures/${orgId}/${submissionId}/$RANDOM$.svg`;
}

export const formDataToSubmissionData = (orgId: string, fieldInstances: FieldInstance[], formData: any, submissionId: string) => {
    const submissionData: {[fieldId: string]: FieldData} = {};
    
    fieldInstances.map((field) => {
        if (field.type === FieldType.Email ||
            field.type === FieldType.Checkbox ||
            field.type === FieldType.TextInput ||
            field.type === FieldType.Phone ||
            field.type === FieldType.Number ||
            field.type === FieldType.TextBlockInput
        ) {
            if (formData[field.id] !== undefined) {
                submissionData[field.id] = {type: field.type, value: formData[field.id]} as any;
            }
        } else if ((field.type === FieldType.Date ||
            field.type === FieldType.Dob) &&
            formData[field.id]) {
            submissionData[field.id] = {type: field.type, value: Timestamp.fromDate(formData[field.id])};
        } else if (field.type === FieldType.Name) {
            submissionData[field.id] = {
                type: field.type,
                firstName: formData[NameHelpers.firstNameFieldId(field.id)] || "",
                lastName: formData[NameHelpers.lastNameFieldId(field.id)] || ""
            };
        } else if ((field.type === FieldType.Dropdown || field.type === FieldType.Radio) && formData[field.id]) {
            const lookupKey = (key: string) => [...field.config.options, {id: "other", text: "Other"}]
                .find((option) => option.id === key)?.text;

            //if multiple selection allowed
            if (field.type === FieldType.Radio && typeof formData[field.id] === 'object') {
                //get the list of keys from field.config.options for each selected radio
                const values = Object.keys(formData[field.id])
                    .filter((k) => formData[field.id][k])
                    .map((k) => lookupKey(k) || undefined)
                    .filter(notEmpty);
                if (values.length > 0) submissionData[field.id] = {type: field.type, value: values};

            } else {
                const value = lookupKey(formData[field.id]);
                if (value) submissionData[field.id] = {type: field.type, value} as any;
            }
            if (formData[`${field.id}-othertext`]) {
                (submissionData[field.id] as RadioFieldData | DropdownFieldData).other = formData[`${field.id}-othertext`];
            }
        } else if (field.type === FieldType.Signature && formData[field.id]) {
            submissionData[field.id] = {
                type: field.type
            }
            const signatureHookData = formData[field.id] as SignatureHookData;
            if (formData[field.id] && (signatureHookData.hasStrokes || signatureHookData.text)) {
                (submissionData[field.id] as SignatureFieldData).path = signatureHookData.path;
                if (signatureHookData.text) {
                    (submissionData[field.id] as SignatureFieldData).text = signatureHookData.text;
                }
            }
        } else if (field.type === FieldType.Image && formData[field.id]) {
            submissionData[field.id] = {
                type: field.type
            }
            const imageHookData = formData[field.id] as ImageHookData;
            if (imageHookData.paths && imageHookData.paths.length > 0) {
                (submissionData[field.id] as ImageFieldData).path = imageHookData.paths[0];
            }
        } else if (field.type === FieldType.Address) {
            const dataObj = {
                type: field.type,
                address1: formData[AddressHelpers.address1FieldId(field.id)],
                address2: formData[AddressHelpers.address2FieldId(field.id)],
                city: formData[AddressHelpers.cityFieldId(field.id)],
                state: formData[AddressHelpers.stateFieldId(field.id)],
                country: formData[AddressHelpers.countryFieldId(field.id)],
                zipCode: formData[AddressHelpers.zipCodeFieldId(field.id)]
            };
            Object.keys(dataObj).forEach(key => (dataObj as any)[key] === undefined && delete (dataObj as any)[key]);
            if (Object.keys(dataObj).length > 0) {
                submissionData[field.id] = dataObj;
            }
        } else if (field.type === FieldType.RepeatedGroup) {
            const fieldData = {
                type: field.type,
                value: [] as Array<{[fieldId: string]: FieldData}>
            };
            if (formData[field.id]) {
                formData[field.id].map((data: any) => {
                    fieldData.value.push(formDataToSubmissionData(orgId, field.config.fieldInstances, data, submissionId));
                });
            }
            submissionData[field.id] = fieldData;
        }
    });
    return submissionData;
}