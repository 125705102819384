import * as React from "react";
import {FieldRenderMode, FieldType} from "shared/forms/model";
import ReactHtmlParser from "react-html-parser";
import {runInAction} from "mobx";
import {FieldProps} from "./field";
import { ThemeProvider } from "@material-ui/core/styles";
const QuillEditor = React.lazy(() => import('./QuillEditor'))

export default (props: FieldProps<FieldType.Text | FieldType.Heading>) => {
    const {fieldRenderMode} = props;
    const config = props.field.config;

    const updateText = (content: string) => {
        runInAction(() => {
            props.field.isClean = false;
            config.text = content;
        });
    };

    return (<div style={{"--quill-font-size": `${props.fieldStyle.fontSize}px`} as any}>
        {fieldRenderMode === FieldRenderMode.Edit &&
        <ThemeProvider theme={props.fieldStyle.theme}>
            <QuillEditor onChange={updateText} value={config.text} placeholder={"Type here"}/>
            <div className="text-xs mt-2">
                TIP: Use the image icon in the toolbar to drop images into your form and make it more attractive.
            </div>
        </ThemeProvider>
        }
        {(fieldRenderMode === FieldRenderMode.Display || fieldRenderMode === FieldRenderMode.Fill) &&
        <div className="ql-editor p-0">{ReactHtmlParser(config.text)}</div>
        }
        </div>
    )
}