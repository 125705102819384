const textLight = {
  primary: "rgba(52, 49, 76, 1)",
  secondary: "rgba(52, 49, 76, 0.70)",
  disabled: "rgba(52, 49, 76, 0.38)",
  hint: "rgba(52, 49, 76, 0.38)"
};
const secondaryColor = {
  light: "#f9a352",
  main: "#ff9e43",
  dark: "#ff932e",
  contrastText: textLight.primary
};

export const themeColors = {
  white: {
    palette: {
      mode: "light",
      primary: {
        main: "#ffffff",
        contrastText: textLight.primary
      },
      secondary: {
        main: "#7467ef",
        contrastText: textLight.primary
      },
      text: textLight
    }
  },
  purple1: {
    palette: {
      mode: "light",
      primary: {
        main: "#7467ef",
        contrastText: "#ffffff"
      },
      secondary: secondaryColor,
      text: textLight
    }
  },
  purpleDark1: {
    palette: {
      mode: "dark",
      primary: {
        main: "#7467ef",
        contrastText: "#ffffff"
      },
      secondary: {
        main: "#ff9e43",
        contrastText: textLight.primary
      },
      background: {
        paper: "#222A45",
        default: "#1a2038"
      }
    }
  },

};
