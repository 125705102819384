import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import MatxCssVars from "./MatxCssVars";
import {themes} from "../settings";

// import cssVars from "css-vars-ponyfill";

const MatxTheme = ({ children }) => {
  let activeTheme = { ...themes["purple1"] };
  // console.log(activeTheme);
  // cssVars();
  // activeTheme.direction = settings.direction;
  return (
    <ThemeProvider theme={activeTheme}>
      <CssBaseline />
      <MatxCssVars> {children} </MatxCssVars>
    </ThemeProvider>
  );
};

export default MatxTheme;
