import {Submission, submissionsEntity} from "./model";
import {ListStore} from "../common/listStore";
import {UserStore} from "../auth/userStore";
import PagedCollection from "../common/pagedCollection";
type CollectionReference = firebase.firestore.CollectionReference;
import {reaction, observable, action, toJS} from "mobx";
import {normalizeForIndexing} from "../utils/stringUtils";
import { subDays, startOfToday } from "date-fns";
import {allFormsId} from "../forms/model";
import {allGroupsId} from "../groups/model";
import PubSub from "pubsub-js";
import {PubSubTopics} from "../common/pubsub";
import {isWeb} from "../utils/utils";

export class SubmissionListStore extends ListStore<Submission> {

    constructor(userStore: UserStore) {
        super(userStore, submissionsEntity, 20);

        reaction(() => userStore.isSignedIn, (isSignedIn) => {
            if (!isSignedIn && this.collection) {
                this.collection.disconnectQuery();
                this.collection = undefined as any;
            }
        }, {name: "SubmissionListStore-SignedIn"})

        PubSub.subscribe(PubSubTopics.ResetSubmissionRoutes, () => {
            this.routeFilterFormId = undefined;
            this.routeFilterGroupId = undefined;
        });
    }

    filterDaysOptions = [
        {title: "Today", days: 0},
        { title: 'Yesterday', days: 1 },
        { title: 'Last 7 days', days: 7 },
        { title: 'Last month', days: 31 },
        { title: 'Last 3 months', days: 92 },
        { title: 'Last 6 months', days: 184 },
        { title: 'All', days: 99999 }
    ];

    @observable currentSearchString = "";

    @observable currentFilterDays = isWeb ? 7 : 99999;
    @observable currentFilterDaysText: string | undefined = isWeb ? "Last 7 days" : "All";

    @observable currentFilterFormId = allFormsId;
    @observable currentFilterGroupId = allGroupsId;

    //form and group ids that were provided to initial ensureInitialized
    routeFilterFormId: string | undefined;
    routeFilterGroupId: string | undefined;

    private basicQuery = (filterDays: number, filterFormId: string, filterGroupId: string) => (r: CollectionReference) => {
        let query = r
            .where('isDeleted', '==', false)
            .where("orgId", "==", this.userStore.userData!.orgId)
            .where("clientCreatedAt", ">=", subDays(startOfToday(), filterDays));

        if (filterFormId !== allFormsId) {
            query = query.where("formId", "==", filterFormId);
        }
        if (filterGroupId !== allGroupsId) {
            query = query.where("groupId", "==", filterGroupId);
        }
        return query;
    }

    ensureInitialized(newRouteFilterFormId: string | undefined, newRouteFilterGroupId: string | undefined): void {
        if (newRouteFilterFormId !== this.routeFilterFormId) {
            this.handleFilterForm(newRouteFilterFormId || allFormsId);
            this.routeFilterFormId = newRouteFilterFormId;
        }
        if (newRouteFilterGroupId !== this.routeFilterGroupId) {
            this.handleFilterGroup(newRouteFilterGroupId || allGroupsId);
            this.routeFilterGroupId = newRouteFilterGroupId;
        }
        if (!this.collection) {
            this.collection = new PagedCollection<Submission>(this.entityType, {pageSize: this.pageSize, query: this.basicQuery(toJS(this.currentFilterDays), toJS(this.currentFilterFormId), toJS(this.currentFilterGroupId)), order: "clientCreatedAt", directionStr: "desc"});
            reaction(() => `${this.currentSearchString}|${this.currentFilterDays}|${this.currentFilterFormId}|${this.currentFilterGroupId}`, () => {
                const currentFilterDays = toJS(this.currentFilterDays);
                const currentFilterFormId = toJS(this.currentFilterFormId);
                const currentFilterGroupId = toJS(this.currentFilterGroupId);
                if (this.currentSearchString === "") {
                    this.collection.updateQuery({query: this.basicQuery(currentFilterDays, currentFilterFormId, currentFilterGroupId), pageSize: this.pageSize });
                } else {
                    const normalizedStr = normalizeForIndexing(this.currentSearchString);
                    this.collection.updateQuery({query: (r: CollectionReference) => this.basicQuery(currentFilterDays, currentFilterFormId, currentFilterGroupId)(r).where('keywords', 'array-contains', normalizedStr), pageSize: this.pageSize});
                }
            }, {delay: 250});
        }
    }

    @action
    handleFilterDays = (days: number) => {
        this.currentFilterDays = days;
        this.currentFilterDaysText = this.filterDaysOptions.find(option => option.days === days)?.title;
    }

    @action
    handleFilterForm = (id: string) => {
        this.currentFilterFormId = id;
    }

    @action
    handleFilterGroup = (id: string) => {
        this.currentFilterGroupId = id;
    }

    @action
    handleSearch = (str: string) => {
        this.currentSearchString = str;
    };
}


