import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import cx from "classnames";

const useStyles = makeStyles(theme => ({
  loading: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: "auto",
    height: "100%",
    width: "40px",
    "& div": {
      top: 0,
      bottom: 0,
      position: "absolute",
      margin: "auto"
    },
    "& img": {
      position: "absolute",
      height: "25px",
      width: "auto",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto"
    }
  }
}));

interface Props {
  errorState?: any;
  onDark?: boolean;
  verticalSpace?: boolean;
  text?: string;
}

const Loading = (props: Props) => {
  const classes = useStyles();

  return (
    <div className={cx({"relative h-20 my-8": props.verticalSpace})}>
      <div className={classes.loading}>

      {props.errorState ?
          <div>An error occurred</div> :
          <>
          <img src={props.onDark ? "/assets/images/logo-circle.png" : "/assets/images/logo-circle-on-white.png"} alt="" />
            <CircularProgress />

          </>
      }
    </div>
      {props.text &&
      <div className="left-0 top-0 bottom-0 right-0 h-0 text-center my-auto absolute pt-16">{props.text}</div>}
    </div>
  );
};

export default Loading;
