import {EditStore} from "../common/editStore";
import {PathReader, Submission, SubmissionDoc, submissionsEntity, userSubmissionId} from "./model";
import {getFirebase} from "firestorter";
import {
    DobFieldData,
    EmailFieldData, FieldData,
    FieldInstance,
    FieldType,
    Form,
    ImageFieldData, NameFieldData, RepeatedGroupData
} from "../forms/model";
import {action, observable, runInAction} from "mobx";
import {UserStore} from "../auth/userStore";
import {Context} from "../common/context";
import {ImageReference} from "./imageHelpers";
import {notEmpty} from "../utils/tsUtils";
import {isWeb} from "../utils/utils";
import {functionsRoot} from "shared/utils/firebaseFunctions";
import PubSub from 'pubsub-js';
import {PubSubTopics} from "../common/pubsub";
import {formDataToSubmissionData} from "./formDataToSubmissionData";

const Timestamp = getFirebase().firestore.Timestamp;

interface ImageWithDestination {
    imageReference: ImageReference;
    destination: string;
}

export class SubmissionEditStore extends EditStore<Submission, SubmissionDoc> {

    @observable ackText = "";

    constructor(context: Context, userStore: UserStore, timeZone: string, id: string | undefined, formId?: string) {
        super(context, userStore, submissionsEntity, timeZone, id, formId ? SubmissionEditStore.defaultEntity(formId) : undefined)
        this.log = context.logger(this);
    }

    @action
    updateEntityUsingFormData(fieldInstances: FieldInstance[], data: any, orgId: string) {
        this.entity.data = formDataToSubmissionData(orgId, fieldInstances, data, this.id!);

        const nameFieldId = fieldInstances.find((field) => field.type === FieldType.Name)?.id;
        if (nameFieldId) {
            const data = this.entity.data[nameFieldId] as NameFieldData | undefined;
            this.entity.firstName = data?.firstName || "";
            this.entity.lastName = data?.lastName;
        }

        const emailFieldId = fieldInstances.find((field) => field.type === FieldType.Email)?.id;
        if (emailFieldId) {
            const data = this.entity.data[emailFieldId] as EmailFieldData | undefined;
            if (data?.value) {
                this.entity.email = data?.value;
            }
        }

        const dobFieldId = fieldInstances.find((field) => field.type === FieldType.Dob)?.id;
        if (dobFieldId) {
            const data = this.entity.data[dobFieldId] as DobFieldData | undefined;
            if (data?.value) {
                this.entity.dob = data?.value;
            }
        }
    }

    async save() {
        this.log.info(`Saving submission ${this.id}`);
        if (this.isNew) {
            runInAction(() => {
                this.entity.clientCreatedAt = Timestamp.fromDate(new Date());
                this.entity.clientCreatedAtTz = this.timeZone;
            });
        }

        await super.save();
        PubSub.publish(PubSubTopics.CreateSubmission, undefined);
        runInAction(() => {
            this.ackText = `Thanks for your submission. Your reference id is: ${userSubmissionId(this.id!)}`;
        })
    }

    static defaultEntity(formId: string): Submission {
        return {
            formId,
            formConfigId: undefined!,
            orgId: undefined!,
            groupId: null,
            formName: "",
            data: {},
            createdAt: undefined as any,
            clientCreatedAt: undefined as any,
            clientCreatedAtTz: undefined as any,
            updatedAt: undefined as any
        };
    }

    @action
    updateFormData(form: Form) {
        this.entity.formName = form.name;
        this.entity.formConfigId = form.currentFormConfigId!;
        this.entity.orgId = form.orgId;
    }

    @action
    updateGroupId(groupId: string | undefined | null) {
        if (groupId) {
            this.entity.groupId = groupId;
        }
    }

    pdfFileName = () => {
        const name = [this.entity.firstName, this.entity.lastName].filter(notEmpty).join(" ");
        return `${this.entity.formName}${name ? " - " + name : ""}.pdf`;
    }
    
    imagesToSave = async (fieldInstances: FieldInstance[], data: any, submissionData: {[fieldId: string]: FieldData}): Promise<Array<ImageWithDestination>> => {
        const list = await Promise.all(fieldInstances.map(async (field: FieldInstance) => {
            if (field.type === FieldType.Signature) {
                const formData = data[field.id] as SignatureHookData;

                if (formData.imageAccessor && formData.path) {
                    const imageReference = await formData.imageAccessor();
                    return {imageReference, destination: formData.path};
                }
            } else if (field.type === FieldType.Image && !isWeb) {
                const formData = data[field.id] as ImageHookData;
                if (formData.localPaths && formData.localPaths.length > 0) {
                    const imageReference: ImageReference = {type: "path", path: formData.localPaths[0]};

                    const destination = (submissionData[field.id] as ImageFieldData)?.path;
                    if (!destination) {
                        this.log.error(`No destination for ${field.id}`)
                    } else {
                        return {imageReference, destination};
                    }
                }
            } else if (field.type === FieldType.RepeatedGroup) {
                if (data[field.id]) {
                    const images: Promise<ImageWithDestination[]>[] = data[field.id].map((groupData: any, i: number) =>
                        this.imagesToSave(field.config.fieldInstances, groupData, (submissionData[field.id] as RepeatedGroupData)?.value[i] || {})
                    );
                    return Promise.all(images).then(imageArray => imageArray.flat());
                }
            }
            return undefined;
        }));
        return list.flat().filter(notEmpty);
    }

    async renderPdf(fieldInstances: FieldInstance[], pathReader: PathReader) {
        const renderPdfLib = await import("./renderPdf");

        try {
            const updatedDoc = await this.document.fetch();
            runInAction(() => {
                this.entity = this.doc2Entity(updatedDoc.data)
            });
        } catch(e) {
            this.log.info("Could not refresh doc - may be offline or not be logged in");
        }

        return await renderPdfLib.renderPdf(fieldInstances, this.entity2Doc(this.entity), this.id, pathReader, this.log);
    }

    async sendEmail(destinationEmailAddress: string) {
        const sendEmailFn = functionsRoot().httpsCallable('emailSubmissionPdf');
        await sendEmailFn({submissionId: this.id, destinationEmailAddress});
    }
}

export interface SignatureHookData {
    hasStrokes: boolean;
    text: string;
    imageAccessor?: () => Promise<ImageReference>;
    path: string;
}

export interface ImageHookData {
    isUploading: boolean;
    paths: string[]; //eg /submissions/images/...
    localPaths?: string[]; //mobile-only
}