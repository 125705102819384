import {computed, observable, runInAction, reaction} from "mobx";
import {Document, Mode} from "firestorter";
import {orgsEntity, OrgDoc} from "./model";
import {NotFoundException} from "../common/editStore";
import { isFuture, set, isAfter, isEqual, parse } from "date-fns";
import {PaddlePlan, paddlePlansObj, Plan, PlanChannel, plans, SubscriptionEvent, topUpsObj} from "./billing";
import {Context, Logger} from "../common/context";
import {functionsRoot} from "../utils/firebaseFunctions";
import {FormListStore} from "../forms/formListStore";
import {UserStore} from "../auth/userStore";
import { subMonths } from "date-fns";
import {RootStore} from "../common/rootStore";
import {withTimeoutUsingCancelToken} from "../utils/promiseUtils";
import {startOfTodayUTC} from "../utils/dateUtils";

export class BillingStore {

    @observable document?: Document<OrgDoc> = undefined;

    private log: Logger;
    private publishedFormListStore: FormListStore;
    private userStore: UserStore;
    private isInitialized = false;
    private isBillingEnabled: boolean;

    constructor(context: Context, rootStore: RootStore) {
        this.log = context.logger(this);
        this.isBillingEnabled = true;
        this.publishedFormListStore = rootStore.publishedFormListStore;
        this.userStore = rootStore.userStore;
    }

    ensureInitialized() {
        if (!this.isInitialized) {
            this.isInitialized = true;
            this.publishedFormListStore.ensureInitialized();

            reaction(() => this.userStore.userData, (userData) => {
                if (userData && this.isBillingEnabled) {
                    const document = new Document<OrgDoc>(`${orgsEntity}/${userData.orgId}`);
                    withTimeoutUsingCancelToken(this.log, () => document.fetch().then(() => {
                        if (!document.hasData) {
                            throw new NotFoundException();
                        }
                        runInAction(() => {
                            this.document = document;
                        });
                    }), 30000, "BillingStore", () => {
                        this.isInitialized = false;
                        this.ensureInitialized();
                    }).then();
                } else {
                    if (this.document) {
                        this.document.mode = Mode.Off;
                    }
                    runInAction(() => {
                        this.document = undefined;
                    });
                    this.isInitialized = false;
                }
            }, {fireImmediately: true});
        }
    }

    updateSubscription = async (plan: PaddlePlan) => {
        this.log.info(`Updating subscription to ${plan.paddleId}`);
        const updateSubscription = functionsRoot().httpsCallable('updateSubscription');
        const response = await updateSubscription({planId: plan.paddleId});
        this.log.info("Update subscription response", response);
    }

    @computed get isLoading(): boolean {
        return this.document === undefined || this.publishedFormListStore.isLoading;
    }

    @computed get currentPlan(): Plan | undefined {
        const currentPlanId = this.document?.data.currentPlanId;
        return currentPlanId && currentPlanId !== "" ? plans[currentPlanId] : undefined;
    }

    @computed get currentPaddlePlan(): PaddlePlan | undefined {
        if (this.planChannel === "paddle") {
            const currentPlanId = this.document?.data.currentPlanId;
            return currentPlanId && currentPlanId !== "" ? paddlePlansObj[currentPlanId] : undefined;
        } else {
            return undefined;
        }
    }

    get planChannel(): PlanChannel {
        return this.document?.data.planChannel || "";
    }

    @computed get lastHistoryUpdate(): SubscriptionEvent | undefined {
        const subscriptionHistory = this.document?.data.subscriptionHistory;
        if (subscriptionHistory && subscriptionHistory.length > 0) {
            return subscriptionHistory[subscriptionHistory.length - 1];
        } else {
            return undefined;
        }
    }

    @computed get hasActivePaddlePlan(): boolean {
        return this.lastHistoryUpdate?.status === "active";
    }

    @computed get hasPaddleTrial(): boolean {
        return this.lastHistoryUpdate?.status === "trialing";
    }

    @computed get shouldDisplayEndOfTrialMessage(): boolean {
        if (this.document) {
            return !this.isTrialMonth && !this.document.data.displayedEndOfTrialMessage;
        } else {
            return false;
        }
    }

    @computed get shouldDisplayStartOfTrialMessage(): boolean {
        if (this.document) {
            return this.isTrialMonth && !this.document.data.displayedStartOfTrialMessage;
        } else {
            return false;
        }
    }

    @computed get isTrialMonth(): boolean {
        if (this.document?.data) {
            return this.document.data.createdAt.toMillis() > subMonths(new Date(), 1).getTime();
        } else {
            return false;
        }
    }

    @computed get cancelSubscriptionLink(): string | undefined {
        return this.lastHistoryUpdate?.cancel_url;
    }

    @computed get updateSubscriptionLink(): string | undefined {
        return this.lastHistoryUpdate?.update_url;
    }

    getLastPlanReset(): Date {
        const resetTime = this.document?.data?.planResetTime;
        if (resetTime) {
            let currentReset = set(startOfTodayUTC(), {date: resetTime.toDate().getDate()});
            if (isFuture(currentReset)) {
                currentReset = subMonths(currentReset, 1);
            }
            return currentReset;
        } else {
            return subMonths(startOfTodayUTC(), 1);
        }
    }

    @computed get currentMonthSubmissionCount(): number {
        const lastResetDate = this.getLastPlanReset();

        const data = this.document?.data;
        if (data) {
            let counter = 0;
            for (const key of Object.keys(data)) {
                const dateMatch = key.match(/subCounter_(\d\d\d\d\d\d\d\d)/);
                if (dateMatch) {
                    const subDate = parse(`${dateMatch[1]}000000Z`, "yyyyMMddHHmmssX", new Date());
                    if (isEqual(subDate, lastResetDate) || isAfter(subDate, lastResetDate)) {
                        counter = counter + (data as any)[key];
                    }
                }
            }
            return counter;
        } else {
            return 0;
        }
    }

    @computed get maxSubmissionsPerMonth(): number | undefined {
        const lastResetDate = this.getLastPlanReset();

        let thisMonthIapSubmissions = 0;
        if (this.document && this.currentPlan) {

            for (let i = 0; i < (this.document.data.iapHistory || []).length; i++) {
                const iap = this.document.data.iapHistory[i];
                const topUpPlan = topUpsObj[iap.productId];

                if (topUpPlan !== undefined && iap.timestamp && isAfter(iap.timestamp.toDate(), lastResetDate)) {
                    thisMonthIapSubmissions = thisMonthIapSubmissions + topUpPlan.submissions;
                }
            }
            return this.currentPlan?.maxSubmissionsPerMonth + thisMonthIapSubmissions;
        } else {
            return undefined;
        }
    }

    get maxForms(): number | undefined {
        return this.currentPlan?.maxForms;
    }

    @computed get currentFormCount(): number {
        if (this.publishedFormListStore.isLoaded) {
            return this.publishedFormListStore.totalCount;
        } else {
            return 0;
        }
    }

    aboutToReachFormAllowance(): boolean {
        return !this.isTrialMonth && this.currentPlan !== undefined && this.currentFormCount >= this.currentPlan.maxForms - 1;
    }

    async updateDisplayedStartOfTrial() {
        if (this.document) {
            await this.document.update({displayedStartOfTrialMessage: true});
        }
    }

    async updateDisplayedEndOfTrial() {
        if (this.document) {
            await this.document.update({displayedEndOfTrialMessage: true});
        }
    }
}