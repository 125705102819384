import React from "react";
import {FieldData, FieldInstance, FieldRenderMode, FieldType} from "shared/forms/model";
import TextDisplayField from "../forms/editFields/TextDisplayField";
import TextInputField from "../forms/editFields/TextInputField";
import CheckboxField from "../forms/editFields/CheckboxField";
import DateField from "../forms/editFields/DateField";
import NameField from "../forms/editFields/NameField";
import RadioField from "../forms/editFields/RadioField";
import SignatureField from "../forms/editFields/SignatureField";
import AddressField from "../forms/editFields/AddressField";
import {createFormTheme} from "../forms/editFields/field";
import {useValidationOpts} from "shared/forms/validationOpts";
import { useMemo } from "use-memo-one";
import {useIsSmallScreen} from "../../hooks/screen";
import useTheme from "@material-ui/core/styles/useTheme";
import { useFormContext } from "react-hook-form";
import { ThemeProvider } from "@material-ui/core/styles";
import {RepeatedGroupField} from "../forms/editFields/RepeatedGroupField";

const ImageField = React.lazy(() => import("../forms/editFields/ImageField"));

interface IProps {
    fieldInstances: FieldInstance[];
    isInsideGroup: boolean;
    fieldPath?: string;
    submissionId?: string;
    data?: {[fieldId: string]: FieldData};
    orgId: string;
}

export const FillSubmissionFields = (props: IProps) => {
    const {fieldInstances} = props;
    const theme = useTheme();
    const isSmallScreenBrowser = useIsSmallScreen();
    const { getValues } = useFormContext();

    const fieldRenderMode = FieldRenderMode.Fill;

    const fieldStyle = useMemo(() => ({
        fontSize: isSmallScreenBrowser ? 18 : 20,
        theme: createFormTheme(theme, isSmallScreenBrowser ? 18 : 20)
    }), [theme, isSmallScreenBrowser]);

    const getValidationOpts = useValidationOpts();

    const renderField = (
        field: FieldInstance,
        nextFieldType: FieldType | undefined,
        nodeType: React.FunctionComponent<any>
    ) => {
        const fieldId = `${props.fieldPath || ""}${field.id}`;
        return React.createElement(nodeType, {
            field,
            fieldId,
            fieldRenderMode,
            fillValidationOpts: getValidationOpts(field, fieldId, getValues),
            data: props.data,
            fieldStyle,
            submissionId: props.submissionId,
            nextFieldType: nextFieldType,
            isInsideGroup: props.isInsideGroup,
            orgId: props.orgId
        });
    }


    return <ThemeProvider theme={fieldStyle.theme}>
    {fieldInstances.map((field, i) => {
        const nextFieldInstance = (i < fieldInstances.length - 1) ?
            fieldInstances[i+1] :
            undefined;
        return (<div className="pb-6" key={field.id}>
            {field.type === FieldType.Text && renderField(field, nextFieldInstance?.type, TextDisplayField)}
            {field.type === FieldType.Heading && renderField(field, nextFieldInstance?.type, TextDisplayField)}
            {field.type === FieldType.Email && renderField(field, nextFieldInstance?.type, TextInputField)}
            {field.type === FieldType.TextInput && renderField(field, nextFieldInstance?.type, TextInputField)}
            {field.type === FieldType.Number && renderField(field, nextFieldInstance?.type, TextInputField)}
            {field.type === FieldType.TextBlockInput && renderField(field, nextFieldInstance?.type, TextInputField)}
            {field.type === FieldType.Checkbox && renderField(field, nextFieldInstance?.type, CheckboxField)}
            {field.type === FieldType.Date && renderField(field, nextFieldInstance?.type, DateField)}
            {field.type === FieldType.Dob && renderField(field, nextFieldInstance?.type, DateField)}
            {field.type === FieldType.Name && renderField(field, nextFieldInstance?.type, NameField)}
            {field.type === FieldType.Phone && renderField(field, nextFieldInstance?.type, TextInputField)}
            {field.type === FieldType.Radio && renderField(field, nextFieldInstance?.type, RadioField)}
            {field.type === FieldType.Dropdown && renderField(field, nextFieldInstance?.type, RadioField)}
            {field.type === FieldType.Signature && renderField(field, nextFieldInstance?.type, SignatureField)}
            {field.type === FieldType.Image && renderField(field, nextFieldInstance?.type, ImageField)}
            {field.type === FieldType.Address && renderField(field, nextFieldInstance?.type, AddressField)}
            {field.type === FieldType.RepeatedGroup && renderField(field, nextFieldInstance?.type, RepeatedGroupField)}
        </div>);
    })}</ThemeProvider>;
}