import {PaddlePlan, paddlePlans} from "shared/users/billing";
import React from "react";
import {observer} from "mobx-react";
import Button from "@material-ui/core/Button";

interface PriceModulesProps {
    showActive: boolean;
    activePlan: PaddlePlan | undefined;
    handleSignUp?: (plan: PaddlePlan) => void;
}

interface PriceModuleProps {
    title: string;
    price: string;
    subhead: string;
    details: string[];
    isActive: boolean;
    onSignUp?: () => void;
    signUpButtonText: string;
}

const PriceModules = observer((props: PriceModulesProps) => {
    const {handleSignUp} = props;

    const PriceModule = ({title, price, subhead, details, isActive, onSignUp, signUpButtonText}: PriceModuleProps) =>
        <section className="flex flex-row items-start items-center lg:justify-center">
        <div className="flex flex-row max-w-lg w-full pb-4">
            <article className="bg-darkblue border-darkblue w-full px-6 lg:px-2 py-6 justify-center flex items-center text-primary-dark rounded-l-lg ">
                <div className="text-center">
                    <h5 className="font-bold text-white text-base">{title}</h5>
                    <h2 className="flex justify-center font-bold text-white">
                        <span className={subhead.length > 6 ? "text-3xl" : "text-6xl"}>{price}</span>
                    </h2>

                    <p className="text-white">{subhead}</p>
                </div>
            </article>
            <article className="flex flex-col max-w-lg pt-6 pb-6 lg:w-full rounded-r-lg border-darkblue border-4 pl-4">
                <ul className="text-sm flex-grow font-medium">
                    {details.map((detail, i) =>
                        <div key={i} className="pt-2 pb-2 text-gray-600">
                            <svg className="h-6 w-6 mr-1 text-purple inline" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                            </svg>
                            {detail}</div>
                    )}
                </ul>
                {!isActive && onSignUp &&
                <div className="my-4 self-center">
                    <Button onClick={onSignUp} variant="contained" color="primary" className="text-white font-bold">
                        {signUpButtonText}
                    </Button>
                </div>
                }
            </article>
        </div>
    </section>;

    return <>
        {paddlePlans
            .filter(plan => (props.showActive && props.activePlan === plan) || (!props.showActive && props.activePlan !== plan))
            .map((plan) => {
                let signUpButtonText;
                if (props.activePlan !== plan && plan.price !== undefined && props.activePlan?.price !== undefined) {
                    signUpButtonText = props.activePlan.price > plan.price ? "Downgrade" : "Upgrade";
                } else {
                    signUpButtonText = "Change Plan"
                }

                return <div key={plan.paddleId}>
                    <PriceModule
                        title={plan.plan.name}
                        price={`$${plan.price}`}
                        subhead={`Up to ${plan.plan.maxSubmissionsPerMonth} Waivers per month`}
                        details={["Use of the app (1 device)", `Up to ${plan.plan.maxForms} form templates`, "In-app kiosk mode", "E-mail notifications"]}
                        isActive={plan === props.activePlan}
                        onSignUp={handleSignUp ? () => handleSignUp(plan) : undefined}
                        signUpButtonText={signUpButtonText}
                    />
                </div>;
            }
        )}
        {!props.showActive &&  <div >
            <PriceModule
                title="Enterprise"
                price="Contact us"
                subhead="Custom plan"
                details={["All Team features", "Sign sign-on", "Custom app", "API (coming soon)"]}
                isActive={false}
                onSignUp={() => { window.location.href = "https://www.speedywaiver.io/contactus"; }}
                signUpButtonText="Contact Us"
            />
        </div>}
    </>;
});

export default PriceModules;