import * as React from "react";
import {FieldRenderMode, FieldType} from "shared/forms/model";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import {action} from "mobx";
import {observer} from "mobx-react";
import {FieldProps} from "./field";
import { useFormContext } from "react-hook-form";
import {textInputProps} from "./defaultProps";
import { ThemeProvider } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import {themes} from "../../../MatxLayout/settings";
import {FillSubmissionFields} from "../../submissions/FillSubmissionFields";
import {RenderFields} from "../../submissions/ViewSubmissionPage";
import {useState} from "react";
import {MatxSuspense} from "../../../matx";
import {AppTextInput} from "../../common/AppTextInput";

export const RepeatedGroupField = observer((props: FieldProps<FieldType.RepeatedGroup>) => {

    const {field, fieldId, data} = props;
    const { errors, register } = useFormContext();
    const [groupCount, setGroupCount] = useState(field.config.fixedInstances || 0);

    const updateRequired = action((event: any, checked: boolean) => {
        field.config.isRequired = checked;
    });

    const updateMaximum = action((event: any) => {
        field.config.maximumInstances = parseInt(event.target.value, 10) || null;
    });

    const updateFixed = action((event: any) => {
        field.config.fixedInstances = parseInt(event.target.value, 10) || null;
    });

    const editFieldId = "e-" + field.id;

    const addButton =
        <Button onClick={() => {
            setGroupCount(groupCount + 1);
        }} variant="contained" color="primary">
            <Icon>add</Icon><span className="pl-2">Add</span>
        </Button>;

    return (
        <>
            {props.fieldRenderMode === FieldRenderMode.Edit &&
            <div key="edit" className="w-full">
                <AppTextInput autoFocus
                           name={editFieldId}
                           label="Field title"
                           defaultValue={props.field.label}
                           onChange={action((event) => { field.isClean = false; field.label = event.target.value; })}
                           inputRef={register({required: true})}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={updateRequired}
                            checked={field.config.isRequired}
                            color="primary"
                        />
                    }
                    label="At least one repetition required?"
                />
                <div className="w-full md:flex mb-4">
                    <div className=" md:mr-1 w-full">
                        <TextField {...textInputProps(`${editFieldId}-maximum`, errors)}
                                   label="Fixed count of Repetitions"
                                   id="fixed"
                                   defaultValue={field.config.fixedInstances || null}
                                   onChange={updateFixed}
                                   type="number"
                                   inputRef={register({pattern: /^[0-9]+$/})}
                        />
                    </div>
                    <div className="mt-2 md:mt-0 md:ml-1 w-full">
                        <TextField {...textInputProps(`${editFieldId}-maximum`, errors)}
                                   label="Maximum Repetitions"
                                   id="maximum"
                                   defaultValue={field.config.maximumInstances || null}
                                   onChange={updateMaximum}
                                   type="number"
                                   inputRef={register({pattern: /^[0-9]+$/})}
                        />
                    </div>
                </div>
            </div>
            }
            {props.fieldRenderMode === FieldRenderMode.Fill &&
            <>
                {!props.isInFormBuilder &&
                    <div style={{fontSize: props.fieldStyle.fontSize}} className="w-full bg-primary field-draggable text-white text-center py-2">
                    {field.label}
                    </div>
                }

                {props.isInFormBuilder && field.config.fieldInstances.length === 0 &&
                <div className="text-center py-8 text-muted text-lg">
                    Add fields here
                </div>
                }
                {!props.isInFormBuilder &&
                    <div className="border-l-2 border-r-2 border-b-2 border-purple py-4 px-4 mb-4">
                        {Array.from(Array(groupCount).keys()).map(( i) =>
                            <div key={i} className={`${i > 0 ? "pt-6" : "pt-2"} `} style={{borderTop: i !== 0 ? "dashed 1px black" : undefined}}>
                                <MatxSuspense>
                                <FillSubmissionFields
                                    fieldInstances={field.config.fieldInstances}
                                    fieldPath={`[${fieldId}][${i}].`}
                                    submissionId={props.submissionId}
                                    isInsideGroup={true}
                                    orgId={props.orgId}
                                />
                                </MatxSuspense>
                                {!field.config.fixedInstances &&
                                <div className="flex flex-row justify-end mb-4">
                                    <ThemeProvider theme={themes["white"]}>
                                        {i === groupCount - 1 && groupCount < (field.config.maximumInstances || 99) &&
                                        <span className="mr-2">
                                                {addButton}
                                            </span>
                                        }

                                        <Button onClick={() => {
                                            setGroupCount(groupCount - 1);
                                        }} variant="contained" color="primary">
                                            <Icon>remove</Icon><span className="pl-2">Remove</span>
                                        </Button>
                                    </ThemeProvider>

                                </div>
                                }
                            </div>
                        )}

                        {groupCount === 0 && !field.config.fixedInstances &&
                        <ThemeProvider theme={themes["white"]}>
                            <div className="flex flex-row justify-end">
                                {addButton}
                            </div>
                        </ThemeProvider>
                        }
                    </div>
                }
            </>
            }
            {props.fieldRenderMode === FieldRenderMode.Display &&
            <div key="display" className="w-full">
                {data?.value && data.value.length > 0 ? data.value.map((data, i) =>
                    <div key={i}>
                        <div className="field-display-name">
                            {field.label} ({i + 1}):
                        </div>
                        <div className="pl-4 pt-4">
                            <RenderFields
                                key={i}
                                fieldInstances={field.config.fieldInstances}
                                fieldRenderMode={props.fieldRenderMode}
                                data={data}
                                fieldStyle={props.fieldStyle}
                                submissionId={props.submissionId}
                                isInsideGroup={true}
                            />
                        </div>
                    </div>
                ) : <>
                    <div className="field-display-name">
                        {field.label}:
                    </div>
                    <div className="field-display-data pb-6">
                        (none)
                    </div>
                </>
                }
            </div>
            }
        </>
    )
});

export const RepeatedGroupEnd = () =>
    <div className="w-full bg-primary py-2">
    </div>;