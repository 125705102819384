import React from "react";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Link from "@material-ui/core/Link";
import {useStores} from "../../stores/context";
import {observer} from "mobx-react";
import {Link as RouterLink, useHistory} from "react-router-dom";
import {formatTimestamp, calculateAge, TimestampFormat} from "shared/utils/dateUtils";
import {Submission, userSubmissionId} from "shared/submissions/model";
import {Document} from "firestorter";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import {useIsSmallScreen} from "../../hooks/screen";
import {MatxLoading} from "../../matx";
import {useIsLg} from "../../hooks/useWindowSize";
import {useQuery} from "../../hooks/useQuery";
import {allFormsId} from "shared/forms/model";
import StoreTablePagination from "../common/StoreTablePagination";
import {allGroupsId} from "shared/groups/model";

export default observer(() => {
    const {submissionListStore, formListStore, authStore} = useStores();
    const history = useHistory();
    authStore.abortIfNotSignedIn();
    const query = useQuery();
    submissionListStore.ensureInitialized(query.get("formId") || undefined, query.get("groupId") || undefined);
    const groupName = query.get("groupName");
    formListStore.ensureInitialized();
    const isSmallScreenBrowser = useIsSmallScreen();

    const handleViewSubmission = (submissionId: string) => () => {
        if (!isSmallScreenBrowser) { //small screen browsers are handled by 'onRowPress'
            history.push(`/submissions/${submissionId}`);
        }
    };

    const dateTimeFormat = isSmallScreenBrowser ? TimestampFormat.ShortDateTimeFormat : TimestampFormat.DateTimeFormat;

    const handleDaysChange = (event: any) => {
        submissionListStore.handleFilterDays(event.target.value);
    }

    const handleFormFilterChange = (event: any) => {
        submissionListStore.handleFilterForm(event.target.value);
    }

    const clearGroupFilter = () => {
        submissionListStore.handleFilterGroup(allGroupsId);
    }

    const isLg = useIsLg();

    const formNamesById = formListStore.formNamesById;

    if (formListStore.isLoaded && formListStore.isEmpty()) {
        history.push(`/forms`);
    }

    return (
        <div className="w-full overflow-auto">
            <div className="mt-2 mb-4 md:flex">
                <TextField
                    name="searchText"
                    id="searchtext"
                    label="Search name/e-mail/submission id"
                    onChange={(event) => submissionListStore.handleSearch(event.target.value)}
                    variant="outlined"
                    InputProps={{ startAdornment: <InputAdornment position="start"><Icon>search</Icon></InputAdornment> }}
                    style={{maxWidth: 300}}
                    className="w-full"
                />
                <div className="flex-grow"></div>
                <div className="mr-2 mt-4 md:mt-0">
                    <TextField style={{width: 150}} id="filterbyform" onChange={handleFormFilterChange} value={submissionListStore.currentFilterFormId} label="Filter by form" variant="outlined" select>
                        <MenuItem value={allFormsId}>All</MenuItem>
                        {formListStore.isLoaded && formListStore.docs.filter(form => form.data.name !== "").map(form => {
                            return <MenuItem key={form.id} value={form.id}>{form.data.name}</MenuItem>;
                        })}
                    </TextField>
                </div>
                <div className="mt-4 md:mt-0">
                    <TextField style={{width: 150}} id="filterbydate" onChange={handleDaysChange} label="Filter by date" value={submissionListStore.currentFilterDays} variant="outlined" select>
                        {submissionListStore.filterDaysOptions.map(option => {
                            return <MenuItem key={option.days} value={option.days}>{option.title}</MenuItem>;
                        })}
                    </TextField>
                </div>
                {submissionListStore.currentFilterGroupId !== allGroupsId &&
                <div className="mt-4 ml-4 md:mt-0 items-center  flex">
                    Filtering by group: {groupName}

                    <IconButton size="small" onClick={clearGroupFilter}>
                        <Icon aria-hidden={false} aria-label="Close" >close</Icon>
                    </IconButton>
                </div>
                }
            </div>

            {submissionListStore.isLoading ?
                <MatxLoading verticalSpace={true}/>:

                submissionListStore.isEmpty() ?
                    <div className="w-full text-center pt-16 pb-16 text-16"><div>No submitted forms matching your criteria.
                        Use 'Add New Submission' above to fill a form.</div>

                        <IconButton onClick={submissionListStore.refresh}>
                            <Icon aria-hidden={false} aria-label="Refresh" >refresh</Icon>
                        </IconButton>
                    </div>
                    :
                    <>
                        <Table className="whitespace-pre">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="px-0">Form</TableCell>
                                    <TableCell className="px-0">Name</TableCell>
                                    {isLg && <TableCell  className="px-0">Age</TableCell>}
                                    {isLg && <TableCell  className="px-0">Submission ID</TableCell> }
                                    <TableCell className="px-0">{isSmallScreenBrowser ? "Submitted" : "Submitted At"}</TableCell>
                                    <TableCell className="px-0" style={{width: 50}}/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {submissionListStore.docs
                                    .map((submission, index) => (
                                        <SubmissionListingRow key={submission.id} submission={submission}
                                                              onViewSubmission={handleViewSubmission}
                                                              dateTimeFormat={dateTimeFormat}
                                                              isLg={isLg} formName={formNamesById.get(submission.data.formId) || submission.data.formName} />
                                    ))}
                            </TableBody>
                        </Table>

                        <StoreTablePagination store={submissionListStore}/>
                    </>
            }
        </div>
    );
});

interface SubmissionListingRowProps {
    submission: Document<Submission>;
    onViewSubmission: (submissionId: string) => () => void;
    dateTimeFormat: TimestampFormat;
    isLg: boolean;
    formName: string;
}

const SubmissionListingRow = (props: SubmissionListingRowProps) => {
    const { submission, dateTimeFormat, isLg } = props;
    const isSmallScreenBrowser = useIsSmallScreen();
    const history = useHistory();
    const submissionLink = `/submissions/${submission.id}`;

    const onRowPress = () => {
        if (isSmallScreenBrowser) {
            history.push(submissionLink);
        }
    }

    return (<TableRow
        onClick={onRowPress}
        className="select-none"
    >
        <TableCell className="px-0 capitalize" align="left">
            <Link component={RouterLink} color="inherit" to={submissionLink}>{props.formName}</Link>
        </TableCell>
        <TableCell className="px-0" align="left">
            {submission.data.lastName && `${submission.data.lastName}, ${submission.data.firstName}`}
        </TableCell>
        {isLg && <TableCell className="px-0" align="left">
            {submission.data.dob && calculateAge(submission.data.dob)}
        </TableCell>}
        {isLg && <TableCell className="px-0" align="left">
            {userSubmissionId(submission.id!)}
        </TableCell>}
        <TableCell className="px-0">
            {formatTimestamp(submission.data.createdAt, dateTimeFormat)}
        </TableCell>
        <TableCell className="hidden md:block px-0">
            <IconButton onClick={props.onViewSubmission(submission.id as string)}>
                <Icon aria-hidden={false} aria-label="View Submission">visibility</Icon>
            </IconButton>
        </TableCell>
    </TableRow>);
};
