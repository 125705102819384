import {FieldInstance, Form, FormDoc, formsEntity} from "./model";
import {EditStore} from "../common/editStore";
import {formLink} from "./webLink";

import {action, computed, runInAction, observable} from "mobx";
import {FormConfigEditStore, FormConfigStoreFactory} from "./formConfigEditStore";
import {Context} from "../common/context";
import {RootStore} from "../common/rootStore";
import {functionsRoot} from "../utils/firebaseFunctions";
import {ObservablePromise} from 'mobx-observable-promise';
import {MailchimpLists} from "../users/model";

export class FormEditStore extends EditStore<Form, FormDoc> {

    static scOffset = 25354;

    @observable formConfigEditStore?: FormConfigEditStore = undefined;

    excludedFromUpdateFields = ["subCounter"];

    constructor(context: Context, rootStore: RootStore, timeZone: string, id: string | undefined, formConfigStoreFactory?: FormConfigStoreFactory, doLoadFormConfig?: boolean) {
        super(context, rootStore.userStore, formsEntity, timeZone, id, id === undefined ? FormEditStore.emptyEntity(rootStore.userStore.userData!.orgId) : undefined);

        if (doLoadFormConfig && formConfigStoreFactory) {
            if (id === undefined) {
                this.formConfigEditStore = formConfigStoreFactory(undefined, this.id);
            } else {
                this.loadingPromise!.then(action(() => {
                    if (this.entity) {
                        this.formConfigEditStore = formConfigStoreFactory(this.entity.currentFormConfigId);
                    }
                }));
            }
        }
    }

    static emptyEntity(orgId: string): Form {
        return {
            name: "",
            nameLower: "",
            currentFormConfigId: undefined,
            currentFieldInstances: [],
            ackSubmitUrl: "",
            ackText: "",
            isPublished: false,
            subCounter: this.scOffset,
            createdAt: undefined as any,
            updatedAt: undefined as any,
            canEmailWaiver: true,
            addToMailchimpListId: null,
            addToMailchimpListName: null,
            orgId
        };
    }

    get formLink(): string {
        return formLink(this.id!);
    }

    get fieldInstances(): FieldInstance[] {
       return this.entity.currentFieldInstances;
    }

    async saveFormConfig() {
        const newFormConfigId = await this.formConfigEditStore!.saveAsNew();
        runInAction(() => {
            this.entity.currentFormConfigId = newFormConfigId;
            this.entity.currentFieldInstances = this.formConfigEditStore!.entity.fieldInstances;
            this.entity.orgId = this.userStore.userData!.orgId;
        });
        if (this.isNew) {
            await this.save();
        } else {
            await this.partialUpdate({
                currentFormConfigId: this.entity.currentFormConfigId,
                currentFieldInstances: this.entity.currentFieldInstances
            });
        }
    }

    @action
    async saveName() {
        if (this.isLoading) {
            throw "attempt to save document while it's loading";
        }
        this.isSaving = true;
        await this.document.update({name: this.entity.name, nameLower: this.entity.nameLower});
        runInAction(() => {
            this.isSaving = false;
        });
    }

    @action
    async savePublishStatus() {
        await this.partialUpdate({isPublished: this.entity.isPublished});
    }

    @action
    async saveCanEmailWaiver() {
        await this.partialUpdate({canEmailWaiver: this.entity.canEmailWaiver});
    }

    async saveNameAndPublishStatus() {
        await this.partialUpdate({name: this.entity.name, nameLower: this.entity.nameLower, isPublished: this.entity.isPublished});
    }

    async saveSubmissionSettings() {
        await this.partialUpdate({ackText: this.entity.ackText, ackSubmitUrl: this.entity.ackSubmitUrl, canEmailWaiver: this.entity.canEmailWaiver})
    }

    async saveMailchimpSettings() {
        await this.partialUpdate({addToMailchimpListId: this.entity.addToMailchimpListId, addToMailchimpListName: this.entity.addToMailchimpListName    })
    }

    @action
    setPublished = (isPublished: boolean) => {
        this.entity.isPublished = isPublished;
    };

    @action
    setCanEmailWaiver = (canEmailWaiver: boolean) => {
        this.entity.canEmailWaiver = canEmailWaiver;
    };

    @action
    setFormName = (name: string) => {
        this.entity.name = name;
        this.entity.nameLower = name.normalize('NFKC').toLowerCase().toUpperCase().toLowerCase();
    };

    @action
    setAckText = async (ackText: string, doSave?: boolean) => {
        this.entity.ackText = ackText || "";
        if (doSave) await this.partialUpdate({ackText});
    };

    @action
    setAckSubmitUrl = async (ackSubmitUrl: string, doSave?: boolean) => {
        this.entity.ackSubmitUrl = ackSubmitUrl || "";
        if (doSave) await this.partialUpdate({ackSubmitUrl});
    };

    @action
    setMailchimpListId = async (mailchimpListId: string) => {
        if (mailchimpListId === "none") {
            this.entity.addToMailchimpListId = null;
            this.entity.addToMailchimpListName = null;
        } else {
            this.entity.addToMailchimpListId = mailchimpListId;
            this.entity.addToMailchimpListName = this.mailchimpLists.lists.find((list) => list.id === mailchimpListId)?.name || null;
        }
    };

    private mailChimpListsPromise = runInAction(() => new ObservablePromise(async () =>
        functionsRoot().httpsCallable('getMailchimpLists')()
            .then((result) => result.data as MailchimpLists)
            .catch((err) => {
                this.log.error(err, "Error getting Mailchimp lists");
                return {lists: []} as MailchimpLists;
            })
    ));

    @computed get mailchimpLists(): MailchimpLists {
        if (!(this.mailChimpListsPromise.wasExecuted || this.mailChimpListsPromise.isExecuting)) {
            this.mailChimpListsPromise.execute();
        }
        return this.mailChimpListsPromise.result;
    }


}