import React from "react";
import TablePagination, { LabelDisplayedRowsArgs } from "@material-ui/core/TablePagination";
import {ListStore} from "shared/common/listStore";
import {observer} from "mobx-react";
import {useIsSmallScreen} from "../../hooks/screen";

interface IProps<T extends object> {
    store: ListStore<T>;
}

const StoreTablePagination = observer(<T extends object, >(props: IProps<T>) => {
    const store = props.store;
    const isSmallScreen = useIsSmallScreen();

    return <TablePagination
        className={!isSmallScreen ? "px-4" : ""}
        rowsPerPageOptions={[20, 40, 80]}
        labelDisplayedRows={({from, to, count}: LabelDisplayedRowsArgs) =>
            isSmallScreen ?
                `${from}-${to} / ${count !== -1 ? count : `${to}+`}` :
                `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`
        }
        component="div"
        count={store.totalCount}
        rowsPerPage={store.pageSize}
        labelRowsPerPage=""
        page={store.currentPage}
        backIconButtonProps={{
            "aria-label": "Previous Page"
        }}
        nextIconButtonProps={{
            "aria-label": "Next Page"
        }}
        onPageChange={store.handleChangePage}
        onRowsPerPageChange={store.handleChangeRowsPerPage}
    />;
});

export default StoreTablePagination;