import React, {useContext, useEffect, useRef} from "react";
import { withStyles, ThemeProvider } from "@material-ui/core/styles";
import { renderRoutes } from "react-router-config";
import Layout1Topbar from "./Layout1Topbar";
import Layout1Sidenav from "./Layout1Sidenav";
import AppContext from "appContext";
import { MatxSuspense } from "matx";
import {useStores} from "../../stores/context";
import {themes} from "../settings";
import {observer} from "mobx-react";
import cx from "classnames";
import {useLocation} from "react-router-dom";

const styles = theme => {
  return {
    layout: {
      backgroundColor: theme.palette.background.default
    }
  };
};

const Layout1 = observer(props => {
  const { routes } = useContext(AppContext);

  const {uiLayoutStore} = useStores();
  const { pathname } = useLocation();
  const scrollRef = useRef(null);

  let { classes } = props;
  let layoutClasses = {
    [classes.layout]: true,
    [`layout1 flex`]: true,
    "topbar-fixed": true
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0
    }
  }, [pathname]);

  return (
    <div className={cx(layoutClasses)}>
      <Layout1Sidenav />

      <div className="content-wrap relative">

        {uiLayoutStore.showHeader &&
        <ThemeProvider theme={themes["purpleDark1"]}>
          <Layout1Topbar fixed={true} className="elevation-z8"/>
        </ThemeProvider>
        }

        <div ref={scrollRef} className="scrollable-content" style={{marginTop: uiLayoutStore.showHeader ? 64 : 0}}>
          <div className="content">
            <MatxSuspense>{renderRoutes(routes)}</MatxSuspense>
          </div>
          <div className="my-auto" />
        </div>

      </div>

    </div>
  );
});

export default withStyles(styles, { withTheme: true })(
    Layout1
);
