import React from "react";
import {action, runInAction} from "mobx";
import {useHistory, withRouter} from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { MatxMenu } from "matx";
import cx from "classnames";
import { Link } from "react-router-dom";
import { useStores} from "../../stores/context";
import { ThemeProvider } from "@material-ui/core/styles";
import {themes} from "../settings";
import {useIsLg} from "../../hooks/useWindowSize";

const styles = theme => ({
  topbar: {
    "& .topbar-hold": {
      backgroundColor: theme.palette.primary.main,
      height: "80px",
      "&.fixed": {
        boxShadow: theme.shadows[8],
        height: "64px"
      }
    }
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    minWidth: 185
  }
});

const Layout1Topbar = (props) => {

  const {uiLayoutStore, userStore} = useStores();
  const history = useHistory();

  const handleSidebarToggle = action(() => {
    runInAction(() => {
      uiLayoutStore.isSideNavOpen = !uiLayoutStore.isSideNavOpen;
    });
  });

  const handleSettings = () => {
    history.push({
      pathname: "/settings"
    })
  };

  const handleSignOut = () => {
    userStore.signOut();
    history.push({
      pathname: "/session/signin"
    })
  };
  let { classes, fixed } = props;

  const isLg = useIsLg();

  return (
    <div style={{width: isLg ? `calc(100vw - 256px)` : "100%"}} className={`topbar ${classes.topbar}`}>
      <div className={cx({ "topbar-hold": true, fixed: fixed })}>
        <div className="flex justify-between items-center h-full">
          <div className="flex">
            <div className="lg:hidden">
              <IconButton
                onClick={handleSidebarToggle}
              >
                <Icon>menu</Icon>
              </IconButton>
            </div>
          </div>
          <div className="flex items-center">

            {/*<NotificationBar />*/}
            <ThemeProvider theme={themes["white"]}>
            <MatxMenu
              menuButton={
                <IconButton><Icon aria-hidden={false} aria-label="User Menu" style={{color: "white"}}>person</Icon></IconButton>
              }
            >
              {userStore.user?.displayName && userStore.user.displayName !== "" &&
                <div className="pl-4 font-bold" >{userStore.user?.displayName}</div>
              }
              <MenuItem>
                <Link className={classes.menuItem} to="/">
                  <Icon> home </Icon>
                  <span className="pl-4"> Home </span>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleSettings}
                        className={classes.menuItem}>
                <Icon> settings </Icon>
                <span className="pl-4"> Settings </span>
              </MenuItem>
              <MenuItem
                onClick={handleSignOut}
                className={classes.menuItem}
              >
                <Icon> power_settings_new </Icon>
                <span className="pl-4"> Logout </span>
              </MenuItem>
            </MatxMenu>
            </ThemeProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(
  withRouter(Layout1Topbar)
);
