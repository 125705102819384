import {getFirebaseApp} from "firestorter";
import 'firebase/storage';
const storage = getFirebaseApp().storage();

export type ImageReference = {
    type: "path", //path returned by image picker
    path: string
} | {
    type: "svg", //string returned by text-to-svg
    svg: string
} | {
    type: "dataURL", //used for signature pad
    url: string
}

//only used by web, mobile uses the imageUploader queue
export const uploadImages = async (images: Array<{imageReference: ImageReference, destination: string}>): Promise<firebase.storage.UploadTask[]> => {
    return images.map( imageToSave => {
        const {imageReference, destination} = imageToSave;
        const fileRef = storage.ref().child(destination);
        if (imageReference.type === "svg") {
            return fileRef.putString(imageReference.svg, "raw", {contentType: "image/svg+xml"});
        } else if (imageReference.type === "dataURL") {
            return fileRef.putString(imageReference.url, "data_url");
        } else {
            throw new Error("Invalid image type");
        }
    });
}

export interface AvailableImageUrl {
    status:"available";
    url: string; //can be a url or path on mobile
}

export type ImageUrl = {
    status: "pending" | "none" | "notavailable";
} | AvailableImageUrl;

export const imageDownloadUrlFromPath: (path: string | undefined | null) => Promise<ImageUrl> = async (path: string | undefined | null) => {
    if (path) {
        try {
            const url = await storage.ref(path).getDownloadURL();
            return {url, status: "available"};
        } catch(e) {
            return {status: "notavailable"};
        }
    } else {
        return {status: "none"};
    }
}
