import * as firebaseApp from 'firebase/app';
type Timestamp = firebaseApp.firestore.Timestamp;

export interface Plans {
    [productId: string]: Plan;
}

export interface Plan {
    productId: string;
    name: string;
    maxSubmissionsPerMonth: number;
    maxForms: number;
}

export interface IapPlan {
    plan: Plan;
    tooltipText: string;
}

export interface PaddlePlan {
    paddleId: number;
    plan: Plan;
    description: string;
    price: number;
}

export type SubscriptionStatus = 'active' | 'trialing' | 'past_due' | 'deleted';

export interface SubscriptionEvent {
    subscription_plan_id: number;
    subscription_id: number;
    timestamp: Timestamp;
    cancel_url?: string;
    update_url?: string;
    status: SubscriptionStatus;
}

export interface IapDetails {
    productId: string;
    transactionReceipt: string;
    timestamp: Timestamp;
}

export interface TopUp {
    productId: string;
    submissions: number;
}

export const productNamespace = process.env.REACT_APP_ENV === 'staging' ? "speedywaiver" : "speedywaiver.prod";

export const plans: Plans = [
    {
        productId: `${productNamespace}.sub10`,
        name: "Individual",
        maxSubmissionsPerMonth: 100,
        maxForms: 3
    },
    {
        productId: `${productNamespace}.sub20`,
        name: "Business",
        maxSubmissionsPerMonth: 500,
        maxForms: 20
    },
    {
        productId: `${productNamespace}.sub30`,
        name: "Premium",
        maxSubmissionsPerMonth: 1500,
        maxForms: 40
    },
    {
        productId: `${productNamespace}.sub40`,
        name: "Enterprise",
        maxSubmissionsPerMonth: 100000000,
        maxForms: 1000
    }
].reduce((acc, plan) => {
    acc[plan.productId] = plan;
    return acc;
}, {} as {[productId: string]: Plan});

export const iapPlans = [
    {
        plan: plans[`${productNamespace}.sub10`],
        tooltipText: "Includes 100 waivers per month, 4 form templates and 1 device."
    },
    {
        plan: plans[`${productNamespace}.sub20`],
        tooltipText: "Includes 500 waivers per month, 20 form templates, 5 devices and question analytics."
    },
    {
        plan: plans[`${productNamespace}.sub30`],
        tooltipText: "Includes 1,500 waivers per month, 40 form templates, 20 devices, question analytics, priority support and uptime SLA."
    }
];

export const iapPlansObj: {[productId: string]: IapPlan} = iapPlans.reduce((acc, plan) => {
    acc[plan.plan.productId] = plan;
    return acc;
}, {} as {[productId: string]: IapPlan});

export const topUps: TopUp[] = [
    {
        productId: `${productNamespace}.submissions.100`,
        submissions: 100
    },
    {
        productId: `${productNamespace}.submissions.200`,
        submissions: 200
    },
    {
        productId: `${productNamespace}.submissions.500`,
        submissions: 500
    },
    {
        productId: `${productNamespace}.submissions.1000`,
        submissions: 1000
    },
    {
        productId: `${productNamespace}.submissions.2000`,
        submissions: 2000
    }
];

export const topUpsObj: {[productId: string]: TopUp} = topUps.reduce((acc, topUp) => {
    acc[topUp.productId] = topUp;
    return acc;
}, {} as {[productId: string]: TopUp});

export const paddlePlans = [
    {
        plan: plans[`${productNamespace}.sub10`],
        description: "For starting out",
        price: 8.99,
        paddleId: process.env.REACT_APP_ENV === 'staging' ? 622895 : 622899
    },
    {
        plan: plans[`${productNamespace}.sub20`],
        description: "For small businesses",
        price: 29.99,
        paddleId: process.env.REACT_APP_ENV === 'staging' ? 598604 : 622900
    },
    {
        plan: plans[`${productNamespace}.sub30`],
        description: "For larger businesses",
        price: 49.99,
        paddleId: process.env.REACT_APP_ENV === 'staging' ? 622896 : 622901
    },
    {
        plan: plans[`${productNamespace}.sub40`],
        description: "Enterprise",
        price: 299.99,
        paddleId: process.env.REACT_APP_ENV === 'staging' ? 622896 : 646240
    }
];

export const paddlePlansObj: {[productId: string]: PaddlePlan} = paddlePlans.reduce((acc, plan) => {
    acc[plan.plan.productId] = plan;
    return acc;
}, {} as {[productId: string]: PaddlePlan});

export type PlanChannel = "paddle" | "iap" | "";

export const paddleVendorId = process.env.REACT_APP_PADDLE_VENDOR_ID;