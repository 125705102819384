import {getFirebaseApp} from "firestorter";
import 'firebase/functions';
import 'firebase/storage';

const useEmulator = process.env.REACT_APP_USE_FUNCTION_EMULATOR === "true";

if (useEmulator) {
    const host = "http://localhost:5001";
    getFirebaseApp().functions("europe-west1").useFunctionsEmulator(host);
    console.log(`Using Firebase emulator at ${host}`);
}

export const functionsRoot = () => getFirebaseApp().functions("europe-west1");