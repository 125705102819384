import * as React from "react";
import { SimpleCard } from "matx";
import {observer} from "mobx-react";
import SubmissionListingTable from "./SubmissionListingTable";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import {ExportDialog} from "./ExportDialog";
import ExportIcon from 'mdi-material-ui/ExportVariant';
import DialogContentText from "@material-ui/core/DialogContentText";
import {setTitle} from "../utilities/setTitle";
import { useStores } from "stores/context";
import {AckDialog} from "../common/AckDialog";
import {FullWidthPageWrapper} from "../../matx/components/PageWrapper";

export default observer(() => {
    const history = useHistory();
    const {billingStore} = useStores();
    billingStore.ensureInitialized();

    const [doDisplayExportDialog, setDoDisplayExportDialog] = useState(false);
    const [doDisplayEndOfTrialMessage, setDoDisplayEndOfTrialMessage] = useState(false);

    const handleEndOfTrialClose = async () => {
        await billingStore.updateDisplayedEndOfTrial();
        setDoDisplayEndOfTrialMessage(false);
    }

    const handleExportClose = () => {
        setDoDisplayExportDialog(false);
    };

    const openExportDialog = () => {
        setDoDisplayExportDialog(true);
    }

    useEffect(() => {
        if (!billingStore.isLoading) {
            setDoDisplayEndOfTrialMessage(billingStore.shouldDisplayEndOfTrialMessage);
        }

    }, [billingStore.isLoading, billingStore.shouldDisplayEndOfTrialMessage, setDoDisplayEndOfTrialMessage])

    useEffect(() => {
        setTitle(`Submissions`);
    }, []);

    const topRightContent = <>
        <span className="ml-2">
            <Button onClick={openExportDialog} variant="contained">
                <ExportIcon/><span className="pl-2">Export</span>
            </Button>
        </span>
        <span className="ml-2">
            <Button onClick={() => history.push('/submissions/new')} variant="contained" color="primary">
                <Icon>add</Icon><span className="pl-2">Add New Submission</span>
            </Button>
        </span>
        </>;

    return (
        <FullWidthPageWrapper>
            {doDisplayExportDialog && <ExportDialog handleClose={handleExportClose}/>}
            {doDisplayEndOfTrialMessage &&
            <AckDialog
                title="End of Trial"
                okText="Ok"
                handleClose={handleEndOfTrialClose}
            >
                <DialogContentText>
                    You have now completed your one month free trial. To continue using speedyWaiver please subscribe in the 'Billing' section.
                </DialogContentText>
            </AckDialog>
            }
            <SimpleCard title="Submissions" topRightContent={topRightContent}>
                <SubmissionListingTable />
            </SimpleCard>
        </FullWidthPageWrapper>
    );
});