import {autorun, reaction, action, observable, runInAction} from "mobx";
import {Document, getFirestore, getFirebase} from "firestorter";
const FieldValue = getFirebase().firestore.FieldValue;
const Timestamp = getFirebase().firestore.Timestamp;
const db = getFirestore();

import {UserStore} from "../auth/userStore";
import {isWeb} from "../utils/utils";
import {Context, Logger} from "./context";
import {withTimeoutUsingCancelToken} from "../utils/promiseUtils";

export abstract class EditStore<A, B extends object> {

    protected entityType: string;

    protected userStore: UserStore;

    @observable entity: A = undefined as any;
    @observable isLoading = true;
    @observable isSaving = false;
    @observable isLongSaving = false;
    @observable errorState: any = undefined;

    isNew = false;
    document: Document<B> = undefined as any;
    get id() { return this.document?.id }
    loadingPromise?: Promise<void> = undefined;
    notFoundHandler?: () => void;
    requestedDocumentId: string | undefined;
    timeZone: string;

    excludedFromUpdateFields: string[] = [];
    log: Logger;

    constructor(context: Context, userStore: UserStore, entityType: string, timeZone: string, id: string | undefined, defaultEntity?: A) {
        this.log = context.logger(this);
        this.notFoundHandler = context.notFoundHandler;
        this.requestedDocumentId = id;
        this.timeZone = timeZone;

        this.entityType = entityType;
        this.userStore = userStore;

        if (id === undefined) {
            if (!defaultEntity) throw `Attempting to initialize ${this.entityType} with no defaultEntity`;

            runInAction(() => {
                this.entity = defaultEntity;
                this.isNew = true;
                this.document = new Document(db.collection(entityType).doc());
                this.isLoading = false;
            });
        } else {
            this.fetch();
        }

        autorun(() => {
            if (this.isSaving) {
                runInAction(() => {
                    this.isLongSaving = true;
                });
            }
        }, {delay: 1000});

        reaction(() => this.isSaving, (isSaving) => {
            if (!isSaving) {
                runInAction(() => {
                    this.isLongSaving = false;
                });
            }
        })
    }

    fetch = action(() => {
        const documentId = `${this.entityType}/${this.requestedDocumentId}`;
        this.isLoading = true;
        this.errorState = undefined;
        this.log.info(`Fetching ${`${documentId}`}`);
        this.loadingPromise = withTimeoutUsingCancelToken(this.log, () => (new Document<B>(`${documentId}`)).fetch().then(async (document) => {
            if (!document.hasData) {
                throw new NotFoundException();
            }
            runInAction(() => {
                this.document = document;
                this.entity = this.doc2Entity(this.document.data);
                this.isLoading = false;
                this.isNew = false;
            })
        }).catch((e) => {
            runInAction(() => {
                this.errorState = e;
                this.log.error(e, "Error fetching document");
                if (e.code === "permission-denied") {
                    this.notFoundHandler && this.notFoundHandler();
                }
            })
        }), 30000, `EditStore-${documentId}`, () => {
            runInAction(() => {
                this.isLoading = false;
                this.errorState = new Error("Loading timeout")
            });
        });
    });

    entity2Doc(entity: A): B {
        return entity as any as B;
    }

    doc2Entity(doc: B): A {
        return doc as any as A;
    }

    @action
    async save() {
        function hasClientCreationDate(doc: B): doc is { clientCreatedAt: string, clientCreatedAtTz: string } & B {
            return "clientCreatedAt" in doc && "clientCreatedAtTz" in doc;
        }

        if (this.isLoading) {
            throw "attempt to save document while it's loading";
        }

        const updatedDoc = this.entity2Doc(this.entity);

        if (this.isNew) {
            this.isSaving = true;
            const setPromise = this.document.set({ //we don't await due to https://github.com/firebase/firebase-js-sdk/issues/1497#issuecomment-472267757
                ...updatedDoc,
                createdAt: FieldValue.serverTimestamp(),
                updatedAt: FieldValue.serverTimestamp(),
                clientCreatedAt: hasClientCreationDate(updatedDoc) && updatedDoc.clientCreatedAt !== undefined ? updatedDoc.clientCreatedAt : Timestamp.fromDate(new Date()),
                clientCreatedAtTz: hasClientCreationDate(updatedDoc) && updatedDoc.clientCreatedAtTz !== undefined ? updatedDoc.clientCreatedAtTz : this.timeZone,
                ...(this.userStore.user?.uid ? { createdBy: this.userStore.user?.uid } : undefined),
                isDeleted: false
            });
            if (isWeb) {
                await setPromise;
            }
            this.isNew = false;
        } else {
            const {createdAt, clientCreatedAt, clientCreatedAtTz, deletedAt, ...restOfFields} = updatedDoc as any; //remove from fields to send to update
            this.excludedFromUpdateFields.forEach(field => delete restOfFields[field]);
            this.isSaving = true;
            const setPromise = this.document.update({
                ...restOfFields,
                updatedAt: FieldValue.serverTimestamp(),
                clientUpdatedAt: Timestamp.fromDate(new Date()),
                clientUpdatedAtTz: new Date().getTimezoneOffset(),
            }).then(async () => {
                await this.document.fetch()
                runInAction(() => {
                    this.entity = this.doc2Entity(this.document.data);
                })
            });
            if (isWeb) {
                await setPromise;
            }
        }
        runInAction(() => {
           this.isSaving = false;
        });
    }

    async delete() {
        await this.document.update({
            isDeleted: true,
            deletedAt: FieldValue.serverTimestamp()
        } as any);
    }

    async saveAsNew(): Promise<string> {
        this.document = new Document(db.collection(this.entityType).doc());
        this.isNew = true;
        await this.save();
        return this.document.id!;
    }

    @action
    async partialUpdate(updates: Partial<B>): Promise<void> {
        if (this.isLoading) {
            throw "attempt to save document while it's loading";
        }
        this.isSaving = true;
        await this.document.update(updates);
        runInAction(() => {
            this.isSaving = false;
        });
    }
}

export class NotFoundException extends Error {
    constructor(message?: string) {
        super(message);
        // see: typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
        this.name = NotFoundException.name; // stack traces display correctly now
    }
}
