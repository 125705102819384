import {useCallback, useState, useEffect} from "react";
import {FieldRenderMode, FieldType} from "shared/forms/model";
import {useFormContext} from "react-hook-form";
import { runInAction } from "mobx";
import {FieldProps} from "./field";
import * as React from "react";
import {AvailableImageUrl, imageDownloadUrlFromPath, ImageUrl} from "shared/submissions/imageHelpers";

export function useImageCommon<T>(props: FieldProps<FieldType.Image> | FieldProps<FieldType.Signature>) {
    const {field, fieldId} = props;
    const {setValue} = useFormContext();

    const setFieldValue = useCallback((value: T) => {
        setValue(fieldId, value);
    }, [setValue, fieldId]);

    const updateLabel = (event: any) => {
        runInAction(() => {
            field.isClean = false;
            field.label = event.target.value;
        });
    };

    const [displayImageUrl, setDisplayImageUrl] = useState<ImageUrl>({status: "pending"});

    const imagePath = props.data?.path;
    useEffect(() => {
        async function updateImageUrl() {
            if (props.fieldRenderMode === FieldRenderMode.Display) {
                setDisplayImageUrl(await imageDownloadUrlFromPath(imagePath));
            }
        }
        updateImageUrl().then();
    }, [imagePath, props.fieldRenderMode]);

    const DisplayImage = useCallback((props: { render: (imageUrl: AvailableImageUrl) => JSX.Element }) => {
        return <>
            {displayImageUrl && displayImageUrl.status === "none" &&
            <div>(none)</div>
            }
            {displayImageUrl && displayImageUrl.status === "notavailable" &&
            <div>Image not uploaded</div>
            }
            {displayImageUrl && displayImageUrl.status === "available" &&
                <div className="mt-4">{props.render(displayImageUrl)}</div>}
        </>;
    }, [displayImageUrl]);

    return {
        setFieldValue,
        updateLabel,
        DisplayImage
    };
}

export function useImageUrl(path: string | undefined | null) {
    const [imageUrl, setImageUrl] = useState<string | undefined>();

    useEffect(() => {
        async function updateImageUrl() {
            if (path) {
                const image = await imageDownloadUrlFromPath(path);
                if (image.status === "available") {
                    setImageUrl(image.url);
                }
            } else {
                setImageUrl(undefined);
            }
        }
        updateImageUrl().then();
    }, [path, setImageUrl]);

    return imageUrl;
}