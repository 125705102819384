import { observable, action, runInAction, autorun } from "mobx";
import { Collection, Document } from "firestorter";
import { computed } from "mobx";
import 'firebase/app';
import "firebase/firestore";
import { firestore } from 'firebase'
import { CollectionSource, ICollectionOptions, CollectionQuery } from "firestorter/lib/Types";

export interface IPagedCollectionOptions<T extends object> extends ICollectionOptions<Document<T>> {
    query?: FirestoreQuery;
    order?: string | firestore.FieldPath;
    directionStr?: "asc" | "desc"
    order2?: string | firestore.FieldPath | null;
    directionStr2?: "asc" | "desc"
    pageSize: number;
}

export type FirestoreQuery = CollectionQuery;

const buildQuery = <T extends object>(
    options: IPagedCollectionOptions<T>,
    startAt?: firestore.QueryDocumentSnapshot
) => (r: firestore.CollectionReference) => {

    const filteredQuery = (options.query ? (options.query as any)(r) : r);
    if (!filteredQuery) { return null; }
    const filteredQueryWithLimit = filteredQuery.limit(options.pageSize + 1);
    const filteredQueryWithOrder = options.order ? filteredQueryWithLimit.orderBy(options.order, options.directionStr) : filteredQueryWithLimit;
    const filteredQueryWithOrder2 = options.order2 ? filteredQueryWithOrder.orderBy(options.order2, options.directionStr2) : filteredQueryWithOrder;
    return startAt ? filteredQueryWithOrder2.startAt(startAt) : filteredQueryWithOrder2;
};

export default class PagedCollection<T extends object> extends Collection<Document<T>> {

    private readonly pagingOptions: IPagedCollectionOptions<T>;

    @observable totalCount = -1;
    @computed get currentPage() { return this.pages.length - 1; }
    @observable private pages: CollectionQuery[] = [];
    @observable hasRequestedMore = false;

    constructor(source: CollectionSource, options: IPagedCollectionOptions<T>) {
        super(source, {
            ...options,
            query: buildQuery(options),
            createDocument: (source, options) =>
                new Document(source, { snapshotOptions: {serverTimestamps: 'none'},...options})
        });

        this.pagingOptions = options;
        runInAction(() => {
            this.pages = [buildQuery(options)];
        });

        autorun(() => {
            if (this.docs.length < this.pagingOptions.pageSize + 1 && !this.isLoading) {
                runInAction(() => {
                    this.totalCount = ((this.pages.length - 1) * this.pagingOptions.pageSize) + this.docs.length;
                });
            } else {
                runInAction(() => { //in race condition this will fix up that we have set totalCount when we shouldn't
                    this.totalCount = -1;
                })
            }
        })
    }

    @computed get pagedDocs(): Document<T>[] {
        return this.docs.filter((_doc, index) => {
            return (index < this.pagingOptions.pageSize);
        })
    }

    @action
    updateQuery = (options: Partial<IPagedCollectionOptions<T>>) => {
        if (options.query !== undefined) {
            this.pagingOptions.query = options.query;
            this.totalCount = -1;
        }
        if (options.order !== undefined) {
            this.pagingOptions.order = options.order;
        }
        if (options.order2 !== undefined) {
            this.pagingOptions.order2 = options.order2;
        }
        if (options.pageSize !== undefined) {
            this.pagingOptions.pageSize = options.pageSize;
        }
        if (options.directionStr !== undefined) {
            this.pagingOptions.directionStr = options.directionStr;
        }
        if (options.directionStr2 !== undefined) {
            this.pagingOptions.directionStr2 = options.directionStr2;
        }
        this.query = buildQuery(this.pagingOptions);
        this.pages = [this.query];
    };

    disconnectQuery = () => {
        this.pagingOptions.query = undefined;
        console.log("Disconnecting query.")
        this.query = undefined;
        this.pages = [];
    }

    @computed
    get canPrev() {
        return this.pagingOptions.query && this.pages.length > 1;
    }

    @computed
    get canNext() {
        return this.pagingOptions.query && this.docs.length == this.pagingOptions.pageSize + 1;
    }

    nextPage = () => {
        this.queryRef?.get().then((documentSnapshots: firestore.QuerySnapshot) => {
            runInAction(() => {
                const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                const newQuery = buildQuery(this.pagingOptions, lastVisible);
                this.pages.push(newQuery);
                this.query = newQuery;
            })
        });
    };

    @action
    prevPage = () => {
        this.pages.pop();
        this.query = this.pages[this.pages.length - 1];
    };

    @action
    retrieveMore(count: number) {
        if (this.totalCount === -1) {
            this.hasRequestedMore = true;
            this.updateQuery({pageSize: this.pagingOptions.pageSize + count});
        }
    }
}
