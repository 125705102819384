import {authRoles} from "shared/auth/authRoles";
import SettingsPage from "./SettingsPage";
import BillingPage from "./BillingPage";
import SupportPage from "./SupportPage";

const settingsRoutes = [
  {
    path: "/billing",
    component: BillingPage,
    auth: authRoles.user
  },
    {
    path: "/settings",
    component: SettingsPage,
    auth: authRoles.user
  },
  {
    path: "/support",
    component: SupportPage,
    auth: authRoles.user
  },

];

export default settingsRoutes;
